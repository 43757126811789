import React, { useEffect, useState } from 'react'
import ProfileSidebar from '../../ProfileSidebar/ProfileSidebar'
import * as constants from '../../../Constants/Constants'
import { useForm } from 'react-hook-form';
import ReactPaginate from 'react-paginate';
declare var $: any;
function WeeklyMatkaAdmin() {
  const { register, handleSubmit, setValue, formState: { errors }, reset } = useForm();
  const [formSubmitLoader, setFormSubmitLoader] = useState<boolean>(false);

  const [wjPattiList, setWjPattiList] = useState<any[]>();
  const [currentDate, setCurrentDate] = useState<string>(new Date().toISOString().split("T")[0]);
  const [sjkId, setsjkId] = useState<any[]>();
  const [updateflag, setUpdateflag] = useState<boolean>(false);
  const [weeklyJodiPattiId, setWeeklyJodiPattiId] = useState<any[]>();
  const [pageCount, setPageCount] = useState(0);
  const [pageNum, setPageNum] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(20);
  const [selectedDate, setSelectedDate] = useState('');
  const [lotteryClubList, setLotteryClubList] = useState<any[]>();
  const [selectedLotteryClub, setSelectedLotteryClub] = useState('');

  useEffect(() => {
    getLotteryClubList();
  }, []);

  useEffect(() => {
    getWjPattiList();
  }, [pageNum, selectedDate, selectedLotteryClub]);

  const getLotteryClubList = () => {
    fetch(constants.BASE_URL + 'lottery-club/dropdown', {
      method: 'GET',
      headers: { 'Content-Type': 'application/json', 'Accept': 'application/json', 'Authorization': constants.getAuthHeader() },
    }).then(function (response) {
      return response.json();
    }).then(function (response: any) {
      if (response.status == '200') {
        setLotteryClubList(response.result);
      }
    });
  }

  const getWjPattiList = () => {
    let queryParam = '?page=' + (pageNum - 1) + '&size=' + itemsPerPage;
    if (selectedDate) {
      queryParam += '&weekStartDate=' + selectedDate;
    }
    // if (currentDate && !selectedDate) {
    //   queryParam += '&weekStartDate=' + currentDate;
    // }
    if (selectedLotteryClub) {
      queryParam += queryParam ? '&' : '?';
      queryParam += 'lotteryId=' + selectedLotteryClub;
    }
    fetch(constants.BASE_URL + 'weekly-jodi-patti' + queryParam, {
      method: 'GET',
      headers: { 'Content-Type': 'application/json', 'Accept': 'application/json', 'Authorization': constants.getAuthHeader() },
    }).then(function (response) {
      return response.json();
    }).then(function (response: any) {
      if (response.status == '200') {
        setWjPattiList(response.result);
        setPageCount(Math.ceil(response.listCount / itemsPerPage));
      }
    });
  }

  const handlePageClick = (event: any) => {
    setPageNum(event.selected + 1);
  };

  const prepareAddSfg = () => {
    $('#sfg-modal').modal('show');
  }

  const addWjPAtti = (data: any) => {
    if (data.monTueFigure) {
      data.monTueFigure = data.monTueFigure?.replaceAll('\n', '<br>');
    }
    if (data.monTuePanel) {
      data.monTuePanel = data.monTuePanel?.replaceAll('\n', '<br>');
    }
    if (data.monTueMatkaJodi) {
      data.monTueMatkaJodi = data.monTueMatkaJodi?.replaceAll('\n', '<br>');
    }
    if (data.wedThuFigure) {
      data.wedThuFigure = data.wedThuFigure?.replaceAll('\n', '<br>');
    }
    if (data.wedThuPanel) {
      data.wedThuPanel = data.wedThuPanel?.replaceAll('\n', '<br>');
    }
    if (data.wedThuMatkaJodi) {
      data.wedThuMatkaJodi = data.wedThuMatkaJodi?.replaceAll('\n', '<br>');
    }

    if (data.friSatFigure) {
      data.friSatFigure = data.friSatFigure?.replaceAll('\n', '<br>');
    }
    if (data.friSatPanel) {
      data.friSatPanel = data.friSatPanel?.replaceAll('\n', '<br>');
    }
    if (data.friSatMatkaJodi) {
      data.friSatMatkaJodi = data.friSatMatkaJodi?.replaceAll('\n', '<br>');
    }
    if (data.weeklyFixAnkOpen) {
      data.weeklyFixAnkOpen = data.weeklyFixAnkOpen?.replaceAll('\n', '<br>');
    }
    if (data.weeklyFixAnkClose) {
      data.weeklyFixAnkClose = data.weeklyFixAnkClose?.replaceAll('\n', '<br>');
    }
    if (data.weeklyJodiOne) {
      data.weeklyJodiOne = data.weeklyJodiOne?.replaceAll('\n', '<br>');
    }
    if (data.weeklyJodiTwo) {
      data.weeklyJodiTwo = data.weeklyJodiTwo?.replaceAll('\n', '<br>');
    }
    if (data.weeklyPanelOne) {
      data.weeklyPanelOne = data.weeklyPanelOne?.replaceAll('\n', '<br>');
    }
    if (data.weeklyPanelTwo) {
      data.weeklyPanelTwo = data.weeklyPanelTwo?.replaceAll('\n', '<br>');
    }
    if (data.weeklySangamOne) {
      data.weeklySangamOne = data.weeklySangamOne?.replaceAll('\n', '<br>');
    }
    if (data.weeklySangamTwo) {
      data.weeklySangamTwo = data.weeklySangamTwo?.replaceAll('\n', '<br>');
    }
    console.log(data, "data")
    setFormSubmitLoader(true);
    data['lotteryClub'] = { lotteryId: data['lotteryId'] };
    fetch(constants.BASE_URL + 'weekly-jodi-patti', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json', 'Accept': 'application/json', 'Authorization': constants.getAuthHeader() },
      body: JSON.stringify(data)
    }).then(function (response) {
      return response.json();
    }).then(function (response: any) {
      setFormSubmitLoader(false);
      if (response.status == '201') {
        reset();
        $('#sfg-modal').modal('hide');
        constants.showSuccess('Request processed successfully');
        getWjPattiList();
      } else {
        constants.showError(response.message);
      }
    });
  }

  const prepareEditSfg = (sfgId: string) => {

  }

  const onDateChange = (e: any) => {
    setCurrentDate(e.target.value);
  }
  const prepareEditSjk = (data: any) => {
    setUpdateflag(true)
    $('#sfg-modal').modal('show');
    setWeeklyJodiPattiId(data.weeklyJodiPattiId)
    setTimeout(() => {
      setValue('weeklyJodiPattiId', data.weeklyJodiPattiId, { shouldDirty: true });
      setValue('weekStartDate', data.weekStartDate, { shouldDirty: true });
      setValue('weekEndDate', data.weekEndDate, { shouldDirty: true });
      setValue('type', data.type, { shouldDirty: true });
      setValue('monTueFigure', data.monTueFigure.replaceAll('<br>', '\n'));
      setValue('monTuePanel', data.monTuePanel.replaceAll('<br>', '\n'));
      setValue('monTueMatkaJodi', data.monTueMatkaJodi.replaceAll('<br>', '\n'));
      setValue('wedThuFigure', data.wedThuFigure.replaceAll('<br>', '\n'));
      setValue('wedThuPanel', data.wedThuPanel.replaceAll('<br>', '\n'));
      setValue('wedThuMatkaJodi', data.wedThuMatkaJodi.replaceAll('<br>', '\n'));
      setValue('friSatFigure', data.friSatFigure.replaceAll('<br>', '\n'));
      setValue('friSatPanel', data.friSatPanel.replaceAll('<br>', '\n'));
      setValue('friSatMatkaJodi', data.friSatMatkaJodi.replaceAll('<br>', '\n'));
      setValue('weeklyFixAnkOpen', data.weeklyFixAnkOpen.replaceAll('<br>', '\n'));
      setValue('weeklyFixAnkClose', data.weeklyFixAnkClose.replaceAll('<br>', '\n'));
      setValue('weeklyJodiOne', data.weeklyJodiOne.replaceAll('<br>', '\n'));
      setValue('weeklyJodiTwo', data.weeklyJodiTwo.replaceAll('<br>', '\n'));
      setValue('weeklyPanelOne', data.weeklyPanelOne.replaceAll('<br>', '\n'));
      setValue('weeklyPanelTwo', data.weeklyPanelTwo.replaceAll('<br>', '\n'));
      setValue('weeklySangamOne', data.weeklySangamOne.replaceAll('<br>', '\n'));
      setValue('weeklySangamTwo', data.weeklySangamTwo.replaceAll('<br>', '\n'));
      setValue('lotteryId', data.lotteryClub.lotteryId, { shouldDirty: true });
    }, 500);

  }
  // put api in satta king jodi
  const editWjPatti = (data: any) => {
    setFormSubmitLoader(true);
    if (data.monTueFigure) {
      data.monTueFigure = data.monTueFigure?.replaceAll('\n', '<br>');
    }
    if (data.monTuePanel) {
      data.monTuePanel = data.monTuePanel?.replaceAll('\n', '<br>');
    }
    if (data.monTueMatkaJodi) {
      data.monTueMatkaJodi = data.monTueMatkaJodi?.replaceAll('\n', '<br>');
    }
    if (data.wedThuFigure) {
      data.wedThuFigure = data.wedThuFigure?.replaceAll('\n', '<br>');
    }
    if (data.wedThuPanel) {
      data.wedThuPanel = data.wedThuPanel?.replaceAll('\n', '<br>');
    }
    if (data.wedThuMatkaJodi) {
      data.wedThuMatkaJodi = data.wedThuMatkaJodi?.replaceAll('\n', '<br>');
    }

    if (data.friSatFigure) {
      data.friSatFigure = data.friSatFigure?.replaceAll('\n', '<br>');
    }
    if (data.friSatPanel) {
      data.friSatPanel = data.friSatPanel?.replaceAll('\n', '<br>');
    }
    if (data.friSatMatkaJodi) {
      data.friSatMatkaJodi = data.friSatMatkaJodi?.replaceAll('\n', '<br>');
    }

    if (data.weeklyFixAnkOpen) {
      data.weeklyFixAnkOpen = data.weeklyFixAnkOpen?.replaceAll('\n', '<br>');
    }
    if (data.weeklyFixAnkClose) {
      data.weeklyFixAnkClose = data.weeklyFixAnkClose?.replaceAll('\n', '<br>');
    }
    if (data.weeklyJodiOne) {
      data.weeklyJodiOne = data.weeklyJodiOne?.replaceAll('\n', '<br>');
    }
    if (data.weeklyJodiTwo) {
      data.weeklyJodiTwo = data.weeklyJodiTwo?.replaceAll('\n', '<br>');
    }
    if (data.weeklyPanelOne) {
      data.weeklyPanelOne = data.weeklyPanelOne?.replaceAll('\n', '<br>');
    }
    if (data.weeklyPanelTwo) {
      data.weeklyPanelTwo = data.weeklyPanelTwo?.replaceAll('\n', '<br>');
    }
    if (data.weeklySangamOne) {
      data.weeklySangamOne = data.weeklySangamOne?.replaceAll('\n', '<br>');
    }
    if (data.weeklySangamTwo) {
      data.weeklySangamTwo = data.weeklySangamTwo?.replaceAll('\n', '<br>');
    }


    data['lotteryClub'] = { lotteryId: data['lotteryId'] };
    fetch(constants.BASE_URL + 'weekly-jodi-patti/' + weeklyJodiPattiId, {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json', 'Accept': 'application/json', 'Authorization': constants.getAuthHeader() },
      body: JSON.stringify(data)
    }).then(function (response) {
      return response.json();
    }).then(function (response: any) {
      setFormSubmitLoader(false);
      if (response.status == '200') {
        $('#sfg-modal').modal('hide');
        constants.showSuccess('Request processed successfully');
        getWjPattiList();
      } else {
        constants.showError(response.message);
      }
    });
  }
  // function to change date format
  function formatDate(input: any) {
    if (input != undefined) {
      let datePart = input.match(/\d+/g),
        year = datePart[0],
        // get only two digits 
        month = datePart[1],
        day = datePart[2];
      return day + '-' + month + '-' + year;
    }
  }

  const deleteWeeklyJodiPatti = (weeklyJodiPattiId: string) => {
    if (window.confirm('Are you sure, you want to delete this record?')) {
      setFormSubmitLoader(true);
      fetch(constants.BASE_URL + 'weekly-jodi-patti/' + weeklyJodiPattiId, {
        method: 'DELETE',
        headers: { 'Content-Type': 'application/json', 'Accept': 'application/json', 'Authorization': constants.getAuthHeader() },
      }).then(function (response) {
        return response.json();
      }).then(function (response: any) {
        setFormSubmitLoader(false);
        if (response.status == '200') {
          getWjPattiList();
          constants.showSuccess(response.message);
        } else {
          constants.showError(response.message);
        }
      });
    }
  }
  return (
    <>
      <div className='page-content'>
        <section className="page-header page-header-dark bg-secondary">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-md-8">
                <h2 className='admin-heading mb-0 text-white'>ADMIN PANEL</h2>
              </div>
              <div className="col-md-4">

              </div>
            </div>
          </div>
        </section>
        <div className="container-fluid p-5 mb-2">
          <div className="row">
            <div className="col-lg-2">
              <ProfileSidebar />
            </div>
            <div className="col-lg-10">
              <div className="bg-white shadow-md rounded p-4">
                <div className='row align-items-center'>
                  <div className='col-md-3 mb-2 mb-md-0'>
                    <h4>Weekly Jodi Patti</h4>
                  </div>
                  <div className='col d-flex align-items-center'>
                    <label htmlFor="weekEndDate" className="mr-2 mb-0 col-form-label">Week end date:</label>
                    <input type="date" id="weekEndDate" className='form-control' value={selectedDate} onChange={(e) => setSelectedDate(e.target.value)} />
                  </div>
                  <div className='col-md-3'>
                    <select className="form-control" onChange={(e) => setSelectedLotteryClub(e.target.value)} defaultValue={""}>
                      <option disabled value="">--Select Lottery Club--</option>
                      {lotteryClubList?.map((lc, index) => (
                        <option key={index} value={lc.lotteryId}>{lc.name}</option>
                      ))}
                    </select>
                  </div>
                  <div className='col-md-2'>
                    <button type='button' className="btn btn-info btn-sm float-md-right" onClick={prepareAddSfg}>Add New Result</button>
                  </div>
                </div>

                <div className="tab-content my-3" id="myTabContent">
                  <div className="tab-pane fade show active" id="first" role="tabpanel" aria-labelledby="first-tab">
                    <div className="table-responsive-md">
                      <table className="table table table-striped table-hover text-dark">
                        <thead >
                          <tr>
                            <th>Sr No</th>
                            <th>Start Date</th>
                            <th>End Date</th>
                            <th>Lottery Club</th>


                            {/* <th><button type='button' className='btn btn-info btn-sm float-right'>Edit Data</button></th> */}

                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {wjPattiList?.map((data: any, index: number) => (
                            <tr key={index}>
                              <td className="align-middle">{(pageNum - 1) * itemsPerPage + index + 1}</td>
                              <td className="align-middle">{formatDate(data.weekStartDate)}</td>
                              <td className="align-middle">{formatDate(data.weekEndDate)}</td>
                              <td className="align-middle">{data.lotteryClub?.name}</td>

                              {/* <td className="align-middle"><button type='button'  onClick ={()=>prepareEditSjk(data)} className='btn btn-info btn-sm '>Edit Data</button></td> */}

                              <td className="align-middle">
                                <button className="featured-box-icon bg-primary text-white rounded-circle border-0 edit-icon"
                                  onClick={() => prepareEditSjk(data)}>
                                  <i className="fas fa-edit"></i>
                                </button>
                                <button className="featured-box-icon bg-danger text-white rounded-circle border-0 edit-icon"
                                onClick={() => deleteWeeklyJodiPatti(data.weeklyJodiPattiId)} disabled={formSubmitLoader}>
                                {!formSubmitLoader && <i className="fas fa-trash"></i>}
                                {formSubmitLoader && <i className="fas fa-circle-notch fa-spin me-2"></i>}
                              </button>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                    <ReactPaginate breakLabel="..." nextLabel=">" onPageChange={handlePageClick} pageRangeDisplayed={5}
                      pageCount={pageCount} previousLabel="<" pageClassName="page-item" pageLinkClassName="page-link"
                      previousClassName="page-item" previousLinkClassName="page-link" nextClassName="page-item" nextLinkClassName="page-link"
                      breakClassName="page-item" breakLinkClassName="page-link" containerClassName="pagination" activeClassName="active"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div id="sfg-modal" className="modal fade" role="dialog" aria-hidden="true">
          <div className="modal-dialog modal-dialog-centered modal-shadow" role="document">
            <div className="modal-content border-0">
              <div className="modal-header">
                <h5 className="modal-title">Weekly Jodi Patti</h5>
                <button type="button" className="btn-close" data-dismiss="modal" aria-label="Close"></button>
              </div>
              <form id="loginForm" method="post" onSubmit={handleSubmit(updateflag ? editWjPatti : addWjPAtti)}>
                <div className="modal-body py-4 px-0">
                  <div className="row">
                    <div className="col-11 col-md-10 mx-auto">
                      <div className="row">
                        <div className=" col-3 col-md-3 mx-auto mb-3">
                          <label className="number" htmlFor="open"><b>Week Start Date</b></label>
                          <input type="date" className="form-control" id="openTime" placeholder="Week Start Date"
                            {...register("weekStartDate", { required: false })} />
                          {errors.open && <span className='text-danger'>Open is required </span>}

                        </div>
                        <div className=" col-3 col-md-3 mx-auto mb-3">
                          <label className="number" htmlFor="open"><b>Week End Date</b></label>
                          <input type="date" className="form-control" id="openTime" placeholder="Week End Date"
                            {...register("weekEndDate", { required: false })} />
                          {errors.open && <span className='text-danger'>Open is required </span>}

                        </div>
                        <div className=" col-6 col-md-6 mx-auto mb-3">
                          <label className="number" htmlFor="name">Lottery Club<span className='star'>*</span></label>
                          <select className="form-control" id="name" defaultValue={""} {...register("lotteryId", { required: true })}>
                            <option disabled value="">--Select Lottery Club--</option>
                            {lotteryClubList?.map((lc, index) => (
                              <option key={index} value={lc.lotteryId}>{lc.name}</option>
                            ))}
                          </select>
                          {errors.lotteryId && <span className='text-danger'>Name is required </span>}
                        </div>
                      </div>
                      <div className='row'>
                        <div className=" col-6 col-md-6 mx-auto mb-3">
                          <label className="number" htmlFor="open"><b></b></label>
                          <div className="number">Figure</div>

                        </div>
                        <div className=" col-6 col-md-6 mx-auto mb-3">
                          <label className="number" htmlFor="open"><b>Monday -Tuesday </b></label>
                          <textarea className="form-control" id="openTime" placeholder="Mon Tue Figure"
                            {...register("monTueFigure", { required: false })} />
                          {errors.open && <span className='text-danger'>Open is required </span>}
                        </div>
                      </div>
                      <div className='row'>
                        <div className=" col-6 col-md-6 mx-auto mb-3">
                          <div className='number'>Panel</div>
                        </div>
                        <div className=" col-6 col-md-6 mx-auto mb-3">
                          <textarea className="form-control" id="openTime" placeholder="Mon Tue Panel"
                            {...register("monTuePanel", { required: false })} />
                          {errors.open && <span className='text-danger'>Open is required </span>}
                        </div>
                      </div>
                      <div className='row'>
                        <div className=" col-6 col-md-6 mx-auto mb-3">
                          <div className='number'>Matka Jodi</div>
                        </div>
                        <div className=" col-6 col-md-6 mx-auto mb-3">
                          <textarea className="form-control" id="openTime" placeholder="Mon Tue Matka Jodi"
                            {...register("monTueMatkaJodi", { required: false })} />
                          {errors.open && <span className='text-danger'>Open is required </span>}
                        </div>

                      </div>
                      <hr />
                      <div className='row'>
                        <div className=" col-6 col-md-6 mx-auto mb-3">
                          <label className="number" htmlFor="open"><b></b></label>
                          <div className="number">Figure</div>

                        </div>
                        <div className=" col-6 col-md-6 mx-auto mb-3">
                          <label className="number" htmlFor="open"><b>Wednesday -Thursday </b></label>
                          <textarea className="form-control" id="openTime" placeholder="Wed Thursday Figure"
                            {...register("wedThuFigure", { required: false })} />
                          {errors.open && <span className='text-danger'>Open is required </span>}
                        </div>
                      </div>
                      <div className='row'>
                        <div className=" col-6 col-md-6 mx-auto mb-3">
                          <div className='number'>Panel</div>
                        </div>
                        <div className=" col-6 col-md-6 mx-auto mb-3">
                          <textarea className="form-control" id="openTime" placeholder="Wed Thursday Panel"
                            {...register("wedThuPanel", { required: false })} />
                          {errors.open && <span className='text-danger'>Open is required </span>}
                        </div>
                      </div>
                      <div className='row'>
                        <div className=" col-6 col-md-6 mx-auto mb-3">
                          <div className='number'>Matka Jodi</div>
                        </div>
                        <div className=" col-6 col-md-6 mx-auto mb-3">
                          <textarea className="form-control" id="openTime" placeholder="Wed Thursday MatkaJodi"
                            {...register("wedThuMatkaJodi", { required: false })} />
                          {errors.open && <span className='text-danger'>Open is required </span>}
                        </div>

                      </div>
                      <hr />
                      <div className='row'>
                        <div className=" col-6 col-md-6 mx-auto mb-3">
                          <label className="number" htmlFor="open"><b></b></label>
                          <div className="number">Figure</div>

                        </div>
                        <div className=" col-6 col-md-6 mx-auto mb-3">
                          <label className="number" htmlFor="open"><b>Friday -Saturday </b></label>
                          <textarea className="form-control" id="openTime" placeholder="Fri Sat Figure"
                            {...register("friSatFigure", { required: false })} />
                          {errors.open && <span className='text-danger'>Open is required </span>}
                        </div>
                      </div>
                      <div className='row'>
                        <div className=" col-6 col-md-6 mx-auto mb-3">
                          <div className='number'>Panel</div>
                        </div>
                        <div className=" col-6 col-md-6 mx-auto mb-3">
                          <textarea className="form-control" id="openTime" placeholder="Fri Sat Panel"
                            {...register("friSatPanel", { required: false })} />
                          {errors.open && <span className='text-danger'>Open is required </span>}
                        </div>
                      </div>
                      <div className='row'>
                        <div className=" col-6 col-md-6 mx-auto mb-3">
                          <div className='number'>Matka Jodi</div>
                        </div>
                        <div className=" col-6 col-md-6 mx-auto mb-3">
                          <textarea className="form-control" id="openTime" placeholder="Fri Sat Matka Jodi"
                            {...register("friSatMatkaJodi", { required: false })} />
                          {errors.open && <span className='text-danger'>Open is required </span>}
                        </div>

                      </div>
                      <hr />
                      <div className='row'>
                        <div className=" col-6 col-md-6 mx-auto mb-3">
                          <label className="number" htmlFor="open"></label>
                          <div className="number">Weekly Fix Ank</div>
                        </div>
                        <div className=" col-6 col-md-6 mx-auto mb-3">
                          <label className="number" htmlFor="open"></label>
                          <textarea className="form-control" id="openTime" placeholder="Weekly Fix Ank Open"
                            {...register("weeklyFixAnkOpen", { required: false })} />
                          {errors.open && <span className='text-danger'>Open is required </span>}
                        </div>

                      </div>
                      <div className='row'>
                        <div className=" col-6 col-md-6 mx-auto mb-3">

                        </div>
                        <div className=" col-6 col-md-6 mx-auto mb-3">
                          <textarea className="form-control" id="openTime" placeholder="Weekly Fix Ank Close"
                            {...register("weeklyFixAnkClose", { required: false })} />
                          {errors.open && <span className='text-danger'>Open is required </span>}
                        </div>

                      </div>
                      <hr />
                      <div className='row'>
                        <div className=" col-6 col-md-6 mx-auto mb-3">
                          <label className="number" htmlFor="open"></label>
                          <div className="number">Weekly Jodi</div>
                        </div>
                        <div className=" col-6 col-md-6 mx-auto mb-3">
                          <label className="number" htmlFor="open"></label>
                          <textarea className="form-control" id="openTime" placeholder="Weekly Jodi One"
                            {...register("weeklyJodiOne", { required: false })} />
                          {errors.open && <span className='text-danger'>Open is required </span>}
                        </div>

                      </div>
                      <div className='row'>
                        <div className=" col-6 col-md-6 mx-auto mb-3">

                        </div>
                        <div className=" col-6 col-md-6 mx-auto mb-3">
                          <textarea className="form-control" id="openTime" placeholder="Weekly Jodi Two"
                            {...register("weeklyJodiTwo", { required: false })} />
                          {errors.open && <span className='text-danger'>Open is required </span>}
                        </div>

                      </div>
                      <hr />
                      <div className='row'>
                        <div className=" col-6 col-md-6 mx-auto mb-3">
                          <label className="number" htmlFor="open"></label>
                          <div className="number">Weekly Panel</div>
                        </div>
                        <div className=" col-6 col-md-6 mx-auto mb-3">
                          <label className="number" htmlFor="open"></label>
                          <textarea className="form-control" id="openTime" placeholder="Weekly Panel One"
                            {...register("weeklyPanelOne", { required: false })} />
                          {errors.open && <span className='text-danger'>Open is required </span>}
                        </div>

                      </div>
                      <div className='row'>
                        <div className=" col-6 col-md-6 mx-auto mb-3">

                        </div>
                        <div className=" col-6 col-md-6 mx-auto mb-3">
                          <textarea className="form-control" id="openTime" placeholder="Weekly Panel Two"
                            {...register("weeklyPanelTwo", { required: false })} />
                          {errors.open && <span className='text-danger'>Open is required </span>}
                        </div>

                      </div>
                      <hr />
                      <div className='row'>
                        <div className=" col-6 col-md-6 mx-auto mb-3">
                          <label className="number" htmlFor="open"></label>
                          <div className="number">Weekly Sangam</div>
                        </div>
                        <div className=" col-6 col-md-6 mx-auto mb-3">
                          <label className="number" htmlFor="open"></label>
                          <textarea className="form-control" id="openTime" placeholder="Weekly Sangam One"
                            {...register("weeklySangamOne", { required: false })} />
                          {errors.open && <span className='text-danger'>Open is required </span>}
                        </div>

                      </div>
                      <div className='row'>
                        <div className=" col-6 col-md-6 mx-auto mb-3">

                        </div>
                        <div className=" col-6 col-md-6 mx-auto mb-3">
                          <textarea className="form-control" id="openTime" placeholder="Weekly Sangam Two"
                            {...register("weeklySangamTwo", { required: false })} />
                          {errors.open && <span className='text-danger'>Open is required </span>}
                        </div>

                      </div>
                    </div>
                  </div>
                </div>
                <div className="modal-footer">
                  <button type="button" className="btn btn-danger btn-sm" data-dismiss="modal">Close</button>
                  <button type="submit" className="btn btn-primary btn-sm" disabled={formSubmitLoader}>
                    {formSubmitLoader && <i className="fas fa-circle-notch fa-spin me-2"></i>}
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default WeeklyMatkaAdmin