import React, { useEffect, useState } from 'react'
import { useNavigate, NavLink } from 'react-router-dom';
import './AllSattaMatkaCharts.css'
import * as constants from '../../Constants/Constants'

export default function () {
  let navigate = useNavigate();

  const [lotteryClubList, setLotteryClubList] = useState<any[]>([]);
  useEffect(() => {
    getLotteryClubList();

  }, []);

  const getLotteryClubList = () => {
    fetch(constants.BASE_URL + 'lottery-club/all/dropdown', {
      method: 'GET',
      headers: { 'Content-Type': 'application/json', 'Accept': 'application/json', 'Authorization': constants.getAuthHeader() },
    }).then(function (response) {
      return response.json();
    }).then(function (response: any) {
      if (response.status == '200') {
        setLotteryClubList(response.result);
      }
    });
  }

  const getLotteryClubJodi = (data: any) => {
    navigate((`/jodi/${data.lotteryId}`))
  }
  const getLotteryClubPanel = (data: any) => {
    navigate((`/panel/${data.lotteryId}`))
  }


  return (
    <>
      <div className='lotheadingwhite'>
        <h3>
          SATTA MATKA CHART
        </h3>
        <p>Satta Matka Chart Page Help you to finding matka jodi panel charts here you get kalyan matka jodi chart, kalyan night chart, main ratan chart, kalyan matka panel chart, matka patti chart, matka record, old matka chart, satta charts, satta record, morning matka chart, day matka chart, night matka chart at satta matka toppersnet.com.</p>
        <p>Matka Chart always help you to predict perfect matka jodi panel. If you are a top class guesser then must use satta matka charts and find out matka game. In this Chart you check out single digit means open and close, jodi(pair) and panel(patti). In this methord you play accurate matka number and win lots of case</p>
        <div className="col-lg-12 col-md-12 lotheadingblue my-1">
          <p className="text-warning">Satta matka chart: This chart will help you to guess accurate matka game. All satta matka website would have a chart section, but our records (chart) are eye protected and user-friendly like zoom in or zoom out function and search option available. In this page you get many charts and there are two different types like 1. Jodi chart, 2. Panel Chart (Patti Chart). Previous results data collection from Matka Office and written datewise and make charts and records for all satta market. Too many Indian people assume a number and bet but that is wrong process, help of this chart you can find out an accurate matka satta number for play and earn case. Here you get Oldest Satta Charts and Records.</p>
          <p className=" text-warning">Helping Of Satta Matka Jodi Charts Find Out accurate number for Open, Close, and Jodi. Helping Of Satta Matka Panel Chart find out Open Panel, Close Panel, Half sangam, and Jackpot Mean Sangam.</p>
          <h4 className='text-warning bg-danger'>Satta Matka  Jodi Charts</h4>
          <div className="col-lg-12 col-md-12 my-3 ">
            <h4>{lotteryClubList.map((club, index) => (
              <React.Fragment key={index}>
                <div className="col-lg-12 col-md-12 my-3">
                  <h4>
                    <div
                      className="spanviolet1 cursor-pointer"
                      onClick={() => getLotteryClubJodi(club)}
                    >
                      {club.name} Jodi Chart
                    </div>
                  </h4>
                </div>
              </React.Fragment>
            ))}</h4>
          </div>

          <h4 className='text-warning bg-danger'>Satta Matka Panel Charts</h4>

          <div className="col-lg-12 col-md-12 my-3 ">
            <h4>{lotteryClubList.map((club, index) => (
              <React.Fragment key={index}>
                
                <div className="col-lg-12 col-md-12 my-3">
                  <h4>
                    <div
                      className="spanviolet1 cursor-pointer"
                      onClick={() => getLotteryClubPanel(club)}
                    >
                      {club.name} Panel Chart
                    </div>
                  </h4>
                </div>
              </React.Fragment>
            ))}</h4>
          </div>
        </div>
        <div className="col-lg-12 col-md-12 lotheadingwhite1 my-1">
          <div className="titlered"><span className="spanviolet2">Important links for Satta Matka User</span> &nbsp; &nbsp; &nbsp; &nbsp;<span><i className="fa fa-arrow-down" /></span></div>
          <div className="col-lg-12 col-md-12 my-1 ">
            <NavLink to='/matkaguessing'>
              <h4><div className="spanviolet2">Matka Guessing</div> </h4>
            </NavLink>
          </div>

        </div>
      </div>
    </>
  )
}
