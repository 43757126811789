import React, { useState, useEffect } from 'react';
import { useForm, SubmitHandler } from "react-hook-form";
import { useNavigate, NavLink } from 'react-router-dom';
import * as constants from '../../Constants/Constants'
import './Home.css'

declare var $: any;

export default function Home() {

  let navigate = useNavigate();
  const { register, handleSubmit, watch, formState: { errors }, reset } = useForm();
  const { register: register2, handleSubmit: handleSubmit2, watch: watch2, formState: { errors: errors2 }, reset: reset2 } = useForm();
  const { register: register3, handleSubmit: handleSubmit3, watch: watch3, formState: { errors: errors3 }, reset: reset3 } = useForm();
  const [formSubmitLoader, setFormSubmitLoader] = useState<boolean>(false);
  const [passwordShown, setPasswordShown] = useState(false);
  const [eye, setEye] = useState(false);
  const [enableOtp, setEnableOtp] = useState<boolean>(true);
  const [user, setUser] = useState<any>();
  const [dailyResult, setDailyResult] = useState([]);
  const [lotteryClubList, setLotteryClubList] = useState<any[]>([]);
  const [lotteryFreeGame, setLotteryFreeGame] = useState([]);
  const [lotteryFixGame, setLotteryFixGame] = useState([]);
  const [bajarList, setBajarList] = useState<any[]>();
  const [marketList, setMarketList] = useState<any[]>();
  const [todayTips, setTodayTips] = useState<any>();
  const [advertisement, setAdverisement] = useState<any>();
  const [fmaList, setFmaList] = useState<any[]>();
  // Free game variables
  const [timeBazar, setTimeBazar] = useState<any>();
  const [milanDay, setMilanDay] = useState<any>();
  const [rajdhaniDay, setRajdhaniDay] = useState<any>();
  const [kalyan, setKalyan] = useState<any>();
  const [milanNight, setMilanNight] = useState<any>();
  const [kalyanNight, setKalyanNight] = useState<any>();
  const [rajdhaniNight, setRajdhaniNight] = useState<any>();
  const [mainBazar, setMainBazar] = useState<any>();
  const imageUrl = constants.BASE_URL + "file/image/";
  // Fix game variables
  // const [faridabad, setFaridabad] = useState<any>();
  // const [gaziyabad, setGaziyabad] = useState<any>();
  // const [gali, setGali] = useState<any>();
  // const [disawar, setDisawar] = useState<any>();

  useEffect(() => {

    getDailyResultList();
    getLotteryClubList();
    getTodayTips();
    getLotteryFreeGame();
    //getLotteryFixGame();
    getFinalMatkaAnk();
    //cal advertisement
    getAdvertisement();
  }, []);

  const togglePasswordVisiblity = () => {
    setPasswordShown(passwordShown ? false : true);
    setEye(eye ? false : true);
  };

  // code for login
  const login = (data: any) => {
    setFormSubmitLoader(true);
    fetch(constants.BASE_URL + 'user/login', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json', 'Accept': 'application/json' },
      body: JSON.stringify(data)
    }).then(function (response) {
      return response.json();
    }).then(function (response: any) {
      setFormSubmitLoader(false);
      if (response.status == '200') {
        constants.showSuccess(response.message);
        sessionStorage.setItem('currentUser', JSON.stringify(response.result));
        constants.loginSubscriber.next(true);
        if (response.result.role?.name == 'USER') {
          navigate('/matkaguessing');
        } else {
          navigate('/dashboard');
        }
      } else {
        constants.showError(response.message);
      }
    });
  }

  const userRegister = (data: any) => {
    setFormSubmitLoader(true);
    fetch(constants.BASE_URL + 'user', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json', 'Accept': 'application/json' },
      body: JSON.stringify(data)
    }).then(function (response) {
      return response.json();
    }).then(function (response: any) {
      setFormSubmitLoader(false);
      if (response.status == '201') {
        reset()
        constants.showSuccess('Registered Successfully');
        sessionStorage.setItem('currentUser', JSON.stringify(response.result));
        constants.loginSubscriber.next(true);
        $('#signup-modal').modal('hide');
        if (response.result.role?.name == 'USER') {
          navigate('/matkaguessing');
        } else {
          navigate('/dashboard');
        }
      } else {
        constants.showError(response.message);
      }
    });
  }

  const forgotPassword = (data: any) => {
    if (data.userId && data.userId.length > 0) {
      resetPassword(data);
    } else {
      setFormSubmitLoader(true);
      fetch(constants.BASE_URL + 'user/forgot-password', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', 'Accept': 'application/json' },
        body: JSON.stringify(data)
      }).then(function (response) {
        return response.json();
      }).then(function (response: any) {
        setFormSubmitLoader(false);
        if (response.status == '200') {
          constants.showSuccess(response.message);
          setEnableOtp(true);
          setUser({ userId: response.result, email: data.email });
          navigate('/');
        } else {
          constants.showError(response.message);
        }
      });
    }
  }

  const resetPassword = (data: any) => {
    setFormSubmitLoader(true);
    data['userId'] = user.userId;
    fetch(constants.BASE_URL + 'user/reset-password', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json', 'Accept': 'application/json' },
      body: JSON.stringify(data)
    }).then(function (response) {
      return response.json();
    }).then(function (response: any) {
      setFormSubmitLoader(false);
      if (response.status == '200') {
        constants.showSuccess(response.message);
        setEnableOtp(false);
        setUser(null);
        $('#forgot-password-modal').modal('hide');
      } else {
        constants.showError(response.message);
      }
    });
  }

  const resendOtp = () => {
    forgotPassword({ email: user.email });
  }

  var today: any = new Date();
  var dd: any = today.getDate();
  var dd1: any = today.getDate() - 1
  var mm: any = today.getMonth() + 1;
  var yyyy: any = today.getFullYear();
  if (dd < 10) {
    dd = '0' + dd;
  }
  if (mm < 10) {
    mm = '0' + mm;
  }
  var today: any = yyyy + "-" + mm + "-" + dd;
  var today1: any = dd + "-" + mm + "-" + yyyy;
  let today2: any = dd1 + "-" + mm + "-" + yyyy;

  const getDailyResultList = () => {
    //let searchKey = "?searchKey=TIME BAZAR,MILAN DAY,RAJDHANI DAY,KALYAN,MILAN NIGHT,RAJDHANI NIGHT,KALYAN NIGHT,MAIN BAZAR";
    fetch(`${constants.BASE_URL}daily-result/public/home-page`, {
      method: 'GET',
      headers: { 'Content-Type': 'application/json', 'Accept': 'application/json', 'Authorization': constants.getAuthHeader() },
    }).then(function (response) {
      return response.json();
    }).then(function (response: any) {
      if (response.status == '200') {
        response?.result?.forEach((data: any) => {
          const d = new Date();
          let currentTime = d.getHours()
          let timeEnd0 = new Date("01/01/2007 " + data.openTime).getHours() - 1;
          let timeEnd1 = new Date("01/01/2007 " + data.closeTime).getHours() + 1;
          let opentime1 = currentTime - timeEnd0;
          let closeTime1 = timeEnd1 - currentTime;
          data["openTimeDiff"] = opentime1;
          data["closeTimeDiff"] = closeTime1
        })
        setDailyResult(response.result);

      } else {
        constants.showError(response.message);
      }
    });
  }

  const getTodayTips = () => {
    fetch(`${constants.BASE_URL}today-tips/public/${today}`, {
      method: 'GET',
      headers: { 'Content-Type': 'application/json', 'Accept': 'application/json', 'Authorization': constants.getAuthHeader() },
    }).then(function (response) {
      return response.json();
    }).then(function (response: any) {
      if (response.status == '200') {
        setTodayTips(response.result);
      } else {
        constants.showError(response.message);
      }
    });
  }

  const getLotteryClubList = () => {
    fetch(constants.BASE_URL + 'lottery-club/all/dropdown', {
      method: 'GET',
      headers: { 'Content-Type': 'application/json', 'Accept': 'application/json', 'Authorization': constants.getAuthHeader() },
    }).then(function (response) {
      return response.json();
    }).then(function (response: any) {
      if (response.status == '200') {
        setLotteryClubList(response.result);
      }
    });
  }

  // // jodi and panel 
  // const getTimeBazarJodi = () => {
  //   navigate((`/jodi/cdc12c74-59f5-43a8-98cc-ae967ebb6aa0`))
  // }
  // const getTimeBazarPanel = () => {
  //   navigate((`/panel/cdc12c74-59f5-43a8-98cc-ae967ebb6aa0`))
  // }
  // const getMilanDayJodi = () => {
  //   navigate((`/jodi/949d8f10-c181-4a5d-b18a-1b2c7aadc53d`))
  // }
  // const getMilanDayPanel = () => {
  //   navigate((`/panel/949d8f10-c181-4a5d-b18a-1b2c7aadc53d`))
  // }
  // const getRajdhaniDayJodi = () => {
  //   navigate((`/jodi/c05e9c34-2c42-4bb6-867a-b51922db42b0`))
  // }
  // const getRajdhaniDayPanel = () => {
  //   navigate((`/panel/c05e9c34-2c42-4bb6-867a-b51922db42b0`))
  // }
  // // didnt found 
  // const getKalyanJodi = () => {
  //   navigate((`/jodi/58ebcb86-e4ef-4fd6-864e-aec89b550661`))
  // }
  // const getKalyanPanel = () => {
  //   navigate((`/panel/58ebcb86-e4ef-4fd6-864e-aec89b550661`))
  // }
  // const getMilanNightJodi = () => {
  //   navigate((`/jodi/5331ffb2-ba28-4d23-a830-f7670fbeb195`))
  // }
  // const getMilanNightPanel = () => {
  //   navigate((`/panel/5331ffb2-ba28-4d23-a830-f7670fbeb195`))
  // }
  // const getRajdhaniNightJodi = () => {
  //   navigate((`/jodi/c090c8ea-ef48-42e5-a916-e4885509eea1`))
  // }
  // const getRajdhaniNightPanel = () => {
  //   navigate((`/panel/c090c8ea-ef48-42e5-a916-e4885509eea1`))
  // }
  // const getMainBazarJodi = () => {
  //   navigate((`/jodi/dd2b7468-59af-4403-bffb-a23aa336b357`))
  // }
  // const getMainBazarPanel = () => {
  //   navigate((`/panel/dd2b7468-59af-4403-bffb-a23aa336b357`))
  // }
  // const getKalyanNightJodi = () => {
  //   navigate((`/jodi/d805d6eb-b92c-4ab5-8764-a37fcdd81a98`))
  // }
  // const getKalyanNightPanel = () => {
  //   navigate((`/panel/d805d6eb-b92c-4ab5-8764-a37fcdd81a98`))
  // }
  // // didnt found shridevi
  // const getShrideviJodi = () => {
  //   navigate((`/jodi/14c405a4-469a-4030-94ba-3cc140734e08`))
  // }
  // const getShrideviPanel = () => {
  //   navigate((`/panel/14c405a4-469a-4030-94ba-3cc140734e08`))
  // }
  // const getShrideviNightJodi = () => {
  //   navigate((`/jodi/5755a7f2-2b65-4689-a6e1-b11ccab3095d`))
  // }
  // const getShrideviNightPanel = () => {
  //   navigate((`/panel/5755a7f2-2b65-4689-a6e1-b11ccab3095d`))
  // }
  // const getMadhuriJodi = () => {
  //   navigate((`/jodi/675c20bb-999b-431e-bb54-61a721860a75`))
  // }
  // const getMadhuriPanel = () => {
  //   navigate((`/panel/675c20bb-999b-431e-bb54-61a721860a75`))
  // }
  // const getMadhuriNightJodi = () => {
  //   navigate((`/jodi/aa3f5183-074f-4d4a-bab3-8ac81f5cd314`))
  // }
  // const getMadhuriNightPanel = () => {
  //   navigate((`/panel/aa3f5183-074f-4d4a-bab3-8ac81f5cd314`))
  // }
  // const getSupremeDayJodi = () => {
  //   navigate((`/jodi/dceaf015-5be8-4d5f-be66-a25133272c6b`))
  // }
  // const getSupremeDayPanel = () => {
  //   navigate((`/panel/dceaf015-5be8-4d5f-be66-a25133272c6b`))
  // }
  // const getSupremeiNightJodi = () => {
  //   navigate((`/jodi/11564cbe-3124-4b5d-aec4-f63f15c79f3b`))
  // }
  // const getSupremeNightPanel = () => {
  //   navigate((`/panel/11564cbe-3124-4b5d-aec4-f63f15c79f3b`))
  // }
  // const getMilanMorningJodi = () => {
  //   navigate((`/jodi/e11a336b-df52-4c5e-918b-1430b67bf6e6`))
  // }
  // const getMilanMorningPanel = () => {
  //   navigate((`/panel/e11a336b-df52-4c5e-918b-1430b67bf6e6`))
  // }
  // const getKalyanMorningJodi = () => {
  //   navigate((`/jodi/58ebcb86-e4ef-4fd6-864e-aec89b550661`))
  // }
  // const getKalyanMorningPanel = () => {
  //   navigate((`/panel/58ebcb86-e4ef-4fd6-864e-aec89b550661`))
  // }
  // const getRajdhaniMorningJodi = () => {
  //   navigate((`/jodi/ba1e93da-87c0-4b88-8457-b67079a2db37`))
  // }
  // const getRajdhaniMorningPanel = () => {
  //   navigate((`/panel/ba1e93da-87c0-4b88-8457-b67079a2db37`))
  // }
  // const getKarnatakaDayJodi = () => {
  //   navigate((`/jodi/530ef193-4f41-4730-bfb7-64e3dfbf7636`))
  // }
  // const getKarnatakaDayPanel = () => {
  //   navigate((`/panel/530ef193-4f41-4730-bfb7-64e3dfbf7636`))
  // }
  // const getKarnatakaNightJodi = () => {
  //   navigate((`/jodi/c4a0fed3-4f63-464c-84c9-8fcd675de2bf`))
  // }
  // const getKarnatakaNightPanel = () => {
  //   navigate((`/panel/c4a0fed3-4f63-464c-84c9-8fcd675de2bf`))
  // }

  // Functions for redirect to jodi page
  const getLotteryClubJodi = (data: any) => {
    navigate((`/jodi/${data.lotteryId}`))
  }
  const getLotteryClubPanel = (data: any) => {
    navigate((`/panel/${data.lotteryId}`))
  }

  // lottery-free-game  starts here
  const getLotteryFreeGame = () => {
    fetch(`${constants.BASE_URL}lottery-free-game/public/${today}`, {
      method: 'GET',
      headers: { 'Content-Type': 'application/json', 'Accept': 'application/json', 'Authorization': constants.getAuthHeader() },
    }).then(function (response) {
      return response.json();
    }).then(function (response: any) {
      if (response.status == '200') {
        setLotteryFreeGame(response.result);
        // response.result.forEach((element: any) => {
        //   if (element.name == 'TIME BAZAR') {
        //     setTimeBazar(element);
        //   } else if (element.name == 'MILAN DAY') {
        //     setMilanDay(element);
        //   } else if (element.name == 'RAJDHANI DAY') {
        //     setRajdhaniDay(element);
        //   } else if (element.name == 'KALYAN') {
        //     setKalyan(element);
        //   } else if (element.name == 'MILAN NIGHT') {
        //     setMilanNight(element);
        //   } else if (element.name == 'KALYAN NIGHT') {
        //     setKalyanNight(element);
        //   } else if (element.name == 'RAJDHANI NIGHT') {
        //     setRajdhaniNight(element);
        //   } else if (element.name == 'MAIN BAZAR') {
        //     setMainBazar(element);
        //   }
        // });
      } else {
        constants.showError(response.message);
      }
    });
  }

  // lottery-fix-game  starts here
  /*const getLotteryFixGame = () => {
    fetch(`${constants.BASE_URL}lottery-fix-game/public/${today}`, {
      method: 'GET',
      headers: { 'Content-Type': 'application/json', 'Accept': 'application/json', 'Authorization': constants.getAuthHeader() },
    }).then(function (response) {
      return response.json();
    }).then(function (response: any) {
      if (response.status == '200') {
        setLotteryFixGame(response.result);
        response.result.forEach((element: any) => {
          if(element.name == 'Faridabad Satta'){
            setFaridabad(element);
          } else if(element.name == 'Gaziyabad Satta'){
            setGaziyabad(element);
          } else if(element.name == 'Gali Satta King'){
            setGali(element);
          } else if(element.name == 'Disawar Satta'){
            setDisawar(element);
          }
        });
      } else {
        constants.showError(response.message);
      }
    });
  }*/


  //final matka ank starts here
  const getFinalMatkaAnk = () => {
    fetch(`${constants.BASE_URL}final-matka-ank/public/${today}`, {
      method: 'GET',
      headers: { 'Content-Type': 'application/json', 'Accept': 'application/json', 'Authorization': constants.getAuthHeader() },
    }).then(function (response) {
      return response.json();
    }).then(function (response: any) {
      if (response.status == '200') {
        setFmaList(response.result);
        // let ml: any = [];
        // let bl: any = [];
        // response.result.forEach((element: any) => {
        //   if (element.name == 'Main Mumbai' || element.name.includes('Night')) {
        //     ml.push(element);
        //   } else {
        //     bl.push(element);
        //   }
        // });
        // setBajarList(bl);
        // setMarketList(ml);
      } else {
        constants.showError(response.message);
      }
    });
  }
  // advertisement to show
  const getAdvertisement = () => {
    fetch(constants.BASE_URL + 'advertisement/public', {
      method: 'GET',
      headers: { 'Content-Type': 'application/json', 'Accept': 'application/json', 'Authorization': constants.getAuthHeader() },
    }).then(function (response) {
      return response.json();
    }).then(function (response: any) {
      if (response.status == '200') {
        setAdverisement(response.result);

      } else {
        constants.showError(response.message);
      }
    });


  }


  return (
    <>
      <div>
        {/* navigation */}
        {/* contact whtasapp Telegram starts */}
        {/* <a href="https://api.whatsapp.com/send?phone=51955081075&text=Hola%21%20Quisiera%20m%C3%A1s%20informaci%C3%B3n%20sobre%20Varela%202." className="float1" target="_blank">
          <i className="fa fa-whatsapp my-float1" />
        </a> */}
        {/* <a title="Explorer Telegram Profile" className="float2" href="https://telegram.org/"><i className="fab fa-telegram-plane my-float2"  />
        </a> */}
        {/* contact whtasapp Telegram ends */}
        <header className="navigation bg-tertiary">
          <div className="bg-dark">
            <div className='webname'><a href="https://www.worldsattamatka4u.com/">WorldSattamatka4u.com</a></div>
            {/* <nav className="navbar navbar-expand-xl navbar-light text-center py-3 ">
              <div className="container logoCenter">
                <NavLink className="navbar-brand" to="/">
                  <img src='images/mataklogo.jpg' />
                  <div  ><span>sattamatkatoppers.com</span></div>
                  <img decoding="async" className="img-fluid" width={560} src="assets/images/mataklogo.jpg" alt="Wallet" />
                </NavLink>
              </div>
            </nav> */}
            <div className=" container-fluid textalign text-white">
              <p className='pb-3'> Welcome to WorldSattamatka4u.com the most popular and trusted Satta Matka website. Our website is well informative and genuine for Official Satta Matka result, Today Game, Tips, and Trick Online. Fastest get Kalyan Satta Result at Sattamatka. I am Satta Matka king  today intensely guesses Kalyan matka, Satta, Matka, Matka Satta, Satta Matta Matka, Satta Batta, Satta Matka God and How to get Satta Matka Result fast or Live? I show you fix matka tips today and there free matka game and Satta tips Today. WorldSattamatka4u give you result, game, chart, news, and guessing.</p>
            </div>
          </div>
          <div className="container-fluid">
            <div className="text-center colorbrown  my-2 ">
              Get today live satta results and matka Open, Close, Jodi, Panel, and Sangam freely in official Matka Satta Result Website.
            </div>

          </div>

        </header>
        {/* /navigation */}
        <section className="section " id="service1">
          <div className="container-fluid">
            <div className="col-lg-12 col-md-12 lotheadingpink my-1">
              <h3 className="colorblue">SATTA MATKA</h3>
            </div>
            <div>
              {dailyResult.map((data: any, index) => (
                <div key={index}>
                  {((data.closeTimeDiff > 0) && (data.openTimeDiff > 0)) ? <div key={index}>

                    <div className="col-lg-12 col-md-12 lotheadinginfo my-1">
                      <div className="service-title1 bg-lotname ">{data.name}
                      </div>
                      <div className="lotresult">
                        {data.result ? data.result : 'Loading...'}
                      </div>
                      <button type="button" className="btn btn-light btn-sm refresh" onClick={() => window.location.reload()}> Refresh </button>
 <div className="text-center h4 mt-2" dangerouslySetInnerHTML={{ __html: data.description ? data.description : " " }}>
                    {/* {data.description ? data.description :" " } */}
                  </div>
                      <div className="opentime">{data.openTime} -{data.closeTime}
                      </div>
                    </div>
                  </div> : <div></div>}
                </div>
              ))}
            </div>

            <div className="col-lg-12 col-md-12 lotheadinginfo my-1">
              {/* <button type="button" className="btn btn-primary" onClick="myFunction()">Primary</button> */}
              <h4 className='redcolor' onClick={() => { window.open(`${advertisement?.topOneLink}`) }} dangerouslySetInnerHTML={{ __html: advertisement?.topOne }} ></h4>
              {/* <h4 className="redcolor">Download Applicatin Get Full Rate-Click Here</h4> */}
              {advertisement?.topOnePhoto && (
                <img className='img img-size' src={imageUrl + advertisement?.topOnePhoto + '/'} alt="" />
              )}
            </div>
            <div className="col-lg-12 col-md-12 lotheadingblue textalign borderviolet1  my-1">
              <div>
                <h4 className="whitecolor" onClick={() => { window.open(`${advertisement?.topTwoLink}`) }} dangerouslySetInnerHTML={{ __html: advertisement?.topTwo }} />
                {advertisement?.topTwoPhoto && (
                  <img className='img img-size' src={imageUrl + advertisement?.topTwoPhoto + '/'} alt="" />
                )}
              </div>
            </div>

            <div className="col-lg-12 col-md-12 lotheadinginfo my-1">
              <a href="#">
                <h4 className="colorbrown" onClick={() => { window.open(`${advertisement?.topThreeLink}`) }} dangerouslySetInnerHTML={{ __html: advertisement?.topThree }} />
                {/* <h4 className="redcolor">Download Applicatin Get Full Rate-Click Here</h4> */}
                {advertisement?.topThreePhoto && (
                  <img className='img img-size' src={imageUrl + advertisement?.topThreePhoto + '/'} alt="" />
                )}
              </a>
            </div>
            {/* <div className="col-lg-12 col-md-12 lotheadinglight my-1">
              <a href="#">
                <h5>Free guess at Telegram</h5>
                <h5>To join Telegram group</h5>
                <h5>Click Here</h5>
              </a>
            </div> */}
            {/* <div className="col-lg-12 col-md-12 lotheadingblue my-1 whitecolor">
              <a href="#">
                <h4 className="whitecolor">ANNOUNCEMENT FOR BOOKI</h4>
                <h5 className="whitecolor">1. Booki Cutting 10% Commission 9 Rate Or 5% Commission 9.5 Rate 2. Full Buav Milega Ld
                  Bhi Bana Sakte Hai Ap Agar Bada Vyappar Hai Toh</h5>
                <h5 className="whitecolor">All Market Direct Company Accounts Open Karne Ke Liye Booki Contact Kare</h5>
                <h4 className="whitecolor">Sattamatkatoppers : +91xxxxxxxxxx</h4>
              </a>
            </div> */}
            <div className="col-lg-12 col-md-12 borderblue1 text-white textalign my-1">
              <h5 className='text-white'>World's Fastest Online Live Satta Matka Result Today</h5>
            </div>
            {dailyResult?.map((data: any, index) => (
              <div key={index}>
                <div className="col-lg-12 lotheadingyellow12 col-md-12 my-1" style={{ backgroundColor: data.color || 'yellowgreen' }}>
                  <div
                    className='service-title1 lotname1'
                    style={{ color: data.fontColor || 'black' }}>
                    {data.name}
                  </div>
                  <div className="text-center result-color h4 mb-2">
                    {data.result ? data.result : '--'}
                  </div>
                  <div className="text-center h4 mb-2" dangerouslySetInnerHTML={{ __html: data.description ? data.description : " " }}>
                    {/* {data.description ? data.description :" " } */}
                  </div>

                  <div className="time row">
                    <div className='col'>
                      <div onClick={() => getLotteryClubJodi(data)} className="jodi">JODI</div>
                    </div>
                    <div className="col opentime">
                      {data.openTime} - {data.closeTime}
                    </div>
                    <div className='col'>
                      <div onClick={() => getLotteryClubPanel(data)} className="jodi float-right">PANEL</div>
                    </div>
                  </div>
                </div>
              </div>
            ))}


            {/* <div className='row'>
              <div className='col-lg-12 col-md-12 col-sm-12'>
                <div className=' lotheadingpink my-2 p-2'>
                  <div className='titlered'>
                    Satta King Result
                  </div>
                </div>
              </div>
              <div className='col-lg-6 col-md-6 col-sm-6 col-6 '>
                <div className="lotheadinglightr my-2">
                  <div className="titlered2">FARIDABAD</div>
                  <h5>47</h5>
                  <div className="time">(6.25 pm)
                  </div>
                </div>
              </div>
              <div className='col-lg-6 col-md-6 col-sm-6 col-6 '>
                <div className="lotheadinglightr my-2">
                  <div className="titlered2">GAZIYABAZ</div>
                  <h5>47</h5>

                  <div className="time">(6.25 pm)
                  </div>
                </div>
              </div>
            </div>

            <div className='row'>
              <div className='col-lg-6 col-md-6 col-sm-6 col-6 '>
                <div className="lotheadinglightr my-2">
                  <div className="titlered2">GALI</div>
                  <h5>38</h5>

                  <div className="time">(6.25 pm)
                  </div>
                </div>
              </div>
              <div className='col-lg-6 col-md-6 col-sm-6 col-6 '>
                <div className="lotheadinglightr my-2">
                  <div className="titlered2">DESAWER</div>
                  <h5>73</h5>

                  <div className="time">(6.25 pm)
                  </div>
                </div>
              </div>
            </div> */}
            <div className="col-lg-12 col-md-12 lotheadingpink my-1">
              <h4 className="titlered">DAILY FINAL MATKA ANK</h4>
              {/* <div className='smallfont'>"Matka Final Ank" is given from Matka office. From SattaMatka Office gave the Final Matka Number only for bookies, but we made revelations in public. This number never comes on open and close, but at today's time, sometimes it comes to the open. Everyday Morning 9:00 pm Daily Update Kalyan Matka Final ank, Main Bazar Final ank, Milan Matka Final Number, Rajdhani Satta Final Ank, And Kalyan Night Final ank Officially Publish By www.worldsattamatka4u.com
              </div> */}
              <h4 className="titlered">{today1}</h4>
              <div className='row'>
                <div className='col'>
                  <table className="table table-bordered table-warning">
                    <thead>
                      <tr>
                        <th scope="col">Market</th>
                        <th scope="col">Final Ank</th>
                      </tr>
                    </thead>
                    {fmaList?.map((finalmatkaankdata: any, index) => (
                      <tbody key={index}>
                        <tr>
                          <td>{finalmatkaankdata.lotteryClub?.name}</td>
                          <td>{finalmatkaankdata.finalAnk}</td>
                        </tr>
                      </tbody>
                    ))}
                    {/* <tbody>
                        {fmaList?.map((data: any, index: number) => (
                          <tr key={index}>
                            <td>{{data.lotteryClub?.name}}</td>
                            <td className="align-middle">{data.mobile}</td>
                          </tr>
                        ))}
                      </tbody> */}
                  </table>
                </div>
                {/* <div className='col'>
                  <table className="table table-bordered table-warning">
                    <thead>
                      <tr>
                        <th scope="col">Market</th>
                        <th scope="col">Final Ank</th>
                      </tr>
                    </thead>
                    {marketList?.map((finalmatkaankdata: any, index) => (
                      <tbody key={index}>
                        <tr>
                          <td>{finalmatkaankdata.name}</td>
                          <td>{finalmatkaankdata.finalAnk}</td>
                        </tr>
                      </tbody>
                    ))}
                  </table>
                </div> */}
              </div>
            </div>
            {/* <div className="col-lg-12 col-md-12 lotheadingwhite my-1">
              <h4 className="titlered">{today1}</h4>
              <h5 className="smallfont">Satta matka free game today publishes by Kalyan Matka king WorldSattamatka4u on public support. In this accurate prediction section gets - open, close, Jodi, panel, and Sangam. From 2021, our official team starts a free matka game for public. Free game are Sridevi, Madhuri, Milan morning, time bazar, Milan day, Rajdhani day, supreme day, Kalyan, Milan night, Rajdhani night, kalyan night, main bazar, old main Mumbai, and rose bazar night. For public information time: 10:00 am to 7:30 pm day games and time: 8:00 pm to 12:30 am night games updated by WorldSattamatka4u at WorldSattamatka4u.com</h5>
            </div> */}
            <div>
              {lotteryFreeGame?.map((lotteryGame: any, index: number) => (
                <div key={index} className="col-lg-12 col-md-12 bg-white lotheadinglight my-1">
                  <h1 className="lotname">{lotteryGame.lotteryClub?.name}</h1>
                  <h4 className="redcolor largefont fontnames">Open : {lotteryGame?.open ? lotteryGame?.open : '-'}</h4> 
                  <h4 className="greencolor largefont fontnames">Close : {lotteryGame?.close ? lotteryGame?.close : '-'}</h4>
                  <h4 className="colorwhite largefont fontnames">Panna : {lotteryGame?.panna ? lotteryGame?.panna : '-'}</h4>
                  <h4 className="redcolor largefont fontnames">Jodi : {lotteryGame?.jodi ? lotteryGame?.jodi : '-'}</h4>
                  <h4 className="greencolor largefont fontnames">Sangam : {lotteryGame?.sangam ? lotteryGame?.sangam : '-'}</h4>
                </div>
              ))}
            </div>

            {/* <div>
              <div className="col-lg-12 col-md-12 lotheadinglight my-1">
                <h1 className="lotname">MILAN DAY</h1>
                <div className="redcolor largefont ">Open - {milanDay?.open ? milanDay?.open : '-'}</div>
                <h4 className="greencolor largefont">Panna - {milanDay?.panna ? milanDay?.panna : '-'}</h4>
                <h4 className="colorwhite largefont">Jodi - {milanDay?.jodi ? milanDay?.jodi : '-'}</h4>
                <h4 className="redcolor largefont">Sangam - {milanDay?.sangam ? milanDay?.sangam : '-'}</h4>
              </div>
            </div>
            <div>
              <div className="col-lg-12 col-md-12 lotheadinglight my-1">
                <h1 className="lotname">RAJDHANI DAY</h1>
                <div className="redcolor largefont ">Open - {rajdhaniDay?.open ? rajdhaniDay?.open : '-'}</div>
                <h4 className="greencolor largefont">Panna - {rajdhaniDay?.panna ? rajdhaniDay?.panna : '-'}</h4>
                <h4 className="colorwhite largefont">Jodi - {rajdhaniDay?.jodi ? rajdhaniDay?.jodi : '-'}</h4>
                <h4 className="redcolor largefont">Sangam - {rajdhaniDay?.sangam ? rajdhaniDay?.sangam : '-'}</h4>
              </div>
            </div>
            <div>
              <div className="col-lg-12 col-md-12 lotheadinglight my-1">
                <h1 className="lotname">KALYAN</h1>
                <div className="redcolor largefont ">Open - {kalyan?.open ? kalyan?.open : '-'}</div>
                <h4 className="greencolor largefont">Panna - {kalyan?.panna ? kalyan?.panna : '-'}</h4>
                <h4 className="colorwhite largefont">Jodi - {kalyan?.jodi ? kalyan?.jodi : '-'}</h4>
                <h4 className="redcolor largefont">Sangam - {kalyan?.sangam ? kalyan?.sangam : '-'}</h4>
              </div>
            </div>
            <div>
              <div className="col-lg-12 col-md-12 lotheadinglight my-1">
                <h1 className="lotname">MILAN NIGHT</h1>
                <div className="redcolor largefont ">Open - {milanNight?.open ? milanNight?.open : '-'}</div>
                <h4 className="greencolor largefont">Panna - {milanNight?.panna ? milanNight?.panna : '-'}</h4>
                <h4 className="colorwhite largefont">Jodi - {milanNight?.jodi ? milanNight?.jodi : '-'}</h4>
                <h4 className="redcolor largefont">Sangam - {milanNight?.sangam ? milanNight?.sangam : '-'}</h4>
              </div>
            </div>
            <div>
              <div className="col-lg-12 col-md-12 lotheadinglight my-1">
                <h1 className="lotname">KALYAN NIGHT</h1>
                <div className="redcolor largefont ">Open - {kalyanNight?.open ? kalyanNight?.open : '-'}</div>
                <h4 className="greencolor largefont">Panna - {kalyanNight?.panna ? kalyanNight?.panna : '-'}</h4>
                <h4 className="colorwhite largefont">Jodi - {kalyanNight?.jodi ? kalyanNight?.jodi : '-'}</h4>
                <h4 className="redcolor largefont">Sangam - {kalyanNight?.sangam}</h4>
              </div>
            </div>
            <div>
              <div className="col-lg-12 col-md-12 lotheadinglight my-1">
                <h1 className="lotname">RAJDHANI NIGHT</h1>
                <div className="redcolor largefont ">Open - {rajdhaniNight?.open ? rajdhaniNight?.open : '-'}</div>
                <h4 className="greencolor largefont">Panna - {rajdhaniNight?.panna ? rajdhaniNight?.panna : '-'}</h4>
                <h4 className="colorwhite largefont">Jodi - {rajdhaniNight?.jodi ? rajdhaniNight?.jodi : '-'}</h4>
                <h4 className="redcolor largefont">Sangam - {rajdhaniNight?.sangam ? rajdhaniNight?.sangam : '-'}</h4>
              </div>
            </div>
            <div>
              <div className="col-lg-12 col-md-12 lotheadinglight my-1">
                <h1 className="lotname">MAIN BAZAR</h1>
                <div className="redcolor largefont ">Open - {mainBazar?.open ? mainBazar?.open : '-'}</div>
                <h4 className="greencolor largefont">Panna - {mainBazar?.panna ? mainBazar?.panna : '-'}</h4>
                <h4 className="colorwhite largefont">Jodi - {mainBazar?.jodi ? mainBazar?.jodi : '-'}</h4>
                <h4 className="redcolor largefont">Sangam - {mainBazar?.sangam ? mainBazar?.sangam : '-'}</h4>
              </div>
            </div> */}
            {/* <div className="col-lg-12 col-md-12 lotheadingwhite my-1">
              <h4 className="titlered">{today1}</h4>
              <h5 className="smallfont">Satta king - fix satta haruf and fix satta king Jodi publish by satta king Sattamatkatoppers. In this section you get fix three haruf, fix satta Jodi and today satta king single fix Jodi. Faridabad satta king number, today Ghaziabad satta Jodi, Gali satta king Jodi, and Disawar satta tips for a public update every day 3:00 pm. Get quickly free satta number, today satta tips, fix satta game, satta fix Jodi, and satta kalyan at www.sattamatkatoppers.com</h5>
            </div>
            <div>
              <div className="col-lg-12 col-md-12 lotheadingwhite my-1">
                <h1 className="titlered largefont"><span className="spanviolet">Faridabad Satta</span></h1>
                <h4 className="redcolor largefont">Haruf - {faridabad?.haruf ? faridabad?.haruf : '-'}</h4>
                <h4 className="greencolor largefont">Jodi - {faridabad?.jodi ? faridabad?.jodi : '-'}</h4>
                <h4 className="colorwhite largefont">Single Fix Jodi - {faridabad?.fixJodi ? faridabad?.fixJodi : '-'}
                </h4>
                <h4 className="yellowgreen largefont">Faridabad satta king number
                </h4>
              </div>
            </div>
            <div>
              <div className="col-lg-12 col-md-12 lotheadingwhite my-1">
                <h1 className="titlered largefont"><span className="spanviolet">Gaziyabad Satta</span></h1>
                <h4 className="redcolor largefont">Haruf - {gaziyabad?.haruf ? gaziyabad?.haruf : '-'}</h4>
                <h4 className="greencolor largefont">Jodi - {gaziyabad?.jodi ? gaziyabad?.jodi : '-'}</h4>
                <h4 className="colorwhite largefont">Single Fix Jodi - {gaziyabad?.fixJodi ? gaziyabad?.fixJodi : '-'}
                </h4>
                <h4 className="yellowgreen largefont">Today Gaziyabad satta Jodi
                </h4>
              </div>
            </div>
            <div>
              <div className="col-lg-12 col-md-12 lotheadingwhite my-1">
                <h1 className="titlered largefont"><span className="spanviolet">Gali Satta King</span></h1>
                <h4 className="redcolor largefont">Haruf - {gali?.haruf ? gali?.haruf : '-'}</h4>
                <h4 className="greencolor largefont">Jodi - {gali?.jodi ? gali?.jodi : '-'}</h4>
                <h4 className="colorwhite largefont">Single Fix Jodi - {gali?.fixJodi ? gali?.fixJodi : '-'}
                </h4>
                <h4 className="yellowgreen largefont">gali satta king jodi
                </h4>
              </div>
            </div>
            <div>
              <div className="col-lg-12 col-md-12 lotheadingwhite my-1">
                <h1 className="titlered largefont"><span className="spanviolet">Disawar Satta</span></h1>
                <h4 className="redcolor largefont">Haruf - {disawar?.haruf ? disawar?.haruf : '-'}</h4>
                <h4 className="greencolor largefont">Jodi - {disawar?.jodi ? disawar?.jodi : '-'}</h4>
                <h4 className="colorwhite largefont">Single Fix Jodi - {disawar?.fixJodi ? disawar?.fixJodi : '-'}
                </h4>
                <h4 className="yellowgreen largefont">Disawer satta king tips
                </h4>
              </div>
            </div> */}
            {/* advertisement starts */}
            <div className="col-lg-12 col-md-12 lotheadingblue my-1 whitecolor">
              <h4 className='whitecolor' onClick={() => { window.open(`${advertisement?.bottomOneLink}`) }} dangerouslySetInnerHTML={{ __html: advertisement?.bottomOne }} />
              {advertisement?.bottomOnePhoto && (
                <img className='img img-size' src={imageUrl + advertisement?.bottomOnePhoto + '/'} alt="" />
              )}
            </div>
            {/* advertisement starts */}

            <div id="login-section">
              <div className="col-lg-12 col-md-12 lotheadingwhite my-2" >
                <h4 className="titlered "><span>Login and Registration</span></h4>
                <div>
                  <form id="loginForm" method="post" onSubmit={handleSubmit2(login)}>
                    <div className="row my-4">
                      <div className="col-lg-3 col-md-0">
                      </div>
                      <div className="col-lg-2 col-md-12 col-sm-12 mb-3">
                        <input type="text" className="form-control" id="username" placeholder="Enter login id"
                          {...register2("userName", { required: true, pattern: /[a-zA-Z][a-zA-Z ]{3,}/, })} />
                        {errors2.username && <span className='text-danger'>Please enter username</span>}

                      </div>
                      <div className="col-lg-2 col-md-12 col-sm-12 mb-3">
                        <input type="password" className="form-control" id="loginPassword" placeholder="Password"
                          {...register2("password", { required: true })} />
                        {errors2.password && <span className='text-danger'>Please enter  password</span>}
                      </div>
                      <div className="col-lg-2 col-md-12 col-sm-12">
                        <button className="btn btn-primary btn-lg btnpadding" type="submit" >
                          {formSubmitLoader && <i className="fas fa-circle-notch fa-spin me-2"></i>}
                          Login
                        </button>
                      </div>
                      <div className="col-lg-3 col-md-12 col-sm-12 text-2 text-center">
                      </div>
                    </div>
                  </form>
                </div>
                <div className="col-lg-12 col-md-12 col-sm-12 text-2 text-center">
                  {/* <button type='button' className="btn btn-sm btn-primary jodi main-bg btnpadding1" data-toggle="modal" data-target="#forgot-password-modal">Forgot Password ?
                    </button> */}
                  {/* forgot password modal starts */}
                  <div className="modal" id="forgot-password-modal">
                    <div className="modal-dialog modal-sm">
                      <div className="modal-content lotheadingblue">
                        <div className="modal-body">
                          <div className="container">
                            <button type="button" className="close" data-dismiss="modal">×</button>
                            <div className="row">
                              <div className="col-lg-12 col-xl-12 mx-auto">
                                <div className="card flex-row my-5 border-0 shadow rounded-3 overflow-hidden">

                                  <div className="card-body p-4 p-sm-5">
                                    <h5 className="card-title text-center mb-5 fw-light fs-5">Reset Password</h5>

                                    <form className="form-border" method="post" onSubmit={handleSubmit3(forgotPassword)}>
                                      <div className="mb-3">
                                        <input type="tel" className="form-control" id="mobile" placeholder="Mobile Number"
                                          {...register3("mobile", { required: true, minLength: 10, maxLength: 13 })} />
                                        {errors.mobile && <span className='text-danger'>Please enter  valid mobile number</span>}
                                      </div>
                                      {/* <div className="mb-3">
                                              <input type="number" className="form-control" id="otp" placeholder="Enter OTP"
                                                {...register3("otp", { required: true })} />
                                              {errors3.otp && <span className='text-danger'>Please enter otp</span>}
                                            </div> */}
                                      <div className="mb-3 ">
                                        <div className='pass-wrapper'>
                                          <input type={passwordShown ? "text" : "password"} className="form-control" id="password" placeholder="New Password"
                                            {...register3("newPassword", { required: true, maxLength: 40 })} />
                                          <span><i onClick={togglePasswordVisiblity} className={`fa ${eye ? "fa-eye-slash" : "fa-eye"} passwordhide`} aria-hidden="true"></i></span>
                                        </div>
                                        {errors3.newPassword && <span className='text-danger'>Enter correct password.</span>}
                                      </div>
                                      <div className="d-grid my-4">
                                        <button className="btn btn-primary" type="submit" disabled={formSubmitLoader}>
                                          {formSubmitLoader && <i className="fas fa-circle-notch fa-spin me-2"></i>}
                                          {enableOtp && 'Reset Password'}
                                          {/* {!enableOtp && 'Continue'} */}
                                        </button>
                                      </div>
                                    </form>
                                    <p className="text-center mb-0"><a className="btn-link" href="" data-bs-toggle="modal" data-bs-target="#login-modal" data-bs-dismiss="modal">Return to Log In</a> <span className="text-muted mx-3">|</span> <a className="btn-link cursor-pointer" onClick={resendOtp}>Request OTP</a></p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          {/* Registration form ends*/}
                        </div>
                        {/* Modal footer */}
                        <div className="modal-footer">
                          <button type="button" className="btn btn-danger" data-dismiss="modal">Close</button>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* forgot password modal starts */}
                </div>
                <div className="col-lg-12 col-md-12 my-2">
                  <button type="button" className="btn btn-sm btn-primary main-bg btnpadding1" data-toggle="modal" data-target="#myModallogin">
                    Click to Register
                  </button>
                  {/*  register modal */}
                  <div className="modal" id="myModallogin">
                    <div className="modal-dialog modal-sm">
                      <div className="modal-content">
                        {/* Modal body */}
                        <div className="modal-body">
                          {/* Registration form starts*/}
                          <div className="container">
                            <button type="button" className="close" data-dismiss="modal">×</button>
                            <div className="row">
                              <div className="col-lg-12 col-xl-12 mx-auto">
                                <div className="card flex-row my-5 border-0 shadow rounded-3 overflow-hidden">
                                  {/* <div class="card-img-left d-none d-md-flex">
                            </div> */}
                                  <div className="card-body p-4 p-sm-5">
                                    <h5 className="card-title text-center mb-5 fw-light fs-5">Register</h5>
                                    <form id="signupForm" method="post" onSubmit={handleSubmit(userRegister)}>
                                      <div className="mb-3">
                                        <input type="text" className="form-control" id="fullName" placeholder="Full Name"
                                          {...register("name", { required: true, pattern: /[a-zA-Z][a-zA-Z ]{3,}/, maxLength: 45 })} />
                                        {errors.name && <span className='text-danger'>Please enter full name</span>}
                                      </div>
                                      <div className="mb-3">
                                        <input type="tel" className="form-control" id="mobile" placeholder="Mobile Number" minLength={10} maxLength={13}
                                          {...register("mobile", { required: true, pattern: /^(\+91[\-\s]?)?[0]?(91)?[789]\d{9}$/, minLength: 10, maxLength: 13 })} />
                                        {errors.mobile && <span className='text-danger'>Please enter  valid mobile number</span>}
                                      </div>
                                      <div className="mb-3 ">
                                        <div className='pass-wrapper'>
                                          <input type={passwordShown ? "text" : "password"} className="form-control" id="loginPassword" placeholder="Password"
                                            {...register("password", { required: true, maxLength: 40 })} />
                                          <span><i onClick={togglePasswordVisiblity} className={`fa ${eye ? "fa-eye-slash" : "fa-eye"} passwordhide`} aria-hidden="true"></i></span>
                                        </div>
                                        {errors.password && <span className='text-danger'>Password must contain minimum five  characters</span>}
                                      </div>
                                      {/* <div className="form-check text-3 my-4">
                                          <input id="agree" name="agree" className="form-check-input" type="checkbox" required />
                                          <label className="form-check-label text-2">I agree to the <a href="/termsofuse">Terms</a> and <a href="/privacypolicy">Privacy Policy</a>.</label>
                                        </div> */}
                                      <div className="d-grid my-4">
                                        <button className="btn btn-primary" type="submit" disabled={formSubmitLoader}>
                                          {formSubmitLoader && <i className="fas fa-circle-notch fa-spin me-2"></i>}
                                          Sign Up
                                        </button>
                                      </div>
                                    </form>
                                    {/* <p className="text-2 text-center mb-0">Already have an account? <a className="btn-link" href="" data-bs-toggle="modal" data-bs-target="#login-modal" data-bs-dismiss="modal">Log In</a></p> */}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          {/* Registration form ends*/}
                        </div>
                        {/* Modal footer */}
                        <div className="modal-footer">
                          <button type="button" className="btn btn-danger" data-dismiss="modal">Close</button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* advertisement starts */}
            <div className="col-lg-12 col-md-12 lotheadinglight my-1">
              <h4 className='redcolor' onClick={() => { window.open(`${advertisement?.bottomTwoLink}`) }} dangerouslySetInnerHTML={{ __html: advertisement?.bottomTwo }} />
              {advertisement?.bottomTwoPhoto && (
                <img className='img img-size' src={imageUrl + advertisement?.bottomTwoPhoto + '/'} alt="" />
              )}
            </div>
            {/* advertisement starts */}
            {/* Telegram link */}
            {/* <div className="col-lg-12 col-md-12 lotheadinglight my-1">
                <h5>To join Telegram group</h5>
                <h5>Click Here</h5>
            </div> */}
            {/* Telegram link */}

            <div className="col-lg-12 col-md-12 lotheadingwhite my-1">
              <div className="col-lg-12 col-md-12 my-1 ">
                <div className="Imp_content">Important Links :-</div>
              </div>
              <div className="col-lg-12 col-md-12 my-1 ">
                <NavLink to='/matkaguessing'>
                  <h4><div className="Guessing_Link">Matka Guessing Forum</div> </h4>
                </NavLink>
              </div>

              <div className="col-lg-12 col-md-12 my-1 ">
                <a href="/all-satta-matka-charts"><h4><div className="Guessing_Link">All Satta Matka Charts</div> </h4></a>
              </div>

            </div>
            <div className="col-lg-12 col-md-12 lotheadingblue my-1">
              <p className="mediumfont">
                Satta matka chart: This chart will help you to guess accurate matka game. All satta matka website would have a chart section, but our records (chart) are eye protected and user-friendly like zoom in or zoom out function and search option available. In this page you get many charts and there are two different types like 1. Jodi chart, 2. Panel Chart (Patti Chart). Previous results data collection from Matka Office and written datewise and make charts and records for all satta market. Too many Indian people assume a number and bet but that is wrong process, help of this chart you can find out an accurate matka satta number for play and earn case. Here you get Oldest Satta Charts and Records.
              </p>
              <h4 className="mediumfont">
                Helping Of Satta Matka Jodi Charts Find Out accurate number for Open, Close, and Jodi. Helping Of Satta Matka Panel Chart find out Open Panel, Close Panel, Half sangam, and Jackpot Mean Sangam.
              </h4>
              {lotteryClubList.map((club, index) => (
                <React.Fragment key={index}>
                  <div className="col-lg-12 col-md-12 my-3">
                    <h4>
                      <div
                        className="spanviolet1 cursor-pointer"
                        onClick={() => getLotteryClubJodi(club)}
                      >
                        {club.name} Jodi Chart
                      </div>
                    </h4>
                  </div>
                  <div className="col-lg-12 col-md-12 my-3">
                    <h4>
                      <div
                        className="spanviolet1 cursor-pointer"
                        onClick={() => getLotteryClubPanel(club)}
                      >
                        {club.name} Panel Chart
                      </div>
                    </h4>
                  </div>
                </React.Fragment>
              ))}
            </div>
            <div className="col-lg-12 col-md-12 lotheadingwhite timesitalic">
              <h2 className="redcolor timesitalic">SATTA MATKA KING</h2>
              <h4>WorldSattamatka4u</h4>
              <h5 className="redcolor"> Get latest updates, satta matka results</h5>
              <p className="colorblue justify-content-center">Get fastest live update sattamatka market results fastest live update with best guessers. In this online world, you can find a variety of sattamatka market is available. In this 21st century, matka satta becomes one of the popular and unique games in the whole world. This satta matka game has originated in 1970 but some new features have been added in the current time. If you want to know how to play this tricky gambling Indian satta matka game, get all tips &amp; tricks from our trusted satta matka website. Here you can get the best strategy &amp; satta matka tips to play this game and to avail yourself of this game for winning more amount money.</p>
              <div>
                <h5 className="redcolor"> Following Are Key Benefits You Get Only With WorldSattamatka4u:</h5>
                <p className="colorblue">WorldSattamatka4u is a reliable satta matka websites that will help with all details related to the game of Kalyan matka at one platform. You can find here Matka- Jodi, Panel, Open-Close, Sangam, Jackpot, Lastest Result, Matka Chart and Lifetime trick and tips and many more. At present, there are just some reputable and trusted sites accessible in India to play Satta King online. If you've begun playing sattamatka and are looking to win, your success is contingent on the selection of the most popular game and its rules. There are numerous benefits that you get only with WorldSattamatka4u include: We provide the latest updates and fastest results, we provide sattamatka strategies and tips. We offer you the most effective interface to make guesses and win massive amounts of money. We promise you the best matka game online with tips and tricks from Kalyan in playing online matka. We provide you with the best kalyan matka tips so you can fulfill your desires with luck. Take part in Matka Online Today! Our aim is to mirror your passion and enthusiasm for playing by providing the top matka play online. We cover the majority of the matka play markets, including Rajdhani and Kalyan as well as main the Milan and Main Bazar. Our website is user-friendly and mobile responsive.</p>
              </div>
              {/* <div>
                <h5 className="redcolor">Tips to Increase your Sattamatka Knowledge</h5>
                <p className="colorblue">A new Satta Matka player can copy the experiences of an experienced player. However, the issue is how a player receives the guidance of a seasoned player. There is Satta Matka site that can help you become active. They will help you achieve success. Additionally, there are many fake websites, but we have to discover the genuine websites. Sattamatkagods is the sole website that is the only official website with more than 100 professional Satta Matka guessers to assist you with Satta Matka game such as Kalyan matka, Milan matka, Rajdhani matka, Milan, and Delhi games. Players must adhere to certain strategies to increase your Sattamatka Knowledge such as: Accurate Matka Guessing, using the top Matka Guesser, study Matka Tricks and Tips Visit the official site of Sattamatka read blogs and articles and check the Lifetime Matka Trick, etc.</p>
              </div>
              <div>
                <h5 className="redcolor">Our Endeavor is a Jodi In Matka Satta</h5>
                <p className="colorblue">WorldSattamatka4u provide you with the best and accurate prediction on your favorite Jodi in Matka Satta. We provide the top Matka results to our customers. Our Endeavor is to make your dreams come true and give you the best of luck in life. We also provide the opportunity of winning some money from their favorite matches and help the people win with ease and convenience, by providing them the latest information on Matka Satta.</p>
                <p className="colorblue">Our company's mission is to provide our users with the best services possible so that they may enjoy their experience while playing on our platform. We are constantly looking for new ways to improve your game by providing you with a better user interface and faster loading speeds than any other website or application out there!</p>
              </div>
              <div>
                <h5 className="redcolor">Result Driven Satta Batta Strategy</h5>
                <p className="colorblue">WorldSattamatka4u is a company that provides you with the best result driven Satta Matka strategy to help you win at the game of luck. We offer a variety of services to our clients, which include live prediction and automated predictions for those who don't have time to check their numbers every day. If you're looking for an honest service that can provide results, then Satta Matka Gods is your answer!</p>
                <p className="colorblue">The company is dedicated to making sure that people are happy with their purchase of a Satta Matka prediction. The company helps people win money with their Matka tips. They have done this by having 24/7 customer service, which can be reached through email or phone calls, as well as live chat support.</p>
              </div> */}
              <div>
                <h5 className="redcolor">Indian Customer can see Fastest Live Satta Matka results</h5>
                <p className="colorblue">WorldSattamatka4u is a trusted platform for Indian customers to see live Satta Matka results. It is a one-stop destination for all your needs related to matka predictions and data. The site has been designed by professionals in the industry with inputs from experts who have years of experience in this field. WorldSattamatka4u.com is an independent website that provides live updates on Satta matkas across India from various sources including Newspapers, TV channels, websites, etc., and it also provides information about different types of lottery games played in India such as Satta Matka Result, Milan Morning, Faridabad Satta, Gaziyabad Satta, Gali Satta King, Disawar Satta Tips, Matka Guessing, Weekly Jodi Patti, Sridevi Jodi Chart, Satta Matka Chart, Kalyan Matka Result, Today Matka Jodi, Madhuri Jodi Chart, Madhuri Panel Chart, Time Bazar Jodi Chart, Time Bazar Panel Chart, Milan Day Jodi Chart, Milan Day Panel Chart, Rajdhani Day</p>
                <p className="colorblue">Jodi Chart, Rajdhani Day Panel Chart, Kalyan Matka Jodi Chart and More!</p>
                <p className="colorblue">The website also provides the fastest and most accurate information on all types of Indian lotteries to its users with tips and tricks to increase their chances of winning the games. In addition, it has a forum where members can discuss everything related to matka results and share their experiences with other members.</p>
                <p className="colorblue">WorldSattamatka4u is the best source for Indian customers to see live Satta Matka results. With our fast and accurate service, you can always win with us! The company's mission is to provide gambling services that are both secure and accessible while keeping gamblers entertained through its various Satta Matka prediction and guessing services. Play now and enjoy your winning chance!</p>
              </div>
            </div>
            {/* <div className="col-lg-12 col-md-12 lotheadingblue my-1">
              <div className="col-lg-12 col-md-12 ">
                <section className="section">
                  <div className="container">
                    <div className="row justify-content-center timesitalic">
                      <div className="col-12">
                        <div className="section-title text-center mb-5 pb-2">
                          <p className="text-primary text-uppercase fw-bold mb-3">Questions You Have</p>
                          <h1>Frequently Asked Questions</h1>
                        </div>
                      </div>
                      <div className="col-lg-12">
                        <div className="accordion shadow rounded py-5 px-0 px-lg-4 bg-white position-relative" id="accordionExample">
                          <div className="accordion-item p-1 mb-2">
                            <button className="accordion-header redcolor h5 border-0 active" id="heading-1"
                              type="button" data-bs-toggle="collapse" data-bs-target="#collapse-1" aria-expanded="true" aria-controls="collapse-1">What to know before you start the Satta Matka Market online game?
                            </button>
                            <div id="collapse-1" className="accordion-collapse collapse border-0 show colorblack" aria-labelledby="heading-1" data-bs-parent="#accordionFAQ">
                              <div className="accordion-body py-0 content">Our satta matta matka expert discusses all types of satta matka game including Kalyanmatka, satta batta, kalyan satta, and how to get satta matka result quickly. People search on Goggle via the keywords satta matka net or satta matka com but you can get trusted fast satta news and all details about games on our website. We offer you the trustworthy platform to play Indian satta matka in a safe way. Here you can find wide range of including kalyan matka game, kalyan matka Bazar, matka satta kalyan, matka satta chart, Milan matka chart, Rajdhani matka chart, live satta result, fastest matka result, kalyan matka Jodi, fix matka Jodi, kalyan satta Jodi and many more. We also provide you matka result today - SattaMatta, weekly Jodi and satta king result, free satta matka and tips.</div>
                            </div>
                          </div>
                          <div className="accordion-item p-1 mb-2">
                            <button className="accordion-header h5 border-0 redcolor active" id="heading-2" type="button" data-bs-toggle="collapse" data-bs-target="#collapse-2" aria-expanded="false" aria-controls="collapse-2">How our Sattamatka expert guide you?
                            </button>
                            <div id="collapse-2" className="accordion-collapse collapse border-0 show colorblack " aria-labelledby="heading-2" data-bs-parent="#accordionFAQ">
                              <div className="accordion-body py-0 content">Our experts guide you in Satta Bazar; sattamatka guessing tips quickest (fastest) matka result. Our best wishes and your good luck will make you become a sattamatka winner. www.sattamatkagods.net official is the #1 India sattamatka website, where people can find sattamatka fastest result and more. Our innovative tips &amp; tricks help you to play this satta matka game properly. The unique tips provided by our experienced matka guessers help you to win the gambling game and become the satta king and earn huge amount of money as well. With the advanced best satta matka guessing technique, our secure website will get you all the charts of kalyan satta matta that will provide you the most super-fast satta matka kalyan chart. Just you need to visit our website daily for updated pieces of information.</div>
                            </div>
                          </div>
                          <div className="accordion-item p-1 mb-2">
                            <button className="accordion-header h5 border-0 redcolor active" id="heading-3" type="button" data-bs-toggle="collapse" data-bs-target="#collapse-3" aria-expanded="false" aria-controls="collapse-3">Are you looking for free kalyan matka tips?
                            </button>
                            <div id="collapse-3" className="accordion-collapse collapse border-0 show colorblack " aria-labelledby="heading-3" data-bs-parent="#accordionFAQ">
                              <div className="accordion-body py-0 content">Get free kalyan matka tips, Rajdhani matka result, Kalyanmatka tips, matka satta tips, satta batta from our experts. You can discuss any queries for quick earnings here. Our website provides you the best opportunity for people to get engaged in satta matka Bazar. So what are you thinking about, start playing on sattamatka market &amp; kalyan market with an explosion, we offer you a chance to earn masses with our best satta matka suggestions. We provide you a 100% secure game platform with a promised sure matka number. It is the best satta matka website that can help you earn the best by playing the game online.</div>
                            </div>
                          </div>
                          <div className="accordion-item p-1 mb-2">
                            <button className="accordion-header h5 border-0 redcolor active" id="heading-4" type="button" data-bs-toggle="collapse" data-bs-target="#collapse-4" aria-expanded="false" aria-controls="collapse-4">How to play MatkaSatta game?
                            </button>
                            <div id="collapse-4" className="accordion-collapse collapse border-0 show colorblack " aria-labelledby="heading-4" data-bs-parent="#accordionFAQ">
                              <div className="accordion-body py-0 content">The matka satta game starts with the player picking their first set of three numbers. Players can pick a number between 0 and 9, for example, 1, 5, and 7. And then these picked three numbers are then added up - 1+5+7= 13. The first digit of that total number is dropped, leaving '3'. And the final collection then looks like 1, 5, and 7*3. And in the same way, players can choose 2nd set of numbers as above. Players can play sattamatka in several markets, like 7. Kalyan matka, satta batta, Rajdhani matka, Milan matka, matka Bazar etc. Just choose any one market out of the options provided and start betting. You just need to play choose the sattamatka lottery you wish to play first, then select the bookie with whom you want to play and then follow the satta calculation formula, then decide on the bet type. Select the part in which you want to place your bet, and last you need to choose your preferred number(s) and place a bet. And finally, check the results.</div>
                            </div>
                          </div>
                          <div className="accordion-item p-1 mb-2">
                            <button className="accordion-header h5 border-0 redcolor active" id="heading-5" type="button" data-bs-toggle="collapse" data-bs-target="#collapse-5" aria-expanded="false" aria-controls="collapse-5">What is the minimum deposit amount to play Sattamatka online?
                            </button>
                            <div id="collapse-5" className="accordion-collapse collapse border-0 show colorblack " aria-labelledby="heading-5" data-bs-parent="#accordionFAQ">
                              <div className="accordion-body py-0 content">The minimum deposit amount is Rs. 100 to rs.500/-. After deposit, players can play and bet on any market they want. As we know to play sattamatka online, the player would require an account, they may start with 500 deposit matka or with a small amount account of 100 deposit matka. You can start your game with a small amount of money investment because if you lose the game then you have low risk. But when you know about the tricks of this game then you can invest more money to make a huge amount of money from this game. Anyone with having a low budget can play this game and earn big money for their luxury life even without qualification.</div>
                            </div>
                          </div>
                          <div className="accordion-item p-1 mb-2">
                            <button className="accordion-header h5 border-0 redcolor active" id="heading-6" type="button" data-bs-toggle="collapse" data-bs-target="#collapse-6" aria-expanded="false" aria-controls="collapse-6">Does Satta matka chart contain all Satta Market?
                            </button>
                            <div id="collapse-6" className="accordion-collapse collapse border-0 show colorblack " aria-labelledby="heading-6" data-bs-parent="#accordionFAQ">
                              <div className="accordion-body py-0 content">Yes, the sattamatka chart contains all the satta markets, like Milan day, Milan night, Gali satta king, Kalyan matka Bazar, today satta king, satta king Jodi, syndicate night, etc. Sattamatka chart is the big bet platform where many people are this betting game to earn more money. This game is based on calculation, formulas, and mathematics. Sattamatka chart contains all satta markets so that all users get any kind of satta result easily, it does not matter what they want to see in this sattamatka chart.</div>
                            </div>
                          </div>
                          <div className="accordion-item p-1 mb-2">
                            <button className="accordion-header h5 border-0 redcolor active" id="heading-7" type="button" data-bs-toggle="collapse" data-bs-target="#collapse-7" aria-expanded="false" aria-controls="collapse-7">How can we win Matka Game?
                            </button>
                            <div id="collapse-7" className="accordion-collapse collapse border-0 show colorblack " aria-labelledby="heading-7" data-bs-parent="#accordionFAQ">
                              <div className="accordion-body py-0 content">one can be won matka game with his experience and tricks. Getting perfect guess of matka, game one can win a huge amount of money. The player can play safely by playing 3 or 4 digits daily and if you want to get these digits then you can take them from our website. But you need to remember always, you need to be very practical and functional while playing sattamatka. Your luck matters, every day can't be your lucky day! So, you may win on some days and may lose on others. But, you mustn't lose your moods or hope as winning is cooler than losing in the game of SattaMatka.</div>
                            </div>
                          </div>
                          <div className="accordion-item p-1 mb-2">
                            <button className="accordion-header h5 border-0 redcolor active" id="heading-8" type="button" data-bs-toggle="collapse" data-bs-target="#collapse-8" aria-expanded="false" aria-controls="collapse-8">What are Kalyan Matka Tips?
                            </button>
                            <div id="collapse-8" className="accordion-collapse collapse border-0 show colorblack " aria-labelledby="heading-8" data-bs-parent="#accordionFAQ">
                              <div className="accordion-body py-0 content">Sattamatka is a number gambling game. Players just need to choose the right numbers to win and earn more money. There are many tips by following which you can win a good amount of money with no loss. Among them, top kalyan matka tips such as 1. Play with fewer amounts 2. Always set your profit targets 3. Calculations are necessary. By following these golden rules, you can win the kalyan matka gambling game easily. When a player well understands these tips and tricks then he can be a satta king and earn a big money. Experience and luck matter in this online betting game. So don't worry just begin your betting with a small amount of investment and try your luck now with our website. Where you can get a good online betting experience.</div>
                            </div>
                          </div>
                          <div className="accordion-item p-1 mb-2">
                            <button className="accordion-header h5 border-0 redcolor active" id="heading-81" type="button" data-bs-toggle="collapse" data-bs-target="#collapse-81" aria-expanded="false" aria-controls="collapse-81">Is it compulsory to have a strong level of expertise and qualification to play Kalyan Satta Matka game?
                            </button>
                            <div id="collapse-81" className="accordion-collapse collapse border-0 show colorblack " aria-labelledby="heading-81" data-bs-parent="#accordionFAQ">
                              <div className="accordion-body py-0 content">No, there is no such fancy qualification or skills requirement. But players must have good experience to win this gambling game. That means the better the player has experience of this game, the better he/she perform. Players no need to have any degree or certifications to participate in this game. It is the best game for unemployed and uneducated people. They can learn money for their luxury life. They can make a huge amount of money so that they can improve their lifestyle with their playing experience. This online betting game is the best platform for those people who are seeking for earning money with no education. They can live a standard and luxurious life by playing this game with their experiences.</div>
                            </div>
                          </div>
                          <div className="accordion-item p-1 mb-2">
                            <button className="accordion-header h5 border-0 redcolor active" id="heading-9" type="button" data-bs-toggle="collapse" data-bs-target="#collapse-9" aria-expanded="false" aria-controls="collapse-9">Is Sattamatka game legal in India?
                            </button>
                            <div id="collapse-9" className="accordion-collapse collapse border-0 show colorblack " aria-labelledby="heading-9" data-bs-parent="#accordionFAQ">
                              <div className="accordion-body py-0 content">Sattamatka is legal in India only on the online platform. But before playing you need to choose the best-trusted betting game. We are the trusted genuine site for online satta matta game. But if this betting or gambling game is not legal when you start playing it offline. It all depends on the entertainment industry what you should guess the online sattamatka game digitally. But satta or gambling games like casino and other offline gambling games are banned in India as they became social problems. Most people have still afraid that this game is illegal but playing gambling online is not a crime.</div>
                            </div>
                          </div>
                          <div className="accordion-item p-1 mb-2">
                            <button className="accordion-header h5 border-0  redcolor active" id="heading-10" type="button" data-bs-toggle="collapse" data-bs-target="#collapse-10" aria-expanded="false" aria-controls="collapse-10">Are Sattamatka game results genuine?
                            </button>
                            <div id="collapse-10" className="accordion-collapse collapse border-0 show colorblack " aria-labelledby="heading-10" data-bs-parent="#accordionFAQ">
                              <div className="accordion-body py-0 content">Yes, if you are playing from a trusted site like www.sattamatkagods.net, the sattamatka game results displayed on this site are correct. You just need to understand the betting game appropriately and be aware of the game results declaration date. Here you can get very fast Satta Matka result, sattamatka, Kalyanmatka, sattamatka chart, matka result, sattamatka result, fix matka number and more. Our website is the best platform for betting on the online game; here you will get the ideal and perfect guessing by our best guesser and quick matka result. Are sattamatka game results genuine or not, it depends on which website you are choosing for sattamatka game results checking. Always go for a trustworthy website.</div>
                            </div>
                          </div>
                          <div className="accordion-item p-1 mb-2">
                            <button className="accordion-header h5 border-0 redcolor active" id="heading-11" type="button" data-bs-toggle="collapse" data-bs-target="#collapse-11" aria-expanded="false" aria-controls="collapse-11">Are there any tips to choose trusted website to play Sattamatka game?
                            </button>
                            <div id="collapse-11" className="accordion-collapse collapse border-0 show colorblack " aria-labelledby="heading-11" data-bs-parent="#accordionFAQ">
                              <div className="accordion-body py-0 content">Sattamatka is one of the most popular and common lottery games in India. In this digital era, these games are betted online and get entertaining gaming skills. These games are the best suggestions for fun and earning purposes too. In India a several numbers of people betting on the matka game for several clarifications. These online betting games became popular as they offer fun, cover earlier loss, greediness, luck valuation, and other efforts. People can understand these game rules as they are very easy to understand and people can play the games simply. By following some tips and tricks players can improve their playing the winning probabilities.</div>
                            </div>
                          </div>
                          <div className="accordion-item p-1 mb-2">
                            <button className="accordion-header h5 border-0 redcolor active" id="heading-12" type="button" data-bs-toggle="collapse" data-bs-target="#collapse-12" aria-expanded="false" aria-controls="collapse-12">How to get Matka Jodi?
                            </button>
                            <div id="collapse-12" className="accordion-collapse collapse border-0 show colorblack " aria-labelledby="heading-12" data-bs-parent="#accordionFAQ">
                              <div className="accordion-body py-0 content">There is no idea or formula in actuality for it. Players having good experience can get matka jodi, it all depends on the numbers you choose. So, try to pick the most encouraging sets of numbers. If you are a new player and want to start playing, you do not need to hesitate to play satta game, here our expert will help you start to the end step and snatch your chance to win a big amount definitely. Just follow our tips &amp; trick and start playing online. If you have any questions regarding the game, you can post your question on our forum page; we will answer your question as soon as possible.</div>
                            </div>
                          </div>
                          <div className="accordion-item p-1 mb-2">
                            <button className="accordion-header h5 border-0 redcolor active" id="heading-13" type="button" data-bs-toggle="collapse" data-bs-target="#collapse-13" aria-expanded="false" aria-controls="collapse-13"> Is online Satta playing a good choice?
                            </button>
                            <div id="collapse-13" className="accordion-collapse collapse border-0 show colorblack " aria-labelledby="heading-13" data-bs-parent="#accordionFAQ">
                              <div className="accordion-body py-0 content">online Satta playing is a good choice because it is very convenient. You can play from the comfort of your own home, and you don't have to go anywhere. There are also many different games to choose from, so you can find one that you really enjoy. And if you ever have any questions or problems, there are always customer service representatives available to help you. So overall, online Satta playing is a great choice for anyone who wants convenience and variety. Thanks for considering it!</div>
                            </div>
                          </div>
                          <div className="accordion-item p-1 mb-2">
                            <button className="accordion-header h5 border-0 redcolor active" id="heading-14" type="button" data-bs-toggle="collapse" data-bs-target="#collapse-14" aria-expanded="false" aria-controls="collapse-14">What is the purpose of the Satta website?
                            </button>
                            <div id="collapse-14" className="accordion-collapse collapse border-0 show colorblack " aria-labelledby="heading-14" data-bs-parent="#accordionFAQ">
                              <div className="accordion-body py-0 content">The purpose of the Satta website is to provide information on the Indian lottery. The Satta website is a platform designed to connect people who want to gamble on Indian lotteries with authorized sellers. The site allows users to place bets on lotteries, receive information about upcoming lotteries, and collect winnings. In addition, the site provides a forum for users to discuss Indian lotteries and share tips on how to win.</div>
                            </div>
                          </div>
                          <div className="accordion-item p-1 mb-2">
                            <button className="accordion-header h5 border-0 redcolor active" id="heading-15" type="button" data-bs-toggle="collapse" data-bs-target="#collapse-15" aria-expanded="false" aria-controls="collapse-15">How can my free game help my business grow?
                            </button>
                            <div id="collapse-15" className="accordion-collapse collapse border-0 show colorblack " aria-labelledby="heading-15" data-bs-parent="#accordionFAQ">
                              <div className="accordion-body py-0 content">There are a lot of benefits to using a free Satta game for your business. First, it's an excellent way to engage with customers and keep them coming back. Second, it can help grow your audience by converting new players into followers and fans. Third, it provides valuable data that you can use to optimize your marketing efforts and improve your results overall.
                                If you're looking for ways to boost revenue or increase brand awareness without spending money on traditional advertising, give a free Satta game a try!</div>
                            </div>
                          </div>
                          <div className="accordion-item p-1 mb-2">
                            <button className="accordion-header h5 border-0 redcolor active" id="heading-16" type="button" data-bs-toggle="collapse" data-bs-target="#collapse-16" aria-expanded="false" aria-controls="collapse-16">Why is a live Satta market important in public?
                            </button>
                            <div id="collapse-16" className="accordion-collapse collapse border-0 show colorblack " aria-labelledby="heading-16" data-bs-parent="#accordionFAQ">
                              <div className="accordion-body py-0 content">A live Satta market is important for many reasons. A major one is that it provides people with a way to gamble, which can be very entertaining or even lucrative depending on how much money you have and what your luck looks like at the time. Gambling has always been an integral part of Indian culture, so this is nothing new or revolutionary by any means. You can also use the game to drive traffic to your website or social media pages. In addition, you can use the game to collect email addresses from players so that you can market to them later on. Overall, there are many ways that a free game can help promote your business and grow your customer base.</div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            </div> */}
            <div className="col-lg-12 col-md-12 lotheadingwhite my-1">
              <p className="colorbrown mediumfont">WorldSattamatka4u: indian satta matka boss people - get matka satta, sata mataka, satta mataka, satta matka net, satta matka .com, satta matka chart, kalyan matka result, today matka jodi, kalyan main matka tips, kalyan matka guru, aaj ka satta kalyan jodi, today satta number, satta matta matka, sata matka, satta result, rajshree matka result, satta kalyan, satta matka com, satta matka mobi, matka boss, sattamataka143, sattabatta, indianmatka, result matka, sattaka matka com, kalyan chart, kalyan result, kalyan panel chart, kalyan satta, satta matka kalyan, satta matka com, satta matka net, satta batta, fix fix fix satta number, www.matka.com, sattamatka.com, worldsattamatka4u.com, sattamatka.mobi, dpboss.net, Satta matka 143, ka matka, worli matka, matka bajar, satta matta matka 143, satta chart, सटका का मटका, सटका मटका, सट्टा मटका</p>
            </div>
            <div className="col-lg-12 col-md-12 lotheadingwhite my-1">
              <div className="mediumfont">The new game of Satta Matka became rich. Satta bazaar's morning, day, the night also evening time opening and closing results. All the information on all game results, daily games, and news are given on our website right now. People now are finding matka through some secret number like 420, 786, 220, 143, 111, 440 and many more.</div>
            </div>
            <div className="col-lg-12 col-md-12 lotheadingwhite my-1">
              <h4 className="titlered"><span className="spanviolet">Satta Matka In Hindi</span></h4>
              <div className="smallfont">किया आप को पता हे? एसा एक खेल जो खेल कर लोग बहत पैसा कमाते हें उस खेल का नाम हे - सट्टा मटका (Satta Matka) | ये एक खेल हे जिसमे आप पैसा लगा कर बहत कम समय पर जादा पैसा कमा सकते हें | कल्याण मटका (Kalyanmatka) भारत में घर घर में खेला जानेवाला सब से जादा खेल हे | हमारे वेबसाइट पर मटका रिजल्ट (Matka Result) सब से तेज्ज़ लाइव प्रसारण किया जाता हे सीधा सट्टा (Satta) ऑफिस से | इस सटका मटका (Satka Matka) और सत्ता मटका (Sata Matka) सब से जादा लोग फ्री मटका सत्ता गेम और सत्ता मटका रिजल्ट देखने आते हें | आज के समय पर मटका (Matka) और सत्ता बहत ही आसानी हे खेल कर पैसा कमाया जासकता हे | जब से कोरोना भारत में आया हे तभी से सट्टा मत्ता मटका (Satta Matta Matka) खेल बहत ही तेज़ी से फ्हेल रहा हे |</div>
            </div>

          </div>
        </section>
        {/* new section starts  here */}
      </div>
      <div className='bottomrefresh'>   <button type="button" className="btn btn-info btn-sm" onClick={() => window.location.reload()}> Refresh</button></div>

    </>
  )
}
