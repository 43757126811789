import React, { useEffect, useState } from 'react'
import { NavLink } from 'react-router-dom'
import ProfileSidebar from '../../ProfileSidebar/ProfileSidebar'
import * as constants from '../../../Constants/Constants'
import { useForm } from 'react-hook-form';
import ReactPaginate from 'react-paginate';
import './DailyResult.css';

declare var $: any;

export default function DailyResult() {

  const { register, handleSubmit, setValue, formState: { errors }, reset } = useForm();
  const [formSubmitLoader, setFormSubmitLoader] = useState<boolean>(false);
  const [lotteryClubList, setLotteryClubList] = useState<any[]>();
  const [dailyResultList, setDailyResultList] = useState<any[]>();
  const [dailyResult, setDailyResult] = useState<any>();
  const [currentDate, setCurrentDate] = useState<string>(new Date().toISOString().split("T")[0]);
  const [selectedDate, setSelectedDate] = useState('');
  const [selectedLotteryClub, setSelectedLotteryClub] = useState('');
  useEffect(() => {
    getLotteryClubList();
  }, []);

  useEffect(() => {
    getDailyResultList();
  }, [selectedDate, selectedLotteryClub]);

  const getLotteryClubList = () => {
    fetch(constants.BASE_URL + 'lottery-club/dropdown', {
      method: 'GET',
      headers: { 'Content-Type': 'application/json', 'Accept': 'application/json', 'Authorization': constants.getAuthHeader() },
    }).then(function (response) {
      return response.json();
    }).then(function (response: any) {
      if (response.status == '200') {
        setLotteryClubList(response.result);
      }
    });
  }

  const getDailyResultList = () => {
    let queryParam = '';
    if (selectedDate) {
      queryParam += '?date=' + selectedDate;
    }
    if (currentDate && !selectedDate) {
      queryParam += '?date=' + currentDate;
    }
    if (selectedLotteryClub) {
      queryParam += queryParam ? '&' : '?';
      queryParam += 'lotteryId=' + selectedLotteryClub;
    }
  
    fetch(constants.BASE_URL + 'daily-result' + queryParam, {
      method: 'GET',
      headers: { 'Content-Type': 'application/json', 'Accept': 'application/json', 'Authorization': constants.getAuthHeader() },
    }).then(function (response) {
      return response.json();
    }).then(function (response: any) {
      if (response.status == '200') {
        setDailyResultList(response.result);
      }
    });
  }

  const prepareAddDailyResult = () => {
    $('#daily-result-modal').modal('show');
  }

  const addDailyResult = (data: any) => {
    setFormSubmitLoader(true);
    if(dailyResult && dailyResult.resultId && dailyResult.resultId.length > 0){
      data['lotteryClub'] = { lotteryId: data['lotteryId'] };
      fetch(constants.BASE_URL + 'daily-result/' + dailyResult.resultId, {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json', 'Accept': 'application/json', 'Authorization': constants.getAuthHeader() },
        body: JSON.stringify(data)
      }).then(function (response) {
        return response.json();
      }).then(function (response: any) {
        setFormSubmitLoader(false);
        if (response.status == '200') {
          reset();
          $('#daily-result-modal').modal('hide');
          constants.showSuccess('Request processed successfully');
          getDailyResultList();
        } else {
          constants.showError(response.message);
        }
      });
    }else{
      data['description'] = data['description'].replaceAll('\n', '<br>');
      data['lotteryClub'] = { lotteryId: data['lotteryId'] };
      fetch(constants.BASE_URL + 'daily-result/' + data['lotteryId'], {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', 'Accept': 'application/json', 'Authorization': constants.getAuthHeader() },
        body: JSON.stringify(data)
      }).then(function (response) {
        return response.json();
      }).then(function (response: any) {
        setFormSubmitLoader(false);
        if (response.status == '201') {
          reset();
          $('#daily-result-modal').modal('hide');
          constants.showSuccess('Request processed successfully');
          getDailyResultList();
        } else {
          constants.showError(response.message);
        }
      });
    }
 
  }

  const prepareEditDailyResult = (resultId: string) => {
    let dr = dailyResultList?.find((data: any) => data.resultId == resultId);
    setDailyResult(dr);
    $('#daily-result-modal').modal('show');
    setTimeout(() => {
      setValue('resultId', dr.resultId);
      setValue('date', dr.date, { shouldDirty: true });
      setValue('lotteryId', dr.lotteryClub.lotteryId , { shouldDirty: true });
      setValue('result', dr.result, { shouldDirty: true });
      setValue('description', dr.description, { shouldDirty: true });
    }, 500);
  }
  // function to change date format
  function formatDate(input: any) {
    let datePart = input.match(/\d+/g),
      year = datePart[0],
      // get only two digits 
      month = datePart[1],
      day = datePart[2];
    return day + '-' + month + '-' + year;
  }

  const deleteDailyResult = (resultId: string) => {
    if (window.confirm('Are you sure, you want to delete this record?')) {
      setFormSubmitLoader(true);
      fetch(constants.BASE_URL + 'daily-result/' + resultId, {
        method: 'DELETE',
        headers: { 'Content-Type': 'application/json', 'Accept': 'application/json', 'Authorization': constants.getAuthHeader() },
      }).then(function (response) {
        return response.json();
      }).then(function (response: any) {
        setFormSubmitLoader(false);
        if (response.status == '200') {
          getDailyResultList();
          constants.showSuccess(response.message);
        } else {
          constants.showError(response.message);
        }
      });
    }
  }
  return (
    <div className='page-content'>
      <section className="page-header page-header-dark bg-secondary">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-8">
              <h2 className='admin-heading mb-0 text-white'>ADMIN PANEL</h2>
            </div>
            <div className="col-md-4">

            </div>
          </div>
        </div>
      </section>
      <div className="container-fluid p-5 mb-2">
        <div className="row">
          <div className="col-lg-2">
            <ProfileSidebar />
          </div>
          <div className="col-lg-10">
            <div className="bg-white shadow-md rounded p-4">
              <div className='row'>
                <div className='col'>
                  <h4 className="mb-2 text-left">Daily Result</h4>
                </div>
                {/* <div className='col-lg-4'>
                  <input type="text" placeholder='Search by service name' className='form-control form-control-sm w-50 float-end'
                    onChange={searchByName} />
                </div> */}


                <div className='col-2'>
                  {<input type="date" className='form-control'  value={selectedDate ? selectedDate : currentDate} onChange={(e) => setSelectedDate(e.target.value)} />}
                </div>
                <div className='col-3'>{
                  <select className="form-control" onChange={(e) => setSelectedLotteryClub(e.target.value)} defaultValue={""}>
                    <option disabled value="">--Select Lottery Club--</option>
                    {lotteryClubList?.map((lc, index) => (
                      <option key={index} value={lc.lotteryId}>{lc.name}</option>
                    ))}
                  </select>
                }</div>
                <div className='col-2'>
                  <button type='button' className="btn btn-info btn-sm float-right" onClick={prepareAddDailyResult}>Add New Result</button>
                </div>
              </div>
              <div className="tab-content my-3" id="myTabContent">
                <div className="tab-pane fade show active" id="first" role="tabpanel" aria-labelledby="first-tab">
                  <div className="table-responsive-md">
                    <table className="table table table-striped table-hover text-dark">
                      <thead >
                        <tr>
                          <th>Sr No</th>
                          <th>Lottery Club</th>
                          <th>Date</th>
                          <th>Result</th>
                          <th>Description</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {dailyResultList?.map((data: any, index: number) => (
                          <tr key={index}>
                            <td className="align-middle">{index + 1}</td>
                            <td className="align-middle">{data.lotteryClub?.name}</td>
                            <td className="align-middle">{formatDate(data.date)}</td>
                            <td className="align-middle">{data.result}</td>
                            <td className="align-middle" dangerouslySetInnerHTML={{ __html: data?.description }}></td>
                            <td className="align-middle">
                              <button className="featured-box-icon bg-primary text-white rounded-circle border-0 edit-icon"
                                onClick={() => prepareEditDailyResult(data.resultId)}>
                                <i className="fas fa-edit"></i>
                              </button>
                              <button className="featured-box-icon bg-danger text-white rounded-circle border-0 edit-icon"
                                onClick={() => deleteDailyResult(data.resultId)} disabled={formSubmitLoader}>
                                {!formSubmitLoader && <i className="fas fa-trash"></i>}
                                {formSubmitLoader && <i className="fas fa-circle-notch fa-spin me-2"></i>}
                              </button>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="daily-result-modal" className="modal fade" role="dialog" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered modal-shadow" role="document">
          <div className="modal-content border-0">
            <div className="modal-header">
              <h5 className="modal-title">Daily Results</h5>
              <button type="button" className="btn-close" data-dismiss="modal" aria-label="Close"></button>
            </div>
            <form id="loginForm" method="post" onSubmit={handleSubmit(addDailyResult)}>
              <div className="modal-body py-4 px-0">
                <div className="row">
                  <div className="col-11 col-md-10 mx-auto">
                    {/* <input type="hidden" id="serviceId" {...register("serviceId", { required: true })}/> */}
                    <div className="mb-3">
                      <label className="number" htmlFor="name">Lottery Club<span className='star'>*</span></label>
                      <select className="form-control" id="name" defaultValue={""} {...register("lotteryId", { required: true })}>
                        <option disabled value="">--Select Lottery Club--</option>
                        {lotteryClubList?.map((lc, index) => (
                          <option key={index} value={lc.lotteryId}>{lc.name}</option>
                        ))}
                      </select>
                      {errors.lotteryId && <span className='text-danger'>Name is required </span>}
                    </div>
                    <div className="mb-3">
                      <label className="number" htmlFor="date">Date<span className='star'>*</span></label>
                      <input type="date" className="form-control" id="date" placeholder="Date"
                        {...register("date", { required: true })} />
                      {errors.date && <span className='text-danger'>Date is required </span>}
                    </div>
                    <div className="mb-3">
                      <label className="number" htmlFor="result">Result<span className='star'>*</span></label>
                      <input type="text" className="form-control" id="result" placeholder="Result"
                        {...register("result", { required: true })} />
                      {errors.result && <span className='text-danger'>Result is required </span>}
                    </div>
                    <div className="mb-3">
                      <label className="number" htmlFor="description">Description</label>
                      <textarea className="form-control" id="description" placeholder="Description"
                        {...register("description", { required: false })}>
                      </textarea>
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <button type="button" className="btn btn-danger btn-sm" data-dismiss="modal">Close</button>
                <button type="submit" className="btn btn-primary btn-sm" disabled={formSubmitLoader}>
                  {formSubmitLoader && <i className="fas fa-circle-notch fa-spin me-2"></i>}
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  )
}
