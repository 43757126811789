import React, { useState, useEffect } from 'react'
import "./FreeMatkaSattaGame.css"
import * as constants from '../../Constants/Constants'

export default function FreeMatkaSattaGame() {
    const [tfgCurrentWeekList, settfgCurrentWeekList] = useState<[]>();

    useEffect(() => {
        getSfgListCurrentWeekKR();
    },
        [])
    const getSfgListCurrentWeekKR = () => {
        fetch(constants.BASE_URL + 'today-free-game/public/current-week', {
            method: 'GET',
            headers: { 'Content-Type': 'application/json', 'Accept': 'application/json', 'Authorization': constants.getAuthHeader() },
        }).then(function (response) {
            return response.json();
        }).then(function (response: any) {
            if (response.status == '200') {
                settfgCurrentWeekList(response.result);
            } else {
                constants.showError(response.message);
            }

        });
    }

    function formatDate(input: any) {
        if (input != undefined) {
            let datePart = input.match(/\d+/g),
                year = datePart[0],
                // get only two digits 
                month = datePart[1],
                day = datePart[2];
            return day + '/' + month + '/' + year;
        }
    }

    return (
        <div>
            <div className='lotheadingwhite'>
                <h3>
                    Free Satta Matka Game
                </h3>
                <p>Free Matka Game - Get Today Free Satta Matka Game, Free Satta Matka Game Chart, Free Satta Matka Tips, Daily Matka Game, Samaj Seva Matka Tips, Samaj Seva Matka Game, Free Satta Game, Kalyan Matka Free Game, Kalyan Free Seva Game, Free Matka Game Zone, Matka Bazar Free Game, and Satta Free Ank Samaj Seva Update By @Sattamatkagods.</p>
                <h4>Kalyan Matka Free Game</h4>
                <p>If You Want to Get Daily Satta Matka Free Game Then Regular Come and Check Out Our Free Satta Matka Game. Today Keep Double Benifits From Free Matka Game Zone In All Matka Bazars i.e. Kalyan, Milan, Rajdhani, Time bazar, Sridevi, Supreme, Main Mumbai, Rose Bazar, and Madhuri</p>
                <p className='text-success bg-dark'>Daily Satta Matka Free Game Daily Update Sattamatkatopers.net</p>
            </div>
            {/* current week free game kalyan and rajdhani */}
            {tfgCurrentWeekList?.map((tfgCurrentWeekListKR: any, index) => (
                <div key={index} className='subsection1'>
                    <p>SattaMatkaGods : Daily  Kalyan Matka And Rajdhani Matka game , Daily matka game 2 Open to Close 4 Jodi Sangam Panna Free Me Matka Number Tips Updated</p>
                    <h4 className='text-dark'>Date: {formatDate(tfgCurrentWeekListKR?.weekStartDate)} to {formatDate(tfgCurrentWeekListKR?.weekEndDate)}  Lottery Club: {(tfgCurrentWeekListKR.lotteryClub as any)?.name}</h4>
                    <p>3 Ank Open to Close Play With Panel. We give a Opportunity to Public Free Game Play and Earn Money.</p>
                    <div>

                        <table className="table table-bordered table-danger table-striped">
                            <thead>
                                <tr>
                                    <th scope="col"><h3>Day</h3></th>
                                    {/* <th scope="col"><h3>{(tfgCurrentWeekListKR.lotteryClub as any)?.name} </h3></th> */}
                                    <th scope="col"><h3>{tfgCurrentWeekListKR.lotteryClubOne.name}</h3></th>
                                    <th scope="col"><h3>{tfgCurrentWeekListKR.lotteryClubTwo.name} </h3></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <th scope="row">Manday</th>
                                    <td dangerouslySetInnerHTML={{ __html: tfgCurrentWeekListKR?.mondayOne }} />

                                    <td dangerouslySetInnerHTML={{ __html: tfgCurrentWeekListKR?.mondayOne }} />

                                </tr>
                                <tr>
                                    <th scope="row">Passed</th>
                                    <td>{tfgCurrentWeekListKR?.mondayOnePassed}</td>
                                    <td>{tfgCurrentWeekListKR?.mondayTwoPassed}</td>
                                </tr>
                                <tr>
                                    <th scope="row">Tuesaday</th>
                                    <td dangerouslySetInnerHTML={{ __html: tfgCurrentWeekListKR?.tuesdayOne }} />

                                    <td dangerouslySetInnerHTML={{ __html: tfgCurrentWeekListKR?.tuesdayTwo }} />
                                </tr>
                                <tr>
                                    <th scope="row">Passed</th>
                                    <td>{tfgCurrentWeekListKR?.tuesdayOnePassed}</td>
                                    <td>{tfgCurrentWeekListKR?.tuesdayTwoPassed}</td>
                                </tr>
                                <tr>
                                    <th scope="row">Wednesday</th>
                                    <td dangerouslySetInnerHTML={{ __html: tfgCurrentWeekListKR?.wednesdayTwo }} />
                                    <td dangerouslySetInnerHTML={{ __html: tfgCurrentWeekListKR?.wednesdayTwo }} />

                                </tr>
                                <tr>
                                    <th scope="row">Passed</th>
                                    <td>{tfgCurrentWeekListKR?.wednesdayOnePassed}</td>
                                    <td>{tfgCurrentWeekListKR?.wednesdayTwoPassed}</td>
                                </tr>
                                <tr>
                                    <th scope="row">Thursday</th>
                                    <td dangerouslySetInnerHTML={{ __html: tfgCurrentWeekListKR?.thursdayOne }} />

                                    <td dangerouslySetInnerHTML={{ __html: tfgCurrentWeekListKR?.thursdayTwo }} />

                                </tr>
                                <tr>
                                    <th scope="row">Passed</th>
                                    <td>{tfgCurrentWeekListKR?.thursdayOnePassed}</td>
                                    <td>{tfgCurrentWeekListKR?.thursdayTwoPassed}</td>
                                </tr>
                                <tr>
                                    <th scope="row">Friday</th>
                                    <td dangerouslySetInnerHTML={{ __html: tfgCurrentWeekListKR?.fridayOne }}>
                                    </td>
                                    <td dangerouslySetInnerHTML={{ __html: tfgCurrentWeekListKR?.fridayTwo }}>
                                    </td>
                                </tr>
                                <tr>
                                    <th scope="row">Passed</th>
                                    <td>{tfgCurrentWeekListKR?.fridayOnePassed}</td>
                                    <td>{tfgCurrentWeekListKR?.fridayTwoPassed}</td>
                                </tr>
                                <tr>
                                    <th scope="row">Saturday</th>
                                    <td dangerouslySetInnerHTML={{ __html: tfgCurrentWeekListKR?.saturdayOne }}>
                                    </td>
                                    <td dangerouslySetInnerHTML={{ __html: tfgCurrentWeekListKR?.saturdayTwo }}>
                                    </td>
                                </tr>
                                <tr>
                                    <th scope="row">Passed</th>
                                    <td>{tfgCurrentWeekListKR?.saturdayOnePassed}</td>
                                    <td>{tfgCurrentWeekListKR?.saturdayTwoPassed}</td>
                                </tr>
                                <tr>
                                    <th scope="row">Sunday</th>
                                    <td dangerouslySetInnerHTML={{ __html: tfgCurrentWeekListKR?.sundayOne }}>
                                    </td>
                                    <td dangerouslySetInnerHTML={{ __html: tfgCurrentWeekListKR?.sundayOne }}>
                                    </td>
                                </tr>
                                <tr>
                                    <th scope="row">Passed</th>
                                    <td>{tfgCurrentWeekListKR?.sundayOnePassed}</td>
                                    <td>{tfgCurrentWeekListKR?.sundayTwoPassed}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            ))}
            <div className="col-lg-12 col-md-12 smallstripwhite my-1">
                <a href='/matkaguessing'>Satta Matka Guessing trick tips and Fast Matka Result Zone</a>
            </div>
            {/* <div className="col-lg-12 col-md-12 smallstripwhite my-1">
                 <a  href='/sattakingjodi'>Free Sattaking Result and Live Satta Result</a>
            </div> */}
            {/* <div className="col-lg-12 col-md-12 smallstripwhite my-1">
                <a  href='/weeklysattamatka'>Free  Satta Matka Jodi Free Chart  Free</a>
            </div> */}
            {/* <div className="col-lg-12 col-md-12 lotheadingwhite my-1">
                <h4 className="titlered">Dec 6 2022</h4>
                <h5 className="greencolor">Satta matka free game today publishes by Kalyan Matka king Sattamatkatoppers on public support. In this accurate prediction section gets - open, close, Jodi, panel, and Sangam. From 2021, our official team starts a free matka game for public. Free game are Sridevi, Madhuri, Milan morning, time bazar, Milan day, Rajdhani day, supreme day, Kalyan, Milan night, Rajdhani night, kalyan night, main bazar, old main Mumbai, and rose bazar night. For public information time: 10:00 am to 7:30 pm day games and time: 8:00 pm to 12:30 am night games updated by Sattamatkatoppers at www.sattamatkatoppers.com</h5>
            </div> */}
            {/* <div className="col-lg-12 col-md-12 lotheadinglight my-1">
                <h4 className="titlered">SHRI DEVI</h4>
                <h5 className="redcolor">OPen-1234</h5>
                <div className="greencolor">Panna -290 260 259 245
                </div>
                <div className="colorwhite">Jodi -14 41 69 96 64 46 91 19
                </div>
                <div className="yellowgreen">Sangam  -270 x 96 x 290
                </div>
            </div> */}
            {/* <div className="col-lg-12 col-md-12 lotheadinglight my-1">
                <h4 className="titlered">SHRI DEVI</h4>
                <h5 className="redcolor">OPen-1234</h5>
                <div className="greencolor">Panna -290 260 259 245
                </div>
                <div className="colorwhite">Jodi -14 41 69 96 64 46 91 19
                </div>
                <div className="yellowgreen">Sangam  -270 x 96 x 290
                </div>
            </div> */}
            {/* <div className="col-lg-12 col-md-12 lotheadingwhite my-1">
                <h4 className="titlered">Dec 6 2022</h4>
                <h5 className="greencolor">Satta king - fix satta haruf and fix satta king Jodi publish by satta king Sattamatkatoppers. In this section you get fix three haruf, fix satta Jodi and today satta king single fix Jodi. Faridabad satta king number, today Ghaziabad satta Jodi, Gali satta king Jodi, and Disawar satta tips for a public update every day 3:00 pm. Get quickly free satta number, today satta tips, fix satta game, satta fix Jodi, and satta kalyan at www.sattamatkatoppers.com</h5>
              </div>     */}
            {/* <div className="col-lg-12 col-md-12 lotheadingwhite my-1">
                <h4 className="titlered"><span className="spanviolet">Faridabad Satta</span></h4>
                <h5 className="redcolor">Haruf-270</h5>
                <div className="greencolor">Jodi -27 72 01 10 64 46 91 19
                </div>
                <div className="colorwhite">Single Fix Jodi -45
                </div>
                <div className="yellowgreen">Faridabad satta king number
                </div>
              </div>  */}
            {/* <div className="col-lg-12 col-md-12 lotheadinginfo my-1">
                <h4 className="titlered"><span className="spanpink">Gaziyabad Satta</span></h4>
                <h5 className="redcolor">Haruf-270</h5>
                <div className="greencolor">Jodi -27 72 01 10 64 46 91 19
                </div>
                <div className="colorwhite">Single Fix Jodi -45
                </div>
                <div className="yellowgreen">Faridabad satta king number
                </div>
              </div> */}
            <div className="col-lg-12 col-md-12 lotheadinginfo my-1">
                <h4 className='text-danger'>Become rich by Playing Free Matka Game</h4>
                <h5 className='text-danger'>Do you want to get fast & correct free satta matka game result?</h5>
                <p className='text-success'>So we feature this success in publishing the correct results of every satta matka game at the earliest. We offer quick results as compared to other satta matta sites. We also offer wide range of satta matka including: free satta matka game, free kalyan matka, free matka game, free matka open close, free matka number.</p>
                <h5 className='text-danger'>Why satta matka website is more trustworthy than an agent?</h5>
                <p className='text-success'>Satta matka is very unlike other lottery games. The method of playing satta matka is very simple. People having minor knowledge of basic math of adding can play satta matka easily. No such qualification is required to play. But playing this betting game through an agent, then he may use the player to earn money by himself. In India, most people's have the fear that they may lose money if they play online. But it all depends on which website you are choosing to play the satta game. You should always go for a trusted website; we at sattamatkagods.net can help you develop the best online games out there. We guarantee to get money back and huge earning with us.</p>
                <h5 className='text-danger'>What tricks you need to follow while playing satta</h5>
                <p className='text-success'>You can win easily when you play satta matka with the right method. People usually lose the game and money because of overconfidence. This gambling game is just a numbers game and we all should play with tricks. Moreover, luck plays an important role, so what are you waiting for try your luck by following some tips and tricks of the game. By following specific strategies, you can win and can earn more money without any loss.</p>
                <p className='text-success'>We assure to offer non-stop entertaining and full-proof security & safety to all of our players. Our expert adding a cherry on top we have an online satta guessing forum for all users. We have over 1000 varieties of satta matka games.</p>
                <h5 className='text-danger'>SATTA FREE SAMAJ SEVA GAME</h5>
                <p className='text-success'>Today sattamatka industry is too big in india and saudi arbia. Many people play online free matka game and satta free ank samaj seva game. From last 6th year our satta matka website gives you satta matķa, 100% date fix free ank, kalyan fix Jodi free, matka tips today, fix fix 3 ank matka open to close, 100% never fail date fix matka Jodi panel, satta fix Jodi, matka lifetime trick and tips, open to close chart, kalyan matka fix 2 ank, fix fix fix satta number, satta batta and kalyan matka king WorldSattamatka4u update matka game in this matka website. Your grow is our attention. Money income is simple through this website because matka website wonor directly update 4 ank open and close in all satta matka market. In last year our matka website provides you kalyan matka single Jodi and panel and print, many online matka visitor play and win.</p>
                <h5 className='text-danger'>TODAY FREE MATKA GAME</h5>
                <div><ul className='text-success text-left'><li>Faster your Study of free matka game.</li><li>Learning the tricks of the game and plain how to play matk game?</li><li>How much you play calculate then play. Fast you have think if you are loss then how to cover.</li><li>You Must Follow free satta matka game from this page, If you not follow you are going to loss. You must follow today kalyan matka fix date fix game and earn money.</li><li>If You are In Loss Of Money then Our Matka Guessing Forum Check Out Once, Many experts Daily Matka game post and many Player Follow and Win Satta Matka game.</li></ul>
                </div>
                <h3 className='text-danger'>Satta Matka King </h3>
                <h4 className='text-success'>WorldSattamatka4u</h4>

            </div>

        </div>

    )
}
