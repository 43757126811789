import React, { useEffect, useState } from 'react'
import { NavLink } from 'react-router-dom'
import ProfileSidebar from '../../ProfileSidebar/ProfileSidebar'
import * as constants from '../../../Constants/Constants'
import { useForm } from 'react-hook-form';
import ReactPaginate from 'react-paginate';
import './LotteryClub.css';
import { CompactPicker, SketchPicker } from 'react-color';

declare var $: any;

export default function LotteryClub() {

  const { register, handleSubmit, setValue, formState: { errors }, reset } = useForm();
  const [formSubmitLoader, setFormSubmitLoader] = useState<boolean>(false);
  const [lotteryClubList, setLotteryClubList] = useState<any[]>();
  const [matkaGuessingList, setMatkaGuessingList] = useState<any[]>();
  const [lotteryClub, setLotteryClub] = useState<any>();
  const [pageCount, setPageCount] = useState(0);
  const [pageNum, setPageNum] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(20);
  const [searchBy, setSearchBy] = useState('');
  const [color, setColor] = useState('#fff');
  const [fontColor, setFontColor] = useState('#fff');

  useEffect(() => {
    getLotteryClubList();
  }, [pageNum, searchBy]);

  const getLotteryClubList = () => {
    let queryParam = '?page=' + (pageNum - 1) + '&size=' + itemsPerPage;
    if (searchBy) {
      queryParam += '&searchBy=' + searchBy;
    }
    fetch(constants.BASE_URL + 'lottery-club' + queryParam, {
      method: 'GET',
      headers: { 'Content-Type': 'application/json', 'Accept': 'application/json', 'Authorization': constants.getAuthHeader() },
    }).then(function (response) {
      return response.json();
    }).then(function (response: any) {
      if (response.status == '200') {
        setLotteryClubList(response.result);
        setPageCount(Math.ceil(response.listCount / itemsPerPage));
      }
    });
  }

  const handleColorChange = (color: { hex: React.SetStateAction<string>; }) => {
    setColor(color.hex);
    setValue('color', color.hex);
  };
  const handleFontColorChange = (color: { hex: React.SetStateAction<string>; }) => {
    setFontColor(color.hex);
    setValue('fontColor', color.hex);
  };


  const prepareAddLotteryClub = () => {
    reset();
    $('#lottery-club-modal').modal('show');
    setLotteryClub(null);
  }
  const handlePageClick = (event: any) => {
    setPageNum(event.selected + 1);
  };

  const handleSearchInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchBy(e.target.value);
  };

  const addLotteryClub = (data: any) => {
    setFormSubmitLoader(true);
    if (lotteryClub && lotteryClub.lotteryId && lotteryClub.lotteryId.length > 0) {
      fetch(constants.BASE_URL + 'lottery-club/' + lotteryClub.lotteryId, {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json', 'Accept': 'application/json', 'Authorization': constants.getAuthHeader() },
        body: JSON.stringify(data)
      }).then(function (response) {
        return response.json();
      }).then(function (response: any) {
        setFormSubmitLoader(false);
        if (response.status == '200') {
          reset();
          $('#lottery-club-modal').modal('hide');
          constants.showSuccess('Request processed successfully');
          getLotteryClubList();
        } else {
          constants.showError(response.message);
        }
      });
    } else {
      fetch(constants.BASE_URL + 'lottery-club', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', 'Accept': 'application/json', 'Authorization': constants.getAuthHeader() },
        body: JSON.stringify(data)
      }).then(function (response) {
        return response.json();
      }).then(function (response: any) {
        setFormSubmitLoader(false);
        if (response.status == '201') {
          reset();
          $('#lottery-club-modal').modal('hide');
          constants.showSuccess('Request processed successfully');
          getLotteryClubList();
        } else {
          constants.showError(response.message);
        }
      });
    }
  }

  const prepareEditLotteryClub = (lotteryId: string) => {
    let lc = lotteryClubList?.find((data: any) => data.lotteryId == lotteryId);
    setLotteryClub(lc);
    $('#lottery-club-modal').modal('show');
    setTimeout(() => {
      setValue('lotteryId', lc.lotteryId);
      setValue('name', lc.name, { shouldDirty: true });
      setValue('openTime', lc.openTime, { shouldDirty: true });
      setValue('closeTime', lc.closeTime, { shouldDirty: true });
      setValue('showOnHome', lc.showOnHome, { shouldDirty: false });
      setValue('showOnGuessForm', lc.showOnGuessForm, { shouldDirty: false });
      setValue('sequence', lc.sequence, { shouldDirty: false });
      setValue('noOfDays', lc.noOfDays, { shouldDirty: false });
      setValue('color', lc.color, { shouldDirty: false });
      setValue('fontColor', lc.fontColor, { shouldDirty: false });
    }, 500);
  }

  const deleteLotteryClub = (lotteryId: string) => {
    if (window.confirm('Are you sure, you want to delete this record?')) {
      setFormSubmitLoader(true);
      fetch(constants.BASE_URL + 'lottery-club/delete/' + lotteryId, {
        method: 'DELETE',
        headers: { 'Content-Type': 'application/json', 'Accept': 'application/json', 'Authorization': constants.getAuthHeader() },
      }).then(function (response) {
        return response.json();
      }).then(function (response: any) {
        setFormSubmitLoader(false);
        if (response.status == '200') {
          getLotteryClubList();
          constants.showSuccess(response.message);
        } else {
          constants.showError(response.message);
        }
      });
    }
  }

  return (
    <div className='page-content'>
      <section className="page-header page-header-dark bg-secondary">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-8">
              <h2 className='admin-heading mb-0 text-white'>ADMIN PANEL</h2>
            </div>
            <div className="col-md-4">

            </div>
          </div>
        </div>
      </section>
      <div className="container-fluid p-5 mb-2">
        <div className="row">
          <div className="col-lg-2">
            <ProfileSidebar />
          </div>
          <div className="col-lg-10">
            <div className="bg-white shadow-md rounded p-4">
              <div className='row'>
                <div className='col'>
                  <h4 className="mb-2 text-left">Lottery Club</h4>
                </div>
                <div className='col-4'>
                  <input
                    type="text"
                    placeholder='Search by lottery club name'
                    className='form-control form-control-sm w-50 float-end'
                    value={searchBy}
                    onChange={handleSearchInputChange}
                  />
                </div>
                <div className='col-2'>
                  <button type='button' className="btn btn-info btn-sm float-right" onClick={prepareAddLotteryClub}>Add New Club</button>
                </div>
              </div>
              <div className="tab-content my-3" id="myTabContent">
                <div className="tab-pane fade show active" id="first" role="tabpanel" aria-labelledby="first-tab">
                  <div className="table-responsive-md">
                    <table className="table table table-striped table-hover text-dark">
                      <thead >
                        <tr>
                          <th>Sr No</th>
                          <th>Lottery Club Name</th>
                          <th>Open time</th>
                          <th>Close time</th>
                          <th>Show on Home?</th>
                          <th>Show on Guess Form?</th>
                          <th>Sequence</th>
                          <th>No. of Days</th>
                          <th>Bg Color</th>
                          <th>Font Color</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {lotteryClubList?.map((data: any, index: number) => (
                          <tr key={index}>
                            <td className="align-middle">{(pageNum - 1) * itemsPerPage + index + 1}</td>
                            <td className="align-middle">{data.name}</td>
                            <td className="align-middle">{data.openTime}</td>
                            <td className="align-middle">{data.closeTime}</td>
                            <td className="align-middle">{data.showOnHome ? 'Yes' : 'No'}</td>
                            <td className="align-middle">{data.showOnGuessForm ? 'Yes' : 'No'}</td>
                            <td className="align-middle">{data.sequence}</td>
                            <td className="align-middle">{data.noOfDays}</td>
                            <td className="align-middle" style={{ backgroundColor: data.color }}>
                            </td>
                            <td className="align-middle" style={{ backgroundColor: data.fontColor }}></td>
                            <td className="align-middle">
                              <button className="featured-box-icon bg-primary text-white rounded-circle border-0 edit-icon"
                                onClick={() => prepareEditLotteryClub(data.lotteryId)}>
                                <i className="fas fa-edit"></i>
                              </button>
                              <button className="featured-box-icon bg-danger text-white rounded-circle border-0 edit-icon"
                                onClick={() => deleteLotteryClub(data.lotteryId)} disabled={formSubmitLoader}>
                                {!formSubmitLoader && <i className="fas fa-trash"></i>}
                                {formSubmitLoader && <i className="fas fa-circle-notch fa-spin me-2"></i>}
                              </button>
                            </td>
                          </tr>
                        ))}
                      </tbody>

                    </table>
                  </div>
                  <ReactPaginate breakLabel="..." nextLabel=">" onPageChange={handlePageClick} pageRangeDisplayed={5}
                    pageCount={pageCount} previousLabel="<" pageClassName="page-item" pageLinkClassName="page-link"
                    previousClassName="page-item" previousLinkClassName="page-link" nextClassName="page-item" nextLinkClassName="page-link"
                    breakClassName="page-item" breakLinkClassName="page-link" containerClassName="pagination" activeClassName="active"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="lottery-club-modal" className="modal fade" role="dialog" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered modal-shadow" role="document">
          <div className="modal-content border-0">
            <div className="modal-header">
              <h5 className="modal-title">Lottery Club</h5>
              <button type="button" className="btn-close" data-dismiss="modal" aria-label="Close"></button>
            </div>
            <form id="loginForm" method="post" onSubmit={handleSubmit(addLotteryClub)}>
              <div className="modal-body py-4 px-0">
                <div className="row">
                  <div className="col-11 col-md-10 mx-auto">
                    {/* <input type="hidden" id="serviceId" {...register("serviceId", { required: true })}/> */}
                    <div className="mb-3">
                      <label className="number" htmlFor="name">Lottery Club Name<span className='star'>*</span></label>
                      <input type="text" className="form-control" id="name" placeholder="Lottery Club Name"
                        {...register("name", { required: true })} />
                      {errors.name && <span className='text-danger'>Name is required </span>}
                    </div>
                    <div className="mb-3">
                      <label className="number" htmlFor="opentime">Open time<span className='star'>*</span></label>
                      <input type="text" className="form-control" id="openTime" placeholder="Open time"
                        {...register("openTime", { required: true })} />
                      {errors.openTime && <span className='text-danger'>Open time is required </span>}
                    </div>
                    <div className="mb-3">
                      <label className="number" htmlFor="closetime">Close time<span className='star'>*</span></label>
                      <input type="text" className="form-control" id="closeTime" placeholder="Close time"
                        {...register("closeTime", { required: true })} />
                      {errors.closeTime && <span className='text-danger'>Close time is required </span>}
                    </div>
                    <div className="mb-3">
                      <label className="number" htmlFor="closetime">Sequence</label>
                      <input type="number" className="form-control" id="sequence" placeholder="Sequence"
                        {...register("sequence", { required: false })} />
                    </div>
                    <div className="mb-3">
                      <label className="number" htmlFor="closetime">No. of Days<span className='star'>*</span></label>
                      <input type="number" className="form-control" id="noOfDays" placeholder="No. Of Days"
                        {...register("noOfDays", { required: true })} />
                      {errors.noOfDays && <span className='text-danger'>No of days is required </span>}
                    </div>
                    <div className="mb-3">
                      <label className="number" htmlFor="color">Select Bg Color</label>
                      <input type="color" className="form-control" id="color"
                        {...register("color", { required: true })} />
                    </div>

                    <div className="mb-3">
                      <label className="number" htmlFor="color">Select Font Color</label>
                      <input type="color" className="form-control" id="fontColor"
                        {...register("fontColor", { required: true })} />
                    </div>
                    <div className="mb-3">
                      <label className="number" htmlFor="closetime">Show on Home Page</label>
                      <input type="checkbox" className='ml-3' id="showOnHome"
                        {...register("showOnHome", { required: false })} />
                      <span className="p-4"></span>
                      <label className="number" htmlFor="closetime">Show on Guess Form</label>
                      <input type="checkbox" className='ml-3' id="showOnGuessForm"
                        {...register("showOnGuessForm", { required: false })} />
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <button type="button" className="btn btn-danger btn-sm" data-dismiss="modal">Close</button>
                <button type="submit" className="btn btn-primary btn-sm" disabled={formSubmitLoader}>
                  {formSubmitLoader && <i className="fas fa-circle-notch fa-spin me-2"></i>}
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  )
}
