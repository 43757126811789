import React, { useEffect, useState } from 'react'
import { NavLink, useNavigate } from 'react-router-dom'
import './ProfileSidebar.css'
import * as constants from '../../Constants/Constants'


export default function ProfileSidebar() {

  let navigate = useNavigate();
  const [currentUser, setCurrentUser] = useState<any>();

  useEffect(() => {
    if (sessionStorage.getItem('currentUser')) {
      let currentUser = JSON.parse(sessionStorage.getItem('currentUser')!);
      if (currentUser.role.name != 'ADMIN' && currentUser.role.name != 'OPERATOR') {
        navigate('/');
        constants.showSuccess('You do not have permission to access this page')
        // alert('You do not have permission to access this page');  
      } else {
        setCurrentUser(currentUser);
      }
    } else {
      navigate('/');
      constants.showError('You do not have permission to access this page')
      // alert('You do not have permission to access this page');
    }
  }, []);

  const logout = () => {
    sessionStorage.clear();
    navigate('/');
  }

  return (
    <>
      <ul className="nav nav-pills alternate flex-lg-column mb-3 mb-lg-0 sticky-top" style={{ zIndex: 899, top: '80px' }}>
        <li className="nav-item text-left">
          <NavLink className="nav-link" to='/dashboard'>
            <span className="me-2"><i className="fas fa-tachometer-alt"></i></span>
            Dashboard
          </NavLink>
        </li>
        <li className="nav-item text-left">
          <NavLink className="nav-link" to="/lotteryclub">
            <span className="me-2"><i className="fas fa-cubes"></i></span>
            Lottery Club
          </NavLink>
        </li>
        <li className="nav-item text-left">
          <NavLink className="nav-link" to="/dailyresults">
            <span className="me-2"><i className="fas fa-poll"></i></span>
            Daily Results
          </NavLink>
        </li>
        <li className="nav-item text-left">
          <NavLink className="nav-link" to="/today-tips">
            <span className="me-2"><i className="fas fa-campground"></i></span>
            Today Tips
          </NavLink>
        </li>
        <li className="nav-item text-left">
          <NavLink className="nav-link" to="/final-matka-ank">
            <span className="me-2"><i className="fas fa-newspaper"></i></span>
            Final Matka Ank
          </NavLink>
        </li>
        <li className="nav-item text-left">
          <NavLink className="nav-link" to="/satta-free-game">
            <span className="me-2"><i className="fas fa-puzzle-piece"></i></span>
            Satta Free Game
          </NavLink>
        </li>
        <li className="nav-item text-left">
          <NavLink className="nav-link" to="/adminsattakingjodi">
            <span className="me-2"><i className="fas fa-window-restore"></i></span>
            Satta King Jodi
          </NavLink>
        </li>
        <li className="nav-item text-left">
          <NavLink className="nav-link" to="/advertisement">
            <span className="me-2"><i className="fa fa-server"></i></span>
            Advertisement
          </NavLink>
        </li>
        <li className="nav-item text-left">
          <NavLink className="nav-link" to="/todayfreegame">
            <span className="me-2"><i className="fas fa-sun"></i></span>
            Today Free Game
          </NavLink>
        </li>
        <li className="nav-item text-left">
          <NavLink className="nav-link" to="/weeklymatkaadmin">
            <span className="me-2"><i className="fas fa-calendar-week"></i></span>
            Weekly Jodi Patti
          </NavLink>
        </li>
        {/* <li className="nav-item text-left">
          <NavLink className="nav-link" to="/satta-fix-game">
            <span className="me-2"><i className="fas fa-puzzle-piece"></i></span>
            Satta Fix Game
          </NavLink>
        </li> */}

        <li className="nav-item text-left">
          <NavLink className="nav-link" to="/users">
            <span className="me-2"><i className="fas fa-user"></i></span>
            Users
          </NavLink>
        </li>
        <li className="nav-item text-left">
          <NavLink className="nav-link" to="/matka-guessing-list">
            <span className="me-2"><i className="far fa-lightbulb"></i></span>
            Matka Guessing List
          </NavLink>
        </li>

        <li className="nav-item text-left">
          <a className="nav-link" onClick={logout}>
            <span className="me-2"><i className="fas fa-sign-out-alt"></i></span>
            Logout
          </a>
        </li>
      </ul>
    </>
  )
}
