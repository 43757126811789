import React, { useEffect, useState } from 'react'
import ProfileSidebar from '../../ProfileSidebar/ProfileSidebar'
import * as constants from '../../../Constants/Constants'
import './MatkaGuessingList.css';
import ReactPaginate from 'react-paginate';

declare var $: any;

export default function MatksGuessingList(this: any) {

  const [formSubmitLoader, setFormSubmitLoader] = useState<boolean>(false);
  const [matkaGuessingList, setMatkaGuessingList] = useState<any[]>();
  const [currentDate, setCurrentDate] = useState<string>(new Date().toISOString().split("T")[0]);

  const [pageCount, setPageCount] = useState(0);
  const [pageNum, setPageNum] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(20);
  const [imageURL, setImageURL] = useState<string>('');

  const imgUrl = constants.BASE_URL + "file/image/";

  useEffect(() => {
    getMatkaGuessingList();
  }, [pageNum, currentDate]);

  const getMatkaGuessingList = () => {
    let queryParam = '?page=' + (pageNum - 1) + '&size=' + itemsPerPage;
    fetch(constants.BASE_URL + 'matka-guessing/public/' + currentDate + queryParam, {
      method: 'GET',
      headers: { 'Content-Type': 'application/json', 'Accept': 'application/json', 'Authorization': constants.getAuthHeader() },
    }).then(function (response) {
      return response.json();
    }).then(function (response: any) {
      if (response.status == '200') {
        setMatkaGuessingList(response.result);
        setPageCount(Math.ceil(response.listCount / itemsPerPage));
      }
    });
  }

  const viewImage = (photoURL: string) => {
    setImageURL(photoURL);
    $('#imageViewModal').modal('show');
  }

  function hideModal(): void {
    $('#imageViewModal').modal('hide');
  }


  const handlePageClick = (event: any) => {
    setPageNum(event.selected + 1);
  };

  const deleteMatkaGuessing = (matkaGuessingId: string) => {
    if (window.confirm('Are you sure, you want to delete this record?')) {
      setFormSubmitLoader(true);
      fetch(constants.BASE_URL + 'matka-guessing/' + matkaGuessingId, {
        method: 'DELETE',
        headers: { 'Content-Type': 'application/json', 'Accept': 'application/json', 'Authorization': constants.getAuthHeader() },
      }).then(function (response) {
        return response.json();
      }).then(function (response: any) {
        setFormSubmitLoader(false);
        if (response.status == '200') {
          getMatkaGuessingList();
          constants.showSuccess('Request processed successfully');
        } else {
          constants.showError(response.message);
        }
      });
    }
  }

  const deleteTodayMatkaGuessing = () => {
    if (window.confirm('Are you sure, you want to delete today records?')) {
      setFormSubmitLoader(true);
      fetch(constants.BASE_URL + 'matka-guessing/bulk-delete-today', {
        method: 'DELETE',
        headers: { 'Content-Type': 'application/json', 'Accept': 'application/json', 'Authorization': constants.getAuthHeader() },
      }).then(function (response) {
        return response.json();
      }).then(function (response: any) {
        setFormSubmitLoader(false);
        if (response.status == '200') {
          getMatkaGuessingList();
          constants.showSuccess(response.message);
        } else {
          constants.showError(response.message);
        }
      });
    }
  }

  const deleteThisPage = () => {
    if (window.confirm('Are you sure, you want to delete these records?')) {
      setFormSubmitLoader(true);
      let ids: string[] = [];
      matkaGuessingList?.forEach((element: any) => {
        ids.push(element.matkaGuessingId);
      });
      fetch(constants.BASE_URL + 'matka-guessing/bulk-delete?ids=' + ids, {
        method: 'DELETE',
        headers: { 'Content-Type': 'application/json', 'Accept': 'application/json', 'Authorization': constants.getAuthHeader() },
      }).then(function (response) {
        return response.json();
      }).then(function (response: any) {
        setFormSubmitLoader(false);
        if (response.status == '200') {
          setPageNum(1);
          getMatkaGuessingList();
          constants.showSuccess('Request processed successfully');
        } else {
          constants.showError(response.message);
        }
      });
    }
  }

  const onDateChange = (e: any) => {
    setCurrentDate(e.target.value);
  }

  return (
    <div className='page-content'>
      <section className="page-header page-header-dark bg-secondary">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-8">
              <h2 className='admin-heading mb-0 text-white'>ADMIN PANEL</h2>
            </div>
            <div className="col-md-4">

            </div>
          </div>
        </div>
      </section>
      <div className="container-fluid p-5 mb-2">
        <div className="row">
          <div className="col-lg-2">
            <ProfileSidebar />
          </div>
          <div className="col-lg-10">
            <div className="bg-white shadow-md rounded p-4">
              <div className='row'>
                <div className='col'>
                  <h4 className="mb-2 text-left">Matka Guessing List</h4>
                </div>
                {/* <div className='col-lg-4'>
                  <input type="text" placeholder='Search by service name' className='form-control form-control-sm w-50 float-end'
                    onChange={searchByName} />
                </div> */}
                <div className='col'>
                  <input type="date" className='form-control float-right w-50' value={currentDate} onChange={onDateChange}/>
                </div>
                <div className='col-2'>
                  <button type='button' className="btn btn-danger btn-sm float-right" onClick={deleteTodayMatkaGuessing}>Delete Today Records</button>
                </div>
                <div className='col-2'>
                  <button type='button' className="btn btn-danger btn-sm" onClick={deleteThisPage}>Delete This Page</button>
                </div>
              </div>
              <div className="tab-content my-3" id="myTabContent">
                <div className="tab-pane fade show active" id="first" role="tabpanel" aria-labelledby="first-tab">
                  <div className="table-responsive-md">
                    <table className="table table table-striped table-hover text-dark">
                      <thead >
                        <tr>
                          <th>Sr No</th>
                          <th className='text-center'>Text</th>
                          <th>User</th>
                          <th>Date</th>
                          <th>Photo</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {matkaGuessingList?.map((data: any, index: number) => (
                          <tr key={index}>
                            <td className="align-middle">{index + 1}</td>
                            <td className="align-middle text-center" dangerouslySetInnerHTML={{ __html: data?.text }}></td>
                            <td className="align-middle">{data.user?.name}</td>
                            <td className="align-middle">{data.createdDateStr}</td>
                            <td className="align-middle">
                              <button onClick={() => viewImage(imgUrl + data.photo + '/')} className="btn btn-icon btn-light btn-sm cursor-pointer ml-1 rounded bg-light" title="View Image">
                                <i className="fa fa-image"></i>
                              </button>
                            </td>
                            <td className="align-middle">
                              <button className="featured-box-icon bg-danger text-white rounded-circle border-0 edit-icon"
                                onClick={() => deleteMatkaGuessing(data.matkaGuessingId)} disabled={formSubmitLoader}>
                                {!formSubmitLoader && <i className="fas fa-trash"></i>}
                                {formSubmitLoader && <i className="fas fa-circle-notch fa-spin me-2"></i>}
                              </button>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                  <ReactPaginate breakLabel="..." nextLabel=">" onPageChange={handlePageClick} pageRangeDisplayed={5}
                    pageCount={pageCount} previousLabel="<" pageClassName="page-item" pageLinkClassName="page-link"
                    previousClassName="page-item" previousLinkClassName="page-link" nextClassName="page-item" nextLinkClassName="page-link"
                    breakClassName="page-item" breakLinkClassName="page-link" containerClassName="pagination" activeClassName="active"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="modal fade" role="dialog" id="imageViewModal">
        <div className="modal-dialog modal-xl" role="document">
          <div className="modal-content">
            <div className="modal-header py-3 px-4 border-bottom-0 d-block">
              <button type="button" onClick={() => hideModal()} className="btn-close float-end" data-bs-dismiss="modal" aria-label="Close"></button>
              <h5 className="modal-title" id="modal-title">Image</h5>
            </div>
            <div className="modal-body">
              <div className="row">
                <div className="col text-center">
                  <img src={imageURL} className="img-lg rounded imagepopup textimg2" alt="Preview" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>

  )
}
