import React, { ChangeEvent, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form';
import ReactPaginate from 'react-paginate';
import * as constants from '../../Constants/Constants'
import { useNavigate, NavLink } from 'react-router-dom';
import "./MatkaGuessing.css"

declare var $: any;

interface FileState {
  photo: File | undefined;
}

export default function MatkaGuessing() {

  let navigate = useNavigate();
  const [guessList, setGuessList] = useState<any[]>();
  const { register, handleSubmit, setValue, formState: { errors }, reset } = useForm();
  const { register: register2, handleSubmit: handleSubmit2, setValue: setValue2, formState: { errors: errors2 }, reset: reset2 } = useForm();
  const [formSubmitLoader, setFormSubmitLoader] = useState<boolean>(false);
  const [currentUser, setCurrentUser] = useState<any>();
  const [guess, setGuess] = useState<any>();
  const [dailyResult, setDailyResult] = useState<any[]>([]);
  const [firstIndexObject, setFirstIndexObject] = useState<any>({});
  const [lastIndexObject, setLastIndexObject] = useState<any>({});
  const [middleElements, setMiddleElements] = useState<any[][]>([]);
  const [currentDate, setCurrentDate] = useState<string>(new Date().toISOString().split("T")[0]);
  const [pageCount, setPageCount] = useState(0);
  const [pageNum, setPageNum] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(20);
  const [advertisement, setAdverisement] = useState<any>();

  const [file, setFile] = useState<FileState>({
    photo: undefined,
  });

  const imageUrl = constants.BASE_URL + "file/image/";

  const [guessPhotoUrl, setGuessPhotoUrl] = useState("");

  // top results
  const [kalyan, setKalyan] = useState<string>();
  const [milanDay, setMilanDay] = useState<string>();
  const [milanNight, setMilanNight] = useState<string>();
  const [rajdhaniDay, setRajdhaniDay] = useState<string>();
  const [rajdhaniNight, setRajdhaniNight] = useState<string>();
  const [timeBazar, setTimeBazar] = useState<string>();
  const [kalyanNight, setKalyanNight] = useState<string>();
  const [mainBazar, setMainBazar] = useState<string>();

  useEffect(() => {
    window.scrollTo(0, 0)
    if (sessionStorage.getItem('currentUser')) {
      setCurrentUser(JSON.parse(sessionStorage.getItem('currentUser')!));
    }
    getDailyResultList();
    getAdvertisement();
  }, []);

  useEffect(() => {
    getGuessList();
  }, [pageNum]);

  const handelOnChange = (e: ChangeEvent<HTMLInputElement>, name: keyof FileState) => {
    const selectedFile = e.target.files?.[0];
    if (selectedFile) {
      setFile(prevState => ({
        ...prevState,
        [name]: selectedFile
      }));
    }
  }

  // const getDailyResult = () => {
  //   let searchKey = "?searchKey=KALYAN,Milan Day,Milan Night,Rajdhani Day,Rajdhani Night,Time Bazar,Main Bazar,KALYAN NIGHT";
  //   fetch(`${constants.BASE_URL}daily-result/public/${currentDate}` + searchKey, {
  //     method: 'GET',
  //     headers: { 'Content-Type': 'application/json', 'Accept': 'application/json', 'Authorization': constants.getAuthHeader() },
  //   }).then(function (response) {
  //     return response.json();
  //   }).then(function (response: any) {
  //     if (response.status == '200') {
  //       response.result.forEach((element: any) => {
  //         if (element.name == 'KALYAN') {
  //           setKalyan(element.result);
  //         } else if (element.name == 'MILAN DAY') {
  //           setMilanDay(element.result);
  //         } else if (element.name == 'MILAN NIGHT') {
  //           setMilanNight(element.result);
  //         } else if (element.name == 'RAJDHANI DAY') {
  //           setRajdhaniDay(element.result);
  //         } else if (element.name == 'RAJDHANI NIGHT') {
  //           setRajdhaniNight(element.result);
  //         } else if (element.name == 'TIME BAZAR') {
  //           setTimeBazar(element.result);
  //         } else if (element.name == 'MAIN BAZAR') {
  //           setMainBazar(element.result);
  //         } else if (element.name == 'KALYAN NIGHT') {
  //           setKalyanNight(element.result);
  //         }
  //       });
  //     } else {
  //       constants.showError(response.message);
  //     }
  //   });
  // }

  var today: any = new Date();
  var dd: any = today.getDate();
  var dd1: any = today.getDate() - 1
  var mm: any = today.getMonth() + 1;
  var yyyy: any = today.getFullYear();
  if (dd < 10) {
    dd = '0' + dd;
  }
  if (mm < 10) {
    mm = '0' + mm;
  }
  var today: any = yyyy + "-" + mm + "-" + dd;

  const getDailyResultList = () => {
    fetch(`${constants.BASE_URL}daily-result/public/guess-form/${today}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': constants.getAuthHeader()
      },
    }).then(function (response) {
      return response.json();
    }).then(function (response) {
      if (response.status === '200') {
        const result = response.result;
        if (result.length > 0) {
          const d = new Date();
          let currentTime = d.getHours();

          result.forEach((data: any) => {
            let timeEnd0 = new Date("01/01/2007 " + data.openTime).getHours() - 1;
            let timeEnd1 = new Date("01/01/2007 " + data.closeTime).getHours() + 1;
            let opentime1 = currentTime - timeEnd0;
            let closeTime1 = timeEnd1 - currentTime;
            data["openTimeDiff"] = opentime1;
            data["closeTimeDiff"] = closeTime1;
          });

          setFirstIndexObject(result[0]);
          setLastIndexObject(result[result.length - 1]);
          const pairs = [];
          for (let i = 1; i < result.length - 1; i += 2) {
            pairs.push(result.slice(i, i + 2));
          }
          setMiddleElements(pairs);
        } else {
          setFirstIndexObject({});
          setLastIndexObject({});
          setMiddleElements([]);
        }
      } else {
        constants.showError(response.message);
      }
    });
  };

  const getGuessList = () => {
    let queryParam = '?page=' + (pageNum - 1) + '&size=' + itemsPerPage;
    fetch(`${constants.BASE_URL}matka-guessing/public/${currentDate}` + queryParam, {
      method: 'GET',
      headers: { 'Content-Type': 'application/json', 'Accept': 'application/json', 'Authorization': constants.getAuthHeader() },
    }).then(function (response) {
      return response.json();
    }).then(function (response: any) {
      if (response.status == '200') {
        response.result.forEach((element: any, index: number) => {
          console.log(element.photo)
          setGuessPhotoUrl(imageUrl + element.photo + '/');
          let text = element.text;
          const replaceLastQuoteFromEnd = (text:any) => {
            const findText = '[quote]';
            const replacement = '<div class="ref-text">';
            let lastIndex = text.lastIndexOf(findText);
  
            if (lastIndex !== -1) {
              text = text.slice(0, lastIndex) + replacement + text.slice(lastIndex + findText.length);
            }
            return text;
          };
          const replaceFirstQuoteEndFromStart = (text:any) => {
            const endFindText = '[/quote]';
            const endReplacement = '</div>';
            const endIndex = text.indexOf(endFindText);
  
            if (endIndex !== -1) {
              text = text.slice(0, endIndex) + endReplacement + text.slice(endIndex + endFindText.length);
            }
            return text;
          };
          text = replaceLastQuoteFromEnd(text);
          text = replaceFirstQuoteEndFromStart(text);

          text = text.replace(/(\[\/quote\])/g, '<br>$1');
          text = text.replace(/\[quote\]/g, '');
  
          element.text = text;
        });

        setGuessList(response.result);
        setPageCount(Math.ceil(response.listCount / itemsPerPage));
      } else {
        constants.showError(response.message);
      }
    });
  }

  const handlePageClick = (event: any) => {
    setPageNum(event.selected + 1);
  };

  const prepapreAddNewGuess = () => {
    setGuess(null);
    let text = (document.getElementById('guess-text')! as HTMLInputElement).value;
    if (!text || (text && text.length == 0)) {
      constants.showSuccess('Please enter your guess')
      // alert('Please enter your guess');
      return;
    }
    addGuess({ text: text });
  }

  const prepapreAddQuote = (guess: any) => {
    if (!currentUser) {
      if (window.confirm("Not logged in, please go to login to continue")) {
        window.location.href = '/#login-section';
      }
    } else {
      reset();
      setGuess(guess);
      setTimeout(() => {
        let existingText = guess.text.replaceAll("<br/>", "\n");
        existingText = existingText.replace('<div class="ref-text">', '[quote]');
        existingText = existingText.replace('</div>', '[/quote]');
        setValue('text', '[quote]' + existingText + '[/quote]');
      }, 500);
      $('#guess-modal').modal('show');
    }
  }

  const addGuess = async (data: any) => {
    setFormSubmitLoader(true);
    if (guess && guess.matkaGuessingId) {
      data['refGuess'] = { matkaGuessingId: guess.matkaGuessingId };
    }
    data['user'] = { userId: currentUser.userId };
    data['text'] = data['text'].replaceAll("\n", "<br/>");

    if (file) {
      try {
        const base64Strings = await Promise.all([
          file.photo ? constants.convertFileToBase64(file.photo) : '',

        ]);
        data.photo = file.photo ? file.photo.name : '';
        data.encodedPhoto = base64Strings[0];

      } catch (error) {
        console.log("Not getting the result....", error);
      }
    }

    fetch(constants.BASE_URL + 'matka-guessing', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json', 'Accept': 'application/json', 'Authorization': constants.getAuthHeader() },
      body: JSON.stringify(data)
    }).then(function (response) {
      return response.json();
    }).then(function (response: any) {
      setFormSubmitLoader(false);
      if (response.status == '201') {
        reset();
        (document.getElementById('guess-text')! as HTMLInputElement).value = '';
        $('#guess-modal').modal('hide');
        constants.showSuccess('Request processed successfully');
        setGuess(null);
        //getGuessList();
        window.location.reload();
      } else {
        constants.showError(response.message);
      }
    });
  }
  // code for scroll

  const [visible, setVisible] = useState(true);
  const [visible1, setVisible1] = useState(false)

  const toggleVisible = () => {
    const scrolled = document.documentElement.scrollTop;
    if (scrolled > 0) {
      setVisible(false)
    } else if (scrolled <= 0) {
      setVisible(true)
    }
  };

  const scrollToBottom = () => {
    document.getElementById("scroll1")!.scrollIntoView();
  }

  const toggleVisible1 = () => {
    const scrolled = document.documentElement.scrollTop;
    if (scrolled > 300) {
      setVisible1(true)
    }
    else if (scrolled <= 300) {
      setVisible1(false)
    }
  };

  const scrollToTop1 = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
      /* you can also use 'auto' behaviour
         in place of 'smooth' */
    });
  };

  const prepareChangePassword = () => {
    reset2();
    $('#pwd-modal').modal('show');
  }

  const changePassword = (data: any) => {
    if (data.newPassword != data.retypePassword) {
      constants.showSuccess('Passwords dont match')
      // alert("Passwords don't match");
      return;
    }
    setFormSubmitLoader(true);
    fetch(constants.BASE_URL + 'user/profile/change-password/' + currentUser.userId, {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json', 'Accept': 'application/json', 'Authorization': constants.getAuthHeader() },
      body: JSON.stringify(data)
    }).then(function (response) {
      return response.json();
    }).then(function (response: any) {
      setFormSubmitLoader(false);
      if (response.status == '200') {
        reset2();
        $('#pwd-modal').modal('hide');
        constants.showSuccess('Password changed successfully');
      } else {
        constants.showError(response.message);
      }
    });
  }

  const getAdvertisement = () => {
    fetch(constants.BASE_URL + 'advertisement/public', {
      method: 'GET',
      headers: { 'Content-Type': 'application/json', 'Accept': 'application/json', 'Authorization': constants.getAuthHeader() },
    }).then(function (response) {
      return response.json();
    }).then(function (response: any) {
      if (response.status == '200') {
        setAdverisement(response.result);

      } else {
        constants.showError(response.message);
      }
    });


  }


  window.addEventListener('scroll', toggleVisible1);

  return (
    <>
      <div className="main-div">
        <div className='container-fluid'>
          <div className="col-lg-12 col-md-12 my-2">
            <div className="col-lg-12 col-md-12 col-sm-12 my-1 titlemg1">
              <a className="text-color" href="https://www.worldsattamatka4u.com/">WorldSattaMatka4u.com</a> <br /> Matka Guessing Forum
            </div>
          </div>

          <div className="row color-font">
            {firstIndexObject && (
              <div className="col-lg-12 col-md-12 col-sm-12">
                <div className="col-lg-12 col-md-12 col-sm-12 lotheadinginfokalyan" style={{ backgroundColor: firstIndexObject.color || 'yellow' }}>
                  <div className="service-title1" style={{ color: firstIndexObject.fontColor || 'black' }}>{firstIndexObject.name}</div>
                  <div className="text-center h3 mb-2" style={{ color: firstIndexObject.fontColor || 'black' }}>{firstIndexObject.result || '--'}</div>
                  <div className="text-center h4 mb-2" dangerouslySetInnerHTML={{ __html: firstIndexObject.description ? firstIndexObject.description : " " }}>
                    {/* {data.description ? data.description :" " } */}
                  </div>
                </div>
              </div>
            )}
          </div>

          <div className="">
            {middleElements.map((pair, groupIndex) => (
              <div className="row color-font" key={groupIndex}>
                {pair.map((data, index) => (
                  <div key={index} className={`col-lg-6 col-sm-6 col-md-6  col-xl-6`}>
                    <div className="lotheadinglight1 my-1" style={{ backgroundColor: data.color || 'yellowgreen' }}>
                      <div className="titlered2k" style={{ color: data.fontColor || 'black' }}>{data.name}</div>
                      <div className="color2k text-center h5 mb-2" style={{ color: data.fontColor || 'black' }}>{data.result || '--'}</div>
                      {/* Render other fields based on your data */}
                    </div>
                  </div>
                ))}
              </div>
            ))}
          </div>

          <div className="row color-font">
            {lastIndexObject && (
              <div className="col-lg-12 col-md-12 col-sm-12">
                <div className="col-lg-12 col-md-12 col-sm-12 lotheadinginfokalyan" style={{ backgroundColor: lastIndexObject.color || 'yellow' }}>
                  <div className="service-title1" style={{ color: lastIndexObject.fontColor || 'black' }}>{lastIndexObject.name}</div>
                  <div className="text-center h3 mb-2" style={{ color: lastIndexObject.fontColor || 'black' }}>{lastIndexObject.result || '--'}</div>
                  <div className="text-center h4 mb-2" dangerouslySetInnerHTML={{ __html: lastIndexObject.description ? lastIndexObject.description : " " }}>
                 
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>

        <div id='id1' className='guesscontent mt-4'>
          {/* scrollbutton to bottom */}
          <a href='/'><div className='subheading1 btn btn-sm btn-danger px-2'>
            <div className="home-button">
              <i className="fas fa-globe"></i>
              <span>Home</span>
            </div>

          </div>
          </a>
          <button type='button' className="btn btn-success btn-sm m-2" onClick={scrollToBottom}>
            <i className="fa fa-arrow-down"
              style={{ display: visible ? 'inline' : 'none' }} > Go to bottom</i>
          </button>
          <button type="button" className="btn btn-info btn-sm" onClick={() => window.location.reload()}> Refresh</button>
          {currentUser &&
            <div className="row">
              <div className='col-3'></div>
              <div className='col'>
                <label className='form-control-label text-white'>Text</label>
                <textarea className='form-control guess-text' placeholder='' id='guess-text'></textarea>
                {currentUser.role.name === 'ADMIN' && (
                  <div>
                    <label className='form-control-label text-white'>Image</label>
                    <input className="form-control" id="image" type="file" name="image" accept="image/*"
                      onChange={(event) => {
                        handelOnChange(event, "photo");
                      }}
                    />
                  </div>
                )}
                <button type='button' className='btn btn-primary btn-sm ml-2 mt-2' onClick={prepapreAddNewGuess}>Submit</button>
              </div>
              <div className='col-3'></div>
            </div>
          }
          {guessList?.map((data: any, index: number) => (
            <div className="col-lg-12 col-md-12 lotheadinginfo my-1 " key={index}>
              <div className='d-flex justify-content-between bgquote'>
                <div>
                  <div>
                    <div className='expertname'>
                      <span className='text-danger first-letter'>{data.user?.name.substring(0, 1)}</span>
                      {data.user?.name.substring(1, data.user?.name.length)}</div>
                  </div>
                  <div className='text-dark font-weight-bold text-left'>{data.user?.userText}</div>
                  <div className='text-dark font-weight-bold text-left'>{data.createdDateStr}</div>
                  <div className='text-dark font-weight-bold text-left'>Call - {data.user?.mobile}</div>
                </div>
                <div>
                  <div className='text-danger h4'>{data.isUserOnline ? 'Online' : 'Offline'}</div>
                  {/* {currentUser &&
                  <div className='text-info cursor-pointer mt-2 h4' onClick={() => prepapreAddQuote(data)}>[Quote]</div>
                } */}
                </div>
              </div>
              <b className='text-danger'></b>
              <div className='main-text'>
                {data.quoteText &&
                  <div className='ref-text'>
                    {/* <div>Written by {data.user?.name} on {data.createdDateStr}</div> */}
                    <div dangerouslySetInnerHTML={{ __html: data?.quoteText }}></div>
                  </div>
                }
                <div dangerouslySetInnerHTML={{ __html: data?.text }}></div>

                {data.photo &&
                  <div className="row">
                    <div className='col-3'></div>
                    <div className='col text-center pt-2'>
                      <img src={imageUrl + data.photo + '/'}
                        alt="Guess Image"
                        className="textimg1" />
                    </div>
                    <div className='col-3'></div>
                  </div>
                }
              </div>
              <div className='quote-text cursor-pointer text-right h4' onClick={() => prepapreAddQuote(data)}>[Quote]</div>
            </div>
          ))}
        </div>
        <div className='row'>
          <div className='col text-start ms-2'>
            <ReactPaginate breakLabel="..." nextLabel=">" onPageChange={handlePageClick} pageRangeDisplayed={5}
              pageCount={pageCount} previousLabel="<" pageClassName="page-item" pageLinkClassName="page-link"
              previousClassName="page-item" previousLinkClassName="page-link" nextClassName="page-item" nextLinkClassName="page-link"
              breakClassName="page-item" breakLinkClassName="page-link" containerClassName="pagination" activeClassName="active"
            />
          </div>
        </div>
        {currentUser &&
          <div className='row'>
            <div className='col text-start ms-2'>
              <a className='text-info' onClick={prepareChangePassword}>Change Password</a>
            </div>
          </div>
        }
        <div id="scroll1">
          {/* Guessing forum starts */}
          <button type='button' className="btn btn-success m-2" onClick={scrollToTop1} >
            <i className="fa fa-arrow-up"
              style={{ display: visible1 ? 'inline' : 'none' }} > Go to top</i>
          </button>
          <div className='lotheadingpink'>
            <div>
              {/* <h4>{advertisement?.ruleForGuesser ? advertisement?.ruleForGuesser : "Data not availbale"}</h4> */}
              <h4>{advertisement?.ruleForGuesser ? (
                <div dangerouslySetInnerHTML={{ __html: advertisement.ruleForGuesser }} />
              ) : "Data not available"}</h4>
              <h4>Advertising time</h4>
              <h5>Morning - {advertisement?.morningStartTime ? advertisement?.morningStartTime : '00:00 AM'} To {advertisement?.morningEndTime ? advertisement?.morningEndTime : '00:00 AM'}</h5>
              <h5>Evening - {advertisement?.eveningStartTime ? advertisement?.eveningStartTime : '00:00 PM'} To {advertisement?.eveningEndTime ? advertisement?.eveningEndTime : '00:00 PM'}</h5>
            </div>
          </div>
          <div className='lotheadingblue my-1'>
            <h4>{advertisement?.contact ? (
                <div dangerouslySetInnerHTML={{ __html: advertisement.contact }} />
              ) : "xxxxxxxxxx"}</h4>
          </div>
          <div id="guess-modal" className="modal fade" role="dialog" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered modal-shadow" role="document">
              <div className="modal-content border-0">
                <div className="modal-header">
                  <h5 className="modal-title">Service Details</h5>
                  <button type="button" className="btn-close" data-dismiss="modal" aria-label="Close"></button>
                </div>
                <form id="loginForm" method="post" onSubmit={handleSubmit(addGuess)}>
                  <div className="modal-body py-4 px-0">
                    <div className="row">
                      <div className="col-11 col-md-10 mx-auto">
                        {guess && guess.quoteText &&
                          <div className="mb-3">
                            <label className="number" htmlFor="name">Quote</label>
                            <h5 dangerouslySetInnerHTML={{ __html: guess?.quoteText }}></h5>
                          </div>
                        }
                        <div className="mb-3">
                          <label className="number" htmlFor="closetime">Guess<span className='star'>*</span></label>
                          <textarea className="form-control" id="guess" placeholder=" "
                            {...register("text", { required: true })}>
                          </textarea>
                          {errors.text && <span className='text-danger'>Text is required </span>}
                        </div>
                        {currentUser &&
                          <div className="mb-3">
                            {currentUser.role.name === 'ADMIN' && (
                              <div>
                                <label className="number" htmlFor="closetime">Image<span className='star'>*</span></label>
                                <input className="form-control" id="image" type="file" name="image" accept="image/*"
                                  onChange={(event) => {
                                    handelOnChange(event, "photo");
                                  }}
                                />
                              </div>
                            )}
                          </div>
                        }
                      </div>
                    </div>
                  </div>
                  <div className="modal-footer">
                    <button type="button" className="btn btn-danger btn-sm" data-dismiss="modal">Close</button>
                    <button type="submit" className="btn btn-primary btn-sm" disabled={formSubmitLoader}>
                      {formSubmitLoader && <i className="fas fa-circle-notch fa-spin me-2"></i>}
                      Submit
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <div id="pwd-modal" className="modal fade" role="dialog" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered modal-shadow" role="document">
              <div className="modal-content border-0">
                <div className="modal-header">
                  <h5 className="modal-title">Change Password</h5>
                  <button type="button" className="btn-close" data-dismiss="modal" aria-label="Close"></button>
                </div>
                <form id="loginForm" method="post" onSubmit={handleSubmit2(changePassword)}>
                  <div className="modal-body py-4 px-0">
                    <div className="row">
                      <div className="col-11 col-md-10 mx-auto">
                        <div className="mb-3">
                          <label className="number" htmlFor="closetime">New Password<span className='star'>*</span></label>
                          <input type="password" className="form-control" placeholder="New Password"
                            {...register2("newPassword", { required: true })} />
                          {errors2.newPassword && <span className='text-danger'>Password is required </span>}
                        </div>
                        <div className="mb-3">
                          <label className="number" htmlFor="closetime">Retype password<span className='star'>*</span></label>
                          <input type="password" className="form-control" placeholder="Retype Password"
                            {...register2("retypePassword", { required: true })} />
                          {errors2.retypePassword && <span className='text-danger'>Retype password is required </span>}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="modal-footer">
                    <button type="button" className="btn btn-danger btn-sm" data-dismiss="modal">Close</button>
                    <button type="submit" className="btn btn-primary btn-sm" disabled={formSubmitLoader}>
                      {formSubmitLoader && <i className="fas fa-circle-notch fa-spin me-2"></i>}
                      Change Password
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        <div className='bottomrefresh'>
          <button type="button" className="btn btn-info btn-sm" onClick={() => window.location.reload()}>Click to Refresh</button>
        </div>
      </div>
    </>
  )
}
