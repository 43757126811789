import  { BehaviorSubject, Observable, ReplaySubject } from "rxjs";

declare var $: any;
//const BASE_URL = "http://localhost:8080/v1/";
const BASE_URL = "https://api.worldsattamatka4u.com/v1/";

// const BASE_URL = process.env.REACT_APP_API_URL;
const CASHFREE_URL = process.env.REACT_APP_CASHFREE_URL;
const IMG_EXTNS = ['jpg', 'JPG', 'jpeg', 'JPEG', 'png', 'PNG'];
const loginSubscriber = new BehaviorSubject<boolean>(false);

const loginStatusService = {
  send: (msg: boolean) => {
    loginSubscriber.next(msg);
  }
}

const showSuccess = (msg: string) => {
  $("#success-msg").html(msg);
  $("#success-alert").show();
  setTimeout(function () {
    $("#success-alert").hide();
  }, 3000);
}

const showError = (msg: string) => {
  $("#error-msg").html(msg);
    $("#error-alert").show();
    setTimeout(function () {
      $("#error-alert").hide();
    }, 3000);
}

const getAuthHeader = () => {
  let authHeader = '';
  if(sessionStorage.getItem('currentUser')){
    const user = JSON.parse(sessionStorage.getItem('currentUser')!);
    authHeader = 'Bearer ' + user.sessionToken;
  }
  return authHeader;
}

export const convertFileToBase64 = (file: File): Promise<string> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => {
      const base64String = reader.result as string;
      resolve(base64String);
    };
    reader.onerror = reject;
    reader.readAsDataURL(file);
  });
};

export const summernoteConfig = {
  toolbar: [
    ['style', ['bold', 'italic', 'underline', 'strikethrough']],
    ['font', ['clear']],
    ['color', ['color']],
    ['para', ['ul', 'ol', 'paragraph']],
    ['table', ['table']],
    ['insert', ['link', 'picture', 'video']],
    ['view', ['fullscreen', 'codeview', 'help']]
  ],
  height: 200,
  placeholder: 'Write here...'
};

   // satta daily results starts here
  // date in yyyy-mm-dd format
  var today:any = new Date();
  var dd:any = today.getDate();
  var mm:any = today.getMonth() + 1;
  var yyyy:any = today.getFullYear();
  if (dd < 10) {
      dd = '0' + dd;
  }
  if (mm < 10) {
      mm = '0' + mm;
  }
  var today:any= yyyy +"-" + mm +"-" + dd ;
  var today1:any= dd +"-" + mm +"-" + yyyy ;

export { BASE_URL, CASHFREE_URL, loginStatusService, loginSubscriber, showSuccess, showError, getAuthHeader,today }
