import React, { useEffect, useState } from 'react'
import { NavLink } from 'react-router-dom'
import ProfileSidebar from '../../ProfileSidebar/ProfileSidebar'
import * as constants from '../../../Constants/Constants'
import { useForm } from 'react-hook-form';
import ReactPaginate from 'react-paginate';
import './TodayTips.css';

declare var $: any;

export default function TodayTips() {

  const { register, handleSubmit, setValue, formState: { errors }, reset } = useForm();
  const [formSubmitLoader, setFormSubmitLoader] = useState<boolean>(false);
  const [ttList, setTtList] = useState<any[]>();
  const [currentDate, setCurrentDate] = useState<string>(new Date().toISOString().split("T")[0]);
  const [selectedDate, setSelectedDate] = useState('');

  useEffect(() => {
    getTtList();
  }, [selectedDate]);

  const getTtList = () => {
    let queryParam = '';
    if (selectedDate) {
      queryParam += '?date=' + selectedDate;
    }
    if (currentDate && !selectedDate) {
      queryParam += '?date=' + currentDate;
    }
    fetch(constants.BASE_URL + 'today-tips' + queryParam, {
      method: 'GET',
      headers: { 'Content-Type': 'application/json', 'Accept': 'application/json', 'Authorization': constants.getAuthHeader() },
    }).then(function (response) {
      return response.json();
    }).then(function (response: any) {
      if (response.status == '200') {
        setTtList(response.result);
      }
    });
  }

  const prepareAddTt = () => {
    $('#tt-modal').modal('show');
  }

  const addTt = (data: any) => {
    setFormSubmitLoader(true);
    fetch(constants.BASE_URL + 'today-tips', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json', 'Accept': 'application/json', 'Authorization': constants.getAuthHeader() },
      body: JSON.stringify(data)
    }).then(function (response) {
      return response.json();
    }).then(function (response: any) {
      setFormSubmitLoader(false);
      if (response.status == '201') {
        reset();
        $('#tt-modal').modal('hide');
        constants.showSuccess('Request processed successfully');
        getTtList();
      } else {
        constants.showError(response.message);
      }
    });
  }

  const prepareEditTt = (ttId: string) => {

  }

  const onDateChange = (e: any) => {
    setCurrentDate(e.target.value);
  }
  // function to change date format
function formatDate(input: any) {
  let datePart = input.match(/\d+/g),
    year = datePart[0],
    // get only two digits 
    month = datePart[1],
    day = datePart[2];
  return   day + '-'+ month + '-' +  year  ;
}

const deleteTodayTips = (todayTipsId: string) => {
  if (window.confirm('Are you sure, you want to delete this record?')) {
    setFormSubmitLoader(true);
    fetch(constants.BASE_URL + 'today-tips/' + todayTipsId, {
      method: 'DELETE',
      headers: { 'Content-Type': 'application/json', 'Accept': 'application/json', 'Authorization': constants.getAuthHeader() },
    }).then(function (response) {
      return response.json();
    }).then(function (response: any) {
      setFormSubmitLoader(false);
      if (response.status == '200') {
        getTtList();
        constants.showSuccess(response.message);
      } else {
        constants.showError(response.message);
      }
    });
  }
}

  return (
    <div className='page-content'>
      <section className="page-header page-header-dark bg-secondary">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-8">
              <h2 className='admin-heading mb-0 text-white'>ADMIN PANEL</h2>
            </div>
            <div className="col-md-4">

            </div>
          </div>
        </div>
      </section>
      <div className="container-fluid p-5 mb-2">
        <div className="row">
          <div className="col-lg-2">
            <ProfileSidebar />
          </div>
          <div className="col-lg-10">
            <div className="bg-white shadow-md rounded p-4">
              <div className='row'>
                <div className='col'>
                  <h4 className="mb-2 text-left">Today Tips</h4>
                </div>
                {/* <div className='col-lg-4'>
                  <input type="text" placeholder='Search by service name' className='form-control form-control-sm w-50 float-end'
                    onChange={searchByName} />
                </div> */}
               <div className='col-2'>
                  {<input type="date" className='form-control'  value={selectedDate ? selectedDate : currentDate} onChange={(e) => setSelectedDate(e.target.value)} />}
                </div>
                <div className='col-2'>
                  <button type='button' className="btn btn-info btn-sm float-right" onClick={prepareAddTt}>Add New Tip</button>
                </div>
              </div>
              <div className="tab-content my-3" id="myTabContent">
                <div className="tab-pane fade show active" id="first" role="tabpanel" aria-labelledby="first-tab">
                  <div className="table-responsive-md">
                    <table className="table table table-striped table-hover text-dark">
                      <thead >
                        <tr>
                          <th>Sr No</th>
                          <th>Date</th>
                          <th>Today Fix Number</th>
                          <th>Motor Patti</th>
                          <th>Jodi</th>
                          <th>Cycle Patti</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {ttList?.map((data: any, index: number) => (
                          <tr key={index}>
                            <td className="align-middle">{index + 1}</td>
                            <td className="align-middle">{formatDate(data.date)}</td>
                            <td className="align-middle">{data.todayFixNumber}</td>
                            <td className="align-middle">{data.motorPatti}</td>
                            <td className="align-middle">{data.jodi}</td>
                            <td className="align-middle">{data.cyclePatti}</td>
                            {/* <td className="align-middle">
                              <button className="featured-box-icon bg-primary text-white rounded-circle border-0 edit-icon"
                                onClick={() => prepareEditLotteryClub(data.lotteryId)}>
                                <i className="fas fa-edit"></i>
                              </button>
                            </td> */}
                            <td>
                            <button className="featured-box-icon bg-danger text-white rounded-circle border-0 edit-icon"
                                onClick={() => deleteTodayTips(data.todayTipsId)} disabled={formSubmitLoader}>
                                {!formSubmitLoader && <i className="fas fa-trash"></i>}
                                {formSubmitLoader && <i className="fas fa-circle-notch fa-spin me-2"></i>}
                              </button>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="tt-modal" className="modal fade" role="dialog" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered modal-shadow" role="document">
          <div className="modal-content border-0">
            <div className="modal-header">
              <h5 className="modal-title">Today Tips</h5>
              <button type="button" className="btn-close" data-dismiss="modal" aria-label="Close"></button>
            </div>
            <form id="loginForm" method="post" onSubmit={handleSubmit(addTt)}>
              <div className="modal-body py-4 px-0">
                <div className="row">
                  <div className="col-11 col-md-10 mx-auto">
                    {/* <input type="hidden" id="serviceId" {...register("serviceId", { required: true })}/> */}
                    <div className="mb-3">
                      <label className="number" htmlFor="date">Date<span className='star'>*</span></label>
                      <input type="date" className="form-control" id="date" placeholder="Date"
                        {...register("date", { required: true })} />
                      {errors.date && <span className='text-danger'>Date is required </span>}
                    </div>
                    <div className="mb-3">
                      <label className="number" htmlFor="todayFixNumber">Today Fix Number</label>
                      <input type="text" className="form-control" id="todayFixNumber" placeholder="Today Fix Number"
                        {...register("todayFixNumber", { required: false })} />
                    </div>
                    <div className="mb-3">
                      <label className="number" htmlFor="motorPatti">Motor Patti</label>
                      <input type="text" className="form-control" id="motorPatti" placeholder="Motor Patti"
                        {...register("motorPatti", { required: false })} />
                    </div>
                    <div className="mb-3">
                      <label className="number" htmlFor="jodi">Jodi</label>
                      <input type="text" className="form-control" id="jodi" placeholder="Jodi"
                        {...register("jodi", { required: false })} />
                    </div>
                    <div className="mb-3">
                      <label className="number" htmlFor="cyclePatti">Cycle Patti</label>
                      <input type="text" className="form-control" id="cyclePatti" placeholder="Cycle Patti"
                        {...register("cyclePatti", { required: false })} />
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <button type="button" className="btn btn-danger btn-sm" data-dismiss="modal">Close</button>
                <button type="submit" className="btn btn-primary btn-sm" disabled={formSubmitLoader}>
                  {formSubmitLoader && <i className="fas fa-circle-notch fa-spin me-2"></i>}
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  )
}
