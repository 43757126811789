import React, { useEffect, useState } from 'react'
import ProfileSidebar from '../../ProfileSidebar/ProfileSidebar'
import * as constants from '../../../Constants/Constants'
import { useForm } from 'react-hook-form';
import ReactPaginate from 'react-paginate';

declare var $: any;

function TodayFreeGame() {

  const { register, handleSubmit, setValue, formState: { errors }, reset } = useForm();
  const [formSubmitLoader, setFormSubmitLoader] = useState<boolean>(false);

  const [tfgList, settfgList] = useState<any[]>();
  const [tfg, settfg] = useState<any>();
  const [currentDate, setCurrentDate] = useState<string>(new Date().toISOString().split("T")[0]);
  const [updateflag, setUpdateflag] = useState<boolean>(false);
  const [tfgId, settfgId] = useState<any[]>();
  const [pageCount, setPageCount] = useState(0);
  const [pageNum, setPageNum] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(20);
  const [selectedDate, setSelectedDate] = useState('');
  const [lotteryClubList, setLotteryClubList] = useState<any[]>();
  const [selectedLotteryClub, setSelectedLotteryClub] = useState('');

  useEffect(() => {
    getLotteryClubList();
  }, []);

  useEffect(() => {
    getTfgList();
  }, [pageNum,selectedDate,selectedLotteryClub]);

  const getLotteryClubList = () => {
    fetch(constants.BASE_URL + 'lottery-club/dropdown', {
      method: 'GET',
      headers: { 'Content-Type': 'application/json', 'Accept': 'application/json', 'Authorization': constants.getAuthHeader() },
    }).then(function (response) {
      return response.json();
    }).then(function (response: any) {
      if (response.status == '200') {
        setLotteryClubList(response.result);
      }
    });
  }

  const getTfgList = () => {
    let queryParam = '?page=' + (pageNum - 1) + '&size=' + itemsPerPage;
    if (selectedDate) {
      queryParam += '&weekStartDate=' + selectedDate;
    }
    // if (currentDate && !selectedDate) {
    //   queryParam += '&weekStartDate=' + currentDate;
    // }
    if (selectedLotteryClub) {
      queryParam += queryParam ? '&' : '?';
      queryParam += 'lotteryId=' + selectedLotteryClub;
    }
    fetch(constants.BASE_URL + 'today-free-game' + queryParam, {
      method: 'GET',
      headers: { 'Content-Type': 'application/json', 'Accept': 'application/json', 'Authorization': constants.getAuthHeader() },
    }).then(function (response) {
      return response.json();
    }).then(function (response: any) {
      if (response.status == '200') {
        settfgList(response.result);
        setPageCount(Math.ceil(response.listCount / itemsPerPage));
      }
    });
  }

  const prepareAddSfg = () => {
    $('#sfg-modal').modal('show');
  }

  const handlePageClick = (event: any) => {
    setPageNum(event.selected + 1);
  };

  const addTfg = (data: any) => {
    if (data.mondayOne) {
      data.mondayOne = data.mondayOne?.replaceAll('\n', '<br>');
    }
    if (data.mondayTwo) {
      data.mondayTwo = data.mondayTwo?.replaceAll('\n', '<br>');
    }
    if (data.tuesdayOne) {
      data.tuesdayOne = data.tuesdayOne?.replaceAll('\n', '<br>');
    }
    if (data.tuesdayTwo) {
      data.tuesdayTwo = data.tuesdayTwo?.replaceAll('\n', '<br>');
    }
    if (data.wednesdayOne) {
      data.wednesdayOne = data.wednesdayOne?.replaceAll('\n', '<br>');
    }
    if (data.wednesdayTwo) {
      data.wednesdayTwo = data.wednesdayTwo?.replaceAll('\n', '<br>');
    }
    if (data.thursdayOne) {
      data.thursdayOne = data.thursdayOne?.replaceAll('\n', '<br>');
    }
    if (data.thursdayTwo) {
      data.thursdayTwo = data.thursdayTwo?.replaceAll('\n', '<br>');
    }
    if (data.fridayOne) {
      data.fridayOne = data.fridayOne?.replaceAll('\n', '<br>');
    }
    if (data.fridayTwo) {
      data.fridayTwo = data.fridayTwo?.replaceAll('\n', '<br>');
    }
    if (data.saturdayOne) {
      data.saturdayOne = data.saturdayOne?.replaceAll('\n', '<br>');
    }
    if (data.saturdayTwo) {
      data.saturdayTwo = data.saturdayTwo?.replaceAll('\n', '<br>');
    }
    if (data.sundayOne) {
      data.sundayOne = data.sundayOne?.replaceAll('\n', '<br>');
    }
    if (data.sundayTwo) {
      data.sundayTwo = data.sundayTwo?.replaceAll('\n', '<br>');
    }
    if (!data.lotteryClubOne) {
      data.lotteryClubOne = {};
    }
    if (!data.lotteryClubTwo) {
      data.lotteryClubTwo = {};
    }
    data.lotteryClubOne.lotteryId = data.lotteryClubIdOne;
    data.lotteryClubTwo.lotteryId = data.lotteryClubIdTwo;

    console.log(data, "data")
    setFormSubmitLoader(true);
    data['lotteryClub'] = { lotteryId: data['lotteryId'] };
    fetch(constants.BASE_URL + 'today-free-game', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json', 'Accept': 'application/json', 'Authorization': constants.getAuthHeader() },
      body: JSON.stringify(data)
    }).then(function (response) {
      return response.json();
    }).then(function (response: any) {
      setFormSubmitLoader(false);
      if (response.status == '201') {
        reset();
        $('#sfg-modal').modal('hide');
        constants.showSuccess('Request processed successfully');
        getTfgList();
      } else {
        constants.showError(response.message);
      }
    });
  }



  // const onDateChange = (e: any) => {
  // setCurrentDate(e.target.value);
  // }

  // edit data

  const prepareEditTfg = (data: any) => {
    setUpdateflag(true);
    $('#sfg-modal').modal('show');
    settfgId(data.todayFreeGameId);
    setTimeout(() => {
      setValue('todayFreeGameId', data.todayFreeGameId, { shouldDirty: true });
      setValue('weekStartDate', data.weekStartDate, { shouldDirty: true });
      setValue('weekEndDate', data.weekEndDate, { shouldDirty: true });
      setValue('mondayOne', data?.mondayOne.replaceAll('<br>', '\n'));
      setValue('mondayTwo', data?.mondayTwo.replaceAll('<br>', '\n'));
      setValue('mondayOnePassed', data.mondayOnePassed, { shouldDirty: true });
      setValue('mondayTwoPassed', data.mondayTwoPassed, { shouldDirty: true });
      setValue('tuesdayOne', data?.tuesdayOne.replaceAll('<br>', '\n'));
      setValue('tuesdayTwo', data?.tuesdayTwo.replaceAll('<br>', '\n'), { shouldDirty: true });
      setValue('tuesdayOnePassed', data.tuesdayOnePassed, { shouldDirty: true });
      setValue('tuesdayTwoPassed', data.tuesdayTwoPassed, { shouldDirty: true });
      setValue('thursdayOne', data?.thursdayOne.replaceAll('<br>', '\n'), { shouldDirty: true });
      setValue('thursdayTwo', data?.thursdayTwo.replaceAll('<br>', '\n'), { shouldDirty: true });
      setValue('thursdayOnePassed', data.thursdayOnePassed, { shouldDirty: true });
      setValue('thursdayTwoPassed', data.thursdayTwoPassed, { shouldDirty: true });
      setValue('wednesdayOne', data?.wednesdayOne.replaceAll('<br>', '\n'), { shouldDirty: true });
      setValue('wednesdayTwo', data?.wednesdayTwo.replaceAll('<br>', '\n'), { shouldDirty: true });
      setValue('wednesdayOnePassed', data.wednesdayOnePassed, { shouldDirty: true });
      setValue('wednesdayTwoPassed', data.wednesdayTwoPassed, { shouldDirty: true });
      setValue('thursdayOne', data?.thursdayOne.replaceAll('<br>', '\n'), { shouldDirty: true });
      setValue('thursdayTwo', data?.thursdayTwo.replaceAll('<br>', '\n'), { shouldDirty: true });
      setValue('thursdayOnePassed', data.thursdayOnePassed, { shouldDirty: true });
      setValue('thursdayTwoPassed', data.thursdayTwoPassed, { shouldDirty: true });
      setValue('fridayOne', data?.fridayOne.replaceAll('<br>', '\n'), { shouldDirty: true });
      setValue('fridayTwo', data?.fridayTwo.replaceAll('<br>', '\n'), { shouldDirty: true });
      setValue('fridayOnePassed', data.fridayOnePassed, { shouldDirty: true });
      setValue('fridayTwoPassed', data.fridayTwoPassed, { shouldDirty: true });
      setValue('saturdayOne', data?.saturdayOne.replaceAll('<br>', '\n'), { shouldDirty: true });
      setValue('saturdayTwo', data?.saturdayTwo.replaceAll('<br>', '\n'), { shouldDirty: true });
      setValue('saturdayOnePassed', data.saturdayOne, { shouldDirty: true });
      setValue('saturdayTwoPassed', data.saturdayTwo, { shouldDirty: true });
      setValue('sundayOne', data?.sundayOne.replaceAll('<br>', '\n'), { shouldDirty: true });
      setValue('sundayTwo', data?.sundayTwo.replaceAll('<br>', '\n'), { shouldDirty: true });
      setValue('sundayOnePassed', data.sundayOnePassed, { shouldDirty: true });
      setValue('sundayTwoPassed', data.sundayTwoPassed, { shouldDirty: true });
      setValue('lotteryId', data.lotteryClub.lotteryId , { shouldDirty: true });
      setValue('lotteryClubIdOne', data.lotteryClubOne.lotteryId, { shouldDirty: true });
      setValue('lotteryClubIdTwo', data.lotteryClubTwo.lotteryId, { shouldDirty: true });
    }, 500)
  }
  // put api in satta king jodi
  const editSattakingJodi = (data: any) => {
    setFormSubmitLoader(true);
    if (data.mondayOne) {
      data.mondayOne = data.mondayOne?.replaceAll('\n', '<br>');
    }
    if (data.mondayTwo) {
      data.mondayTwo = data.mondayTwo?.replaceAll('\n', '<br>');
    }
    if (data.tuesdayOne) {
      data.tuesdayOne = data.tuesdayOne?.replaceAll('\n', '<br>');
    }
    if (data.tuesdayTwo) {
      data.tuesdayTwo = data.tuesdayTwo?.replaceAll('\n', '<br>');
    }
    if (data.wednesdayOne) {
      data.wednesdayOne = data.wednesdayOne?.replaceAll('\n', '<br>');
    }
    if (data.wednesdayTwo) {
      data.wednesdayTwo = data.wednesdayTwo?.replaceAll('\n', '<br>');
    }
    if (data.thursdayOne) {
      data.thursdayOne = data.thursdayOne?.replaceAll('\n', '<br>');
    }
    if (data.thursdayTwo) {
      data.thursdayTwo = data.thursdayTwo?.replaceAll('\n', '<br>');
    }
    if (data.fridayOne) {
      data.fridayOne = data.fridayOne?.replaceAll('\n', '<br>');
    }
    if (data.fridayTwo) {
      data.fridayTwo = data.fridayTwo?.replaceAll('\n', '<br>');
    }
    if (data.saturdayOne) {
      data.saturdayOne = data.saturdayOne?.replaceAll('\n', '<br>');
    }
    if (data.saturdayTwo) {
      data.saturdayTwo = data.saturdayTwo?.replaceAll('\n', '<br>');
    }
    if (data.sundayOne) {
      data.sundayOne = data.sundayOne?.replaceAll('\n', '<br>');
    }
    if (data.sundayTwo) {
      data.sundayTwo = data.sundayTwo?.replaceAll('\n', '<br>');
    }
    if (!data.lotteryClubOne) {
      data.lotteryClubOne = {};
    }
    if (!data.lotteryClubTwo) {
      data.lotteryClubTwo = {};
    }
    data.lotteryClubOne.lotteryId = data.lotteryClubIdOne;
    data.lotteryClubTwo.lotteryId = data.lotteryClubIdTwo;

    data['lotteryClub'] = { lotteryId: data['lotteryId'] };
    fetch(constants.BASE_URL + 'today-free-game/' + tfgId, {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json', 'Accept': 'application/json', 'Authorization': constants.getAuthHeader() },
      body: JSON.stringify(data)
    }).then(function (response) {
      return response.json();
    }).then(function (response: any) {
      setFormSubmitLoader(false);
      if (response.status == '200') {
        $('#sfg-modal').modal('hide');
        constants.showSuccess('Request processed successfully');
        getTfgList();
      } else {
        constants.showError(response.message);
      }
    });
  }
  // function to change date format
  function formatDate(input: any) {
    if (input != undefined) {
      let datePart = input.match(/\d+/g),
        year = datePart[0],
        // get only two digits 
        month = datePart[1],
        day = datePart[2];
      return day + '-' + month + '-' + year;
    }
  }

  const deleteTodayFreeGame = (todayFreeGameId: string) => {
    if (window.confirm('Are you sure, you want to delete this record?')) {
      setFormSubmitLoader(true);
      fetch(constants.BASE_URL + 'today-free-game/' + todayFreeGameId, {
        method: 'DELETE',
        headers: { 'Content-Type': 'application/json', 'Accept': 'application/json', 'Authorization': constants.getAuthHeader() },
      }).then(function (response) {
        return response.json();
      }).then(function (response: any) {
        setFormSubmitLoader(false);
        if (response.status == '200') {
          getTfgList();
          constants.showSuccess(response.message);
        } else {
          constants.showError(response.message);
        }
      });
    }
  }

  return (
    <>
      <div className='page-content'>
        <section className="page-header page-header-dark bg-secondary">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-md-8">
                <h2 className='admin-heading mb-0 text-white'>ADMIN PANEL</h2>
              </div>
              <div className="col-md-4">

              </div>
            </div>
          </div>
        </section>
        <div className="container-fluid p-5 mb-2">
          <div className="row">
            <div className="col-lg-2">
              <ProfileSidebar />
            </div>
            <div className="col-lg-10">
              <div className="bg-white shadow-md rounded p-4">
              <div className='row align-items-center'>
                  <div className='col-md-3 mb-2 mb-md-0'>
                    <h4>Today Free Game</h4>
                  </div>
                  <div className='col d-flex align-items-center'>
                    <label htmlFor="weekEndDate" className="mr-2 mb-0 col-form-label">Week end date:</label>
                    <input type="date" id="weekEndDate" className='form-control' value={selectedDate} onChange={(e) => setSelectedDate(e.target.value)} />
                  </div>
                  <div className='col-md-3'>
                    <select className="form-control" onChange={(e) => setSelectedLotteryClub(e.target.value)} defaultValue={""}>
                      <option disabled value="">--Select Lottery Club--</option>
                      {lotteryClubList?.map((lc, index) => (
                        <option key={index} value={lc.lotteryId}>{lc.name}</option>
                      ))}
                    </select>
                  </div>
                  <div className='col-md-2'>
                    <button type='button' className="btn btn-info btn-sm float-md-right" onClick={prepareAddSfg}>Add New Result</button>
                  </div>
                </div>
                <div className="tab-content my-3" id="myTabContent">
                  <div className="tab-pane fade show active" id="first" role="tabpanel" aria-labelledby="first-tab">
                    <div className="table-responsive-md">
                      <table className="table table table-striped table-hover text-dark">
                        <thead >
                          <tr>
                            <th>Sr No</th>
                            <th>Start Date</th>
                            <th>End Date</th>
                            <th>Lottery Club</th>
                            <th>Lottery Club One</th>
                            <th>Lottery Club Two</th>
                            {/* <th><button type='button' className='btn btn-info btn-sm float-left'>Edit Data</button></th> */}

                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {tfgList?.map((data: any, index: number) => (
                            <tr key={index}>
                              <td className="align-middle">{(pageNum - 1) * itemsPerPage + index + 1}</td>
                              <td className="align-middle">{formatDate(data.weekStartDate)}</td>
                              <td className="align-middle">{formatDate(data.weekEndDate)}</td>
                              <td className="align-middle">{data.lotteryClub?.name}</td>
                              <td className="align-middle">{data.lotteryClubOne?.name}</td>
                              <td className="align-middle">{data.lotteryClubTwo?.name}</td>
                              {/* <td className="align-middle"><button type='button'  onClick ={()=>prepareEditTfg(data)} className='btn btn-info btn-sm '>Edit Data</button></td> */}


                              <td className="align-middle">
                                <button className="featured-box-icon bg-primary text-white rounded-circle border-0 edit-icon"
                                  onClick={() => prepareEditTfg(data)}>
                                  <i className="fas fa-edit"></i>
                                </button>
                                <button className="featured-box-icon bg-danger text-white rounded-circle border-0 edit-icon"
                                onClick={() => deleteTodayFreeGame(data.todayFreeGameId)} disabled={formSubmitLoader}>
                                {!formSubmitLoader && <i className="fas fa-trash"></i>}
                                {formSubmitLoader && <i className="fas fa-circle-notch fa-spin me-2"></i>}
                              </button>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                    <ReactPaginate breakLabel="..." nextLabel=">" onPageChange={handlePageClick} pageRangeDisplayed={5}
                      pageCount={pageCount} previousLabel="<" pageClassName="page-item" pageLinkClassName="page-link"
                      previousClassName="page-item" previousLinkClassName="page-link" nextClassName="page-item" nextLinkClassName="page-link"
                      breakClassName="page-item" breakLinkClassName="page-link" containerClassName="pagination" activeClassName="active"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div id="sfg-modal" className="modal fade" role="dialog" aria-hidden="true">
          <div className="modal-dialog modal-dialog-centered modal-shadow" role="document">
            <div className="modal-content border-0">
              <div className="modal-header">
                <h5 className="modal-title">Today Free Game</h5>
                <button type="button" className="btn-close" data-dismiss="modal" aria-label="Close"></button>
              </div>
              <form id="loginForm" method="post" onSubmit={handleSubmit(updateflag ? editSattakingJodi : addTfg)}>
                <div className="modal-body py-4 px-0">
                  <div className="row">
                    <div className="col-11 col-md-10 mx-auto">
                      {/* <input type="hidden" id="serviceId" {...register("serviceId", { required: true })}/> */}
                      {/* <div className="mb-3">
                      <label className="number" htmlFor="name">Satta Name<span className='star'>*</span></label>
                      <select className="form-control" id="name" {...register("name", { required: true })}>
                        <option disabled value="">--Select--</option>
                        <option value="TIME BAZAR">TIME BAZAR</option>
                        <option value="MILAN DAY">MILAN DAY</option>
                        <option value="RAJDHANI DAY">RAJDHANI DAY</option>
                        <option value="KALYAN">KALYAN</option>
                        <option value="MILAN NIGHT">MILAN NIGHT</option>
                        <option value="KALYAN NIGHT">KALYAN NIGHT</option>
                        <option value="RAJDHANI NIGHT">RAJDHANI NIGHT</option>
                        <option value="MAIN BAZAR">MAIN BAZAR</option>
                      </select>
                      {errors.name && <span className='text-danger'>Name is required </span>}
                    </div> */}
                      {/* <div className="mb-3">
                      <label className="number" htmlFor="date">Date<span className='star'>*</span></label>
                      <input type="date"  className="form-control" id="date" placeholder="Date"
                        {...register("date", { required: true })} />
                      {errors.date && <span className='text-danger'>Date is required </span>}
                    </div> */}
                      <div className="row">
                        <div className=" col-3 col-md-3 mx-auto mb-3">
                          <label className="number" htmlFor="open"><b>Week Start Date</b></label>
                          <input type="date" className="form-control" id="openTime" placeholder="Week Start Date"
                            {...register("weekStartDate", { required: false })} />
                          {errors.open && <span className='text-danger'>Open is required </span>}

                        </div>
                        <div className=" col-3 col-md-3 mx-auto mb-3">
                          <label className="number" htmlFor="open"><b>Week End Date</b></label>
                          <input type="date" className="form-control" id="openTime" placeholder="Week End Date"
                            {...register("weekEndDate", { required: false })} />
                          {errors.open && <span className='text-danger'>Open is required </span>}

                        </div>
                        <div className=" col-6 col-md-6 mx-auto mb-3">
                          <label className="number" htmlFor="name">Lottery Club<span className='star'>*</span></label>
                          <select className="form-control" id="name" defaultValue={""} {...register("lotteryId", { required: true })}>
                            <option disabled value="">--Select Lottery Club--</option>
                            {lotteryClubList?.map((lc, index) => (
                              <option key={index} value={lc.lotteryId}>{lc.name}</option>
                            ))}
                          </select>
                          {errors.lotteryId && <span className='text-danger'>Name is required </span>}
                        </div>
                      </div>
                      <div className="row">
                        <div className=" col-3 col-md-3 mx-auto mb-3">

                        </div>
                        <div className="col-4 col-md-4 mx-auto mb-3">
                          <label className="number" htmlFor="name">Lottery Club<span className='star'>*</span></label>
                          <select className="form-control" id="name" defaultValue={""} {...register("lotteryClubIdOne", { required: true })}>
                            <option disabled value="">--Select Lottery Club--</option>
                            {lotteryClubList?.map((lc, index) => (
                              <option key={index} value={lc.lotteryId}>{lc.name}</option>
                            ))}
                          </select>
                          {errors.lotteryId && <span className='text-danger'>Name is required </span>}
                        </div>
                        <div className=" col-4 col-md-4 mx-auto mb-3">
                          <label className="number" htmlFor="name">Lottery Club<span className='star'>*</span></label>
                          <select className="form-control" id="name" defaultValue={""} {...register("lotteryClubIdTwo", { required: true })}>
                            <option disabled value="">--Select Lottery Club--</option>
                            {lotteryClubList?.map((lc, index) => (
                              <option key={index} value={lc.lotteryId}>{lc.name}</option>
                            ))}
                          </select>
                          {errors.lotteryId && <span className='text-danger'>Name is required </span>}
                        </div>

                      </div>
                      <div className='row'>
                        <div className=" col-3 col-md-3 mx-auto mb-3">
                          <label className="number" htmlFor="open"><b>Day</b></label>
                          <div className="number">Monday</div>
                        </div>
                        <div className=" col-4 col-md-4 mx-auto mb-3">
                          <label className="number" htmlFor="open"><b> </b></label>
                          <textarea className="form-control" id="openTime" placeholder=" Monday Free Game"
                            {...register("mondayOne", { required: false })} />
                          {errors.open && <span className='text-danger'>Open is required </span>}
                        </div>
                        <div className=" col-4 col-md-4 mx-auto mb-3">
                          <label className="number" htmlFor="open"><b> </b></label>
                          <textarea className="form-control" id="openTime" placeholder="Monday Free Game"
                            {...register("mondayTwo", { required: false })} />
                          {errors.open && <span className='text-danger'>Open is required </span>}
                        </div>
                      </div>
                      <div className='row'>
                        <div className=" col-3 col-md-3 mx-auto mb-3">
                          <div className='number'>Passed</div>
                        </div>
                        <div className=" col-4 col-md-4 mx-auto mb-3">
                          <textarea className="form-control" id="openTime" placeholder=" Monday Matka Tips"
                            {...register("mondayOnePassed", { required: false })} />
                          {errors.open && <span className='text-danger'>Open is required </span>}
                        </div>
                        <div className=" col-4 col-md-4 mx-auto mb-3">
                          <textarea className="form-control" id="openTime" placeholder="Monday Matka Tips"
                            {...register("mondayTwoPassed", { required: false })} />
                          {errors.open && <span className='text-danger'>Open is required </span>}
                        </div>
                      </div>
                      <hr />
                      <div className='row'>
                        <div className=" col-3 col-md-3 mx-auto mb-3">
                          <label className="number" htmlFor="open"></label>
                          <div className="number">Tuesday</div>
                        </div>
                        <div className=" col-4 col-md-4 mx-auto mb-3">
                          <label className="number" htmlFor="open"></label>
                          <textarea className="form-control" id="openTime" placeholder="Tuesday Free Game"
                            {...register("tuesdayOne", { required: false })} />
                          {errors.open && <span className='text-danger'>Open is required </span>}
                        </div>
                        <div className=" col-4 col-md-4 mx-auto mb-3">
                          <label className="number" htmlFor="open"></label>
                          <textarea className="form-control" id="openTime" placeholder="Tuesday Free Game"
                            {...register("tuesdayTwo", { required: false })} />
                          {errors.open && <span className='text-danger'>Open is required </span>}
                        </div>
                      </div>
                      <div className='row'>
                        <div className=" col-3 col-md-3 mx-auto mb-3">
                          <div className='number'>Passed</div>
                        </div>
                        <div className=" col-4 col-md-4 mx-auto mb-3">
                          <textarea className="form-control" id="openTime" placeholder="Tuesady Matka Tips"
                            {...register("tuesdayOnePassed", { required: false })} />
                          {errors.open && <span className='text-danger'>Open is required </span>}
                        </div>
                        <div className=" col-4 col-md-4 mx-auto mb-3">
                          <textarea className="form-control" id="openTime" placeholder="Tuesday Matka Tips"
                            {...register("tuesdayTwoPassed", { required: false })} />
                          {errors.open && <span className='text-danger'>Open is required </span>}
                        </div>
                      </div>
                      <hr />
                      <div className='row'>
                        <div className=" col-3 col-md-3 mx-auto mb-3">
                          <label className="number" htmlFor="open"></label>
                          <div className="number">Wednesday</div>
                        </div>
                        <div className=" col-4 col-md-4 mx-auto mb-3">
                          <label className="number" htmlFor="open"></label>
                          <textarea className="form-control" id="openTime" placeholder="Wednesday Free Game"
                            {...register("wednesdayOne", { required: false })} />
                          {errors.open && <span className='text-danger'>Open is required </span>}
                        </div>
                        <div className=" col-4 col-md-4 mx-auto mb-3">
                          <label className="number" htmlFor="open"></label>
                          <textarea className="form-control" id="openTime" placeholder="Wednesday Free Game"
                            {...register("wednesdayTwo", { required: false })} />
                          {errors.open && <span className='text-danger'>Open is required </span>}
                        </div>
                      </div>
                      <div className='row'>
                        <div className=" col-3 col-md-3 mx-auto mb-3">
                          <div className='number'>Passed</div>
                        </div>
                        <div className=" col-4 col-md-4 mx-auto mb-3">
                          <textarea className="form-control" id="openTime" placeholder="Wednesday Matka Tips"
                            {...register("wednesdayOnePassed", { required: false })} />
                          {errors.open && <span className='text-danger'>Open is required </span>}
                        </div>
                        <div className=" col-4 col-md-4 mx-auto mb-3">
                          <textarea className="form-control" id="openTime" placeholder="Wednesday Matka Tips"
                            {...register("wednesdayTwoPassed", { required: false })} />
                          {errors.open && <span className='text-danger'>Open is required </span>}
                        </div>
                      </div>
                      <hr />
                      <div className='row'>
                        <div className=" col-3 col-md-3 mx-auto mb-3">
                          <label className="number" htmlFor="open"></label>
                          <div className="number">Thursday</div>
                        </div>
                        <div className=" col-4 col-md-4 mx-auto mb-3">
                          <label className="number" htmlFor="open"></label>
                          <textarea className="form-control" id="openTime" placeholder="Thursday Free Game"
                            {...register("thursdayOne", { required: false })} />
                          {errors.open && <span className='text-danger'>Open is required </span>}
                        </div>
                        <div className=" col-4 col-md-4 mx-auto mb-3">
                          <label className="number" htmlFor="open"></label>
                          <textarea className="form-control" id="openTime" placeholder="Thursday Free Game"
                            {...register("thursdayTwo", { required: false })} />
                          {errors.open && <span className='text-danger'>Open is required </span>}
                        </div>
                      </div>
                      <div className='row'>
                        <div className=" col-3 col-md-3 mx-auto mb-3">
                          <div className='number'>Passed</div>
                        </div>
                        <div className=" col-4 col-md-4 mx-auto mb-3">
                          <textarea className="form-control" id="openTime" placeholder="Thursday Matka Tips"
                            {...register("thursdayOnePassed", { required: false })} />
                          {errors.open && <span className='text-danger'>Open is required </span>}
                        </div>
                        <div className=" col-4 col-md-4 mx-auto mb-3">
                          <textarea className="form-control" id="openTime" placeholder="Thursday Matka Tips"
                            {...register("thursdayTwoPassed", { required: false })} />
                          {errors.open && <span className='text-danger'>Open is required </span>}
                        </div>
                      </div>
                      <hr />
                      <div className='row'>
                        <div className=" col-3 col-md-3 mx-auto mb-3">
                          <label className="number" htmlFor="open"></label>
                          <div className="number">Friday</div>
                        </div>
                        <div className=" col-4 col-md-4 mx-auto mb-3">
                          <label className="number" htmlFor="open"></label>
                          <textarea className="form-control" id="openTime" placeholder=" Friday Free Game"
                            {...register("fridayOne", { required: false })} />
                          {errors.open && <span className='text-danger'>Open is required </span>}
                        </div>
                        <div className=" col-4 col-md-4 mx-auto mb-3">
                          <label className="number" htmlFor="open"></label>
                          <textarea className="form-control" id="openTime" placeholder="Friday Free Game"
                            {...register("fridayTwo", { required: false })} />
                          {errors.open && <span className='text-danger'>Open is required </span>}
                        </div>
                      </div>
                      <div className='row'>
                        <div className=" col-3 col-md-3 mx-auto mb-3">
                          <div className='number'>Passed</div>
                        </div>
                        <div className=" col-4 col-md-4 mx-auto mb-3">
                          <textarea className="form-control" id="openTime" placeholder="Friday Matka Tips"
                            {...register("fridayOnePassed", { required: false })} />
                          {errors.open && <span className='text-danger'>Open is required </span>}
                        </div>
                        <div className=" col-4 col-md-4 mx-auto mb-3">
                          <textarea className="form-control" id="openTime" placeholder="Friday Matka Tips"
                            {...register("fridayTwoPassed", { required: false })} />
                          {errors.open && <span className='text-danger'>Open is required </span>}
                        </div>
                      </div>
                      <hr />
                      <div className='row'>
                        <div className=" col-3 col-md-3 mx-auto mb-3">
                          <label className="number" htmlFor="open"></label>
                          <div className="number">Satday</div>
                        </div>
                        <div className=" col-4 col-md-4 mx-auto mb-3">
                          <label className="number" htmlFor="open"></label>
                          <textarea className="form-control" id="openTime" placeholder="Saturday Free Game"
                            {...register("saturdayOne", { required: false })} />
                          {errors.open && <span className='text-danger'>Open is required </span>}
                        </div>
                        <div className=" col-4 col-md-4 mx-auto mb-3">
                          <label className="number" htmlFor="open"></label>
                          <textarea className="form-control" id="openTime" placeholder="Saturday Free Game"
                            {...register("saturdayTwo", { required: false })} />
                          {errors.open && <span className='text-danger'>Open is required </span>}
                        </div>
                      </div>
                      <div className='row'>
                        <div className=" col-3 col-md-3 mx-auto mb-3">
                          <div className='number'>Passed</div>
                        </div>
                        <div className=" col-4 col-md-4 mx-auto mb-3">
                          <textarea className="form-control" id="openTime" placeholder="Saturday Matka Tips"
                            {...register("saturdayOnePassed", { required: false })} />
                          {errors.open && <span className='text-danger'>Open is required </span>}
                        </div>
                        <div className=" col-4 col-md-4 mx-auto mb-3">
                          <textarea className="form-control" id="openTime" placeholder="Saturday Matka Tips"
                            {...register("saturdayTwoPassed", { required: false })} />
                          {errors.open && <span className='text-danger'>Open is required </span>}
                        </div>
                      </div>
                      <hr />
                      <div className='row'>
                        <div className=" col-3 col-md-3 mx-auto mb-3">
                          <label className="number" htmlFor="open"></label>
                          <div className="number">Sunday</div>
                        </div>
                        <div className=" col-4 col-md-4 mx-auto mb-3">
                          <label className="number" htmlFor="open"></label>
                          <textarea className="form-control" id="openTime" placeholder="sunday Free Game "
                            {...register("sundayOne", { required: false })} />
                          {errors.open && <span className='text-danger'>Open is required </span>}
                        </div>
                        <div className=" col-4 col-md-4 mx-auto mb-3">
                          <label className="number" htmlFor="open"></label>
                          <textarea className="form-control" id="openTime" placeholder="Sunday Free Game"
                            {...register("sundayTwo", { required: false })} />
                          {errors.open && <span className='text-danger'>Open is required </span>}
                        </div>
                      </div>
                      <div className='row'>
                        <div className=" col-3 col-md-3 mx-auto mb-3">
                          <div className='number'>Passed</div>
                        </div>
                        <div className=" col-4 col-md-4 mx-auto mb-3">
                          <textarea className="form-control" id="openTime" placeholder="Sunday Matka Tips"
                            {...register("sundayOnePassed", { required: false })} />
                          {errors.open && <span className='text-danger'>Open is required </span>}
                        </div>
                        <div className=" col-4 col-md-4 mx-auto mb-3">
                          <textarea className="form-control" id="openTime" placeholder="Sunday Matka Tips"
                            {...register("sundayTwoPassed", { required: false })} />
                          {errors.open && <span className='text-danger'>Open is required </span>}
                        </div>
                      </div>


                    </div>
                  </div>
                </div>
                <div className="modal-footer">
                  <button type="button" className="btn btn-danger btn-sm" data-dismiss="modal">Close</button>
                  <button type="submit" className="btn btn-primary btn-sm" disabled={formSubmitLoader}>
                    {formSubmitLoader && <i className="fas fa-circle-notch fa-spin me-2"></i>}
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default TodayFreeGame