import React, { useEffect } from 'react';
import './App.css';
import { BrowserRouter } from "react-router-dom";
import Routing from './Routing/Routing';
import Footer from './Component/Footer/Footer';
import * as constants from './Constants/Constants'

function App() {

  useEffect(() => {
    constants.loginSubscriber.subscribe((status: boolean) => {
      startSessionLog();
    });
  }, []);

  const startSessionLog = () =>{
    setInterval(() => {
      if(sessionStorage.getItem('currentUser')){
        fetch(constants.BASE_URL + 'user/session', {
          method: 'PUT',
          headers: { 'Content-Type': 'application/json', 'Accept': 'application/json', 'Authorization': constants.getAuthHeader() }
        }).then(function (response) {
          return response.json();
        }).then(function (response: any) {
          if (response.status == '200') {
            console.log('session updated...')
          }
        });
      }
    }, 10000);
  }

  return (
    <div className="App">
      <BrowserRouter>
        <Routing />
        <Footer />
      </BrowserRouter>
    </div>
  );
}

export default App;
