import React, { useEffect, useState } from 'react'
import ProfileSidebar from '../../ProfileSidebar/ProfileSidebar'
import * as constants from '../../../Constants/Constants'
import { useForm } from 'react-hook-form';
import ReactPaginate from 'react-paginate';

declare var $: any;
function AdminSattaKingJodi() {
  const { register, handleSubmit, setValue, formState: { errors }, reset } = useForm();
  const [formSubmitLoader, setFormSubmitLoader] = useState<boolean>(false);
  const [lotteryClubList, setLotteryClubList] = useState<any[]>();
  const [sfgList, setSfgList] = useState<any[]>();
  const [currentDate, setCurrentDate] = useState<string>(new Date().toISOString().split("T")[0]);
  const [sjkId, setsjkId] = useState<any[]>();
  const [updateflag, setUpdateflag] = useState<boolean>(false);

  const [pageCount, setPageCount] = useState(0);
  const [pageNum, setPageNum] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(20);

  useEffect(() => {
    getLotteryClubList();
  }, []);

  useEffect(() => {
    getSkjList();
  }, [pageNum]);

  const getLotteryClubList = () => {
    fetch(constants.BASE_URL + 'lottery-club/dropdown', {
      method: 'GET',
      headers: { 'Content-Type': 'application/json', 'Accept': 'application/json', 'Authorization': constants.getAuthHeader() },
    }).then(function (response) {
      return response.json();
    }).then(function (response: any) {
      if (response.status == '200') {
        setLotteryClubList(response.result);
      }
    });
  }

  const getSkjList = () => {
    let queryParam = '?page=' + (pageNum - 1) + '&size=' + itemsPerPage;
    fetch(constants.BASE_URL + 'satta-king-jodi' + queryParam, {
      method: 'GET',
      headers: { 'Content-Type': 'application/json', 'Accept': 'application/json', 'Authorization': constants.getAuthHeader() },
    }).then(function (response) {
      return response.json();
    }).then(function (response: any) {
      if (response.status == '200') {
        setSfgList(response.result);
        setPageCount(Math.ceil(response.listCount / itemsPerPage));
      }
    });
  }

  const prepareAddSkj = () => {
    reset();
    $('#sfg-modal').modal('show');
  }

  const handlePageClick = (event: any) => {
    setPageNum(event.selected + 1);
  };

  const addSkj = (data: any) => {
    if (data.mondayDesawar) {
      data.mondayDesawar = data.mondayDesawar?.replaceAll('\n', '<br>');
    }
    if (data.mondayGali) {
      data.mondayGali = data.mondayGali?.replaceAll('\n', '<br>');
    }
    if (data.tuesdayDesawar) {
      data.tuesdayDesawar = data.tuesdayDesawar?.replaceAll('\n', '<br>');
    }
    if (data.tuesdayGali) {
      data.tuesdayGali = data.tuesdayGali?.replaceAll('\n', '<br>');
    }
    if (data.wednesdayDesawar) {
      data.wednesdayDesawar = data.wednesdayDesawar?.replaceAll('\n', '<br>');
    }
    if (data.wednesdayGali) {
      data.wednesdayGali = data.wednesdayGali?.replaceAll('\n', '<br>');
    }
    if (data.thursdayDesawar) {
      data.thursdayDesawar = data.thursdayDesawar?.replaceAll('\n', '<br>');
    }
    if (data.thursdayGali) {
      data.thursdayGali = data.thursdayGali?.replaceAll('\n', '<br>');
    }
    if (data.saturdayDesawar) {
      data.saturdayDesawar = data.saturdayDesawar?.replaceAll('\n', '<br>');
    }
    if (data.saturdayGali) {
      data.saturdayGali = data.saturdayGali?.replaceAll('\n', '<br>');
    }
    if (data.fridayDesawar) {
      data.fridayDesawar = data.fridayDesawar?.replaceAll('\n', '<br>');
    }
    if (data.fridayGali) {
      data.fridayGali = data.fridayGali?.replaceAll('\n', '<br>');
    }
    if (data.sundayDesawar) {
      data.sundayDesawar = data.sundayDesawar?.replaceAll('\n', '<br>');
    }
    if (data.sundayGali) {
      data.sundayGali = data.sundayGali?.replaceAll('\n', '<br>');
    }
    if (!data.lotteryClubOne) {
      data.lotteryClubOne = {};
    }
    if (!data.lotteryClubTwo) {
      data.lotteryClubTwo = {};
    }
    data.lotteryClubOne.lotteryId = data.lotteryClubIdOne;
    data.lotteryClubTwo.lotteryId = data.lotteryClubIdTwo;
  
    setFormSubmitLoader(true);
    fetch(constants.BASE_URL + 'satta-king-jodi', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json', 'Accept': 'application/json', 'Authorization': constants.getAuthHeader() },
      body: JSON.stringify(data)
    }).then(function (response) {
      return response.json();
    }).then(function (response: any) {
      setFormSubmitLoader(false);
      if (response.status == '201') {
        reset();
        $('#sfg-modal').modal('hide');
        constants.showSuccess('Request processed successfully');
        getSkjList();
      } else {
        constants.showError(response.message);
      }
    });
  }

  const prepareEditSjk = (data: any) => {
    setUpdateflag(true)
    $('#sfg-modal').modal('show');
    setsjkId(data.sattaKingJodiId)
    setTimeout(() => {
      setValue('sattaKingJodiId', data.sattaKingJodiId, { shouldDirty: true });
      setValue('weekStartDate', data.weekStartDate, { shouldDirty: true });
      setValue('weekEndDate', data.weekEndDate, { shouldDirty: true });
      setValue('fridayDesawar', data?.fridayDesawar.replaceAll('<br>', '\n'));
      setValue('fridayDesawarPassed', data.fridayDesawarPassed, { shouldDirty: true });
      setValue('fridayGali', data?.fridayGali.replaceAll('<br>', '\n'));
      setValue('fridayGaliPassed', data.fridayGaliPassed, { shouldDirty: true });
      setValue('mondayDesawar', data?.mondayDesawar.replaceAll('<br>', '\n'), { shouldDirty: true });
      setValue('mondayDesawarPassed', data.mondayDesawarPassed, { shouldDirty: true });
      setValue('mondayGali', data?.mondayGali.replaceAll('<br>', '\n'), { shouldDirty: true });
      setValue('mondayGaliPassed', data.mondayGaliPassed, { shouldDirty: true });
      setValue('saturdayDesawar', data?.saturdayDesawar.replaceAll('<br>', '\n'), { shouldDirty: true });
      setValue('saturdayDesawarPassed', data.saturdayDesawarPassed, { shouldDirty: true });
      setValue('saturdayGali', data?.saturdayGali.replaceAll('<br>', '\n'), { shouldDirty: true });
      setValue('saturdayGaliPassed', data.saturdayGaliPassed, { shouldDirty: true });

      setValue('sundayDesawar', data?.sundayDesawar.replaceAll('<br>', '\n'), { shouldDirty: true });
      setValue('sundayDesawarPassed', data.sundayDesawarPassed, { shouldDirty: true });
      setValue('sundayGali', data?.sundayGali.replaceAll('<br>', '\n'), { shouldDirty: true });
      setValue('sundayGaliPassed', data.sundayGaliPassed, { shouldDirty: true });
      setValue('thursdayDesawar', data?.thursdayDesawar.replaceAll('<br>', '\n'), { shouldDirty: true });
      setValue('thursdayDesawarPassed', data.thursdayDesawarPassed, { shouldDirty: true });
      setValue('thursdayGali', data?.thursdayGali.replaceAll('<br>', '\n'), { shouldDirty: true });
      setValue('thursdayGaliPassed', data.thursdayGaliPassed, { shouldDirty: true });
      setValue('tuesdayDesawar', data?.tuesdayDesawar.replaceAll('<br>', '\n'), { shouldDirty: true });
      setValue('tuesdayDesawarPassed', data.tuesdayDesawarPassed, { shouldDirty: true });
      setValue('tuesdayGali', data?.tuesdayGali.replaceAll('<br>', '\n'), { shouldDirty: true });
      setValue('tuesdayGaliPassed', data.tuesdayGaliPassed, { shouldDirty: true });
      setValue('wednesdayDesawar', data?.wednesdayDesawar.replaceAll('<br>', '\n'), { shouldDirty: true });
      setValue('wednesdayDesawarPassed', data.wednesdayDesawarPassed, { shouldDirty: true });
      setValue('wednesdayGali', data?.wednesdayGali.replaceAll('<br>', '\n'), { shouldDirty: true });
      setValue('wednesdayGaliPassed', data.wednesdayGaliPassed, { shouldDirty: true });
      setValue('lotteryClubIdOne', data.lotteryClubOne.lotteryId, { shouldDirty: true });
      setValue('lotteryClubIdTwo', data.lotteryClubTwo.lotteryId, { shouldDirty: true });
    }, 500);

  }


  // put api in satta king jodi
  const editSattakingJodi = (data: any) => {
    if (data.mondayDesawar) {
      data.mondayDesawar = data.mondayDesawar?.replaceAll('\n', '<br>');
    }
    if (data.mondayGali) {
      data.mondayGali = data.mondayGali?.replaceAll('\n', '<br>');
    }
    if (data.tuesdayDesawar) {
      data.tuesdayDesawar = data.tuesdayDesawar?.replaceAll('\n', '<br>');
    }
    if (data.tuesdayGali) {
      data.tuesdayGali = data.tuesdayGali?.replaceAll('\n', '<br>');
    }
    if (data.wednesdayDesawar) {
      data.wednesdayDesawar = data.wednesdayDesawar?.replaceAll('\n', '<br>');
    }
    if (data.wednesdayGali) {
      data.wednesdayGali = data.wednesdayGali?.replaceAll('\n', '<br>');
    }
    if (data.thursdayDesawar) {
      data.thursdayDesawar = data.thursdayDesawar?.replaceAll('\n', '<br>');
    }
    if (data.thursdayGali) {
      data.thursdayGali = data.thursdayGali?.replaceAll('\n', '<br>');
    }
    if (data.saturdayDesawar) {
      data.saturdayDesawar = data.saturdayDesawar?.replaceAll('\n', '<br>');
    }
    if (data.saturdayGali) {
      data.saturdayGali = data.saturdayGali?.replaceAll('\n', '<br>');
    }
    if (data.fridayDesawar) {
      data.fridayDesawar = data.fridayDesawar?.replaceAll('\n', '<br>');
    }
    if (data.fridayGali) {
      data.fridayGali = data.fridayGali?.replaceAll('\n', '<br>');
    }
    if (data.sundayDesawar) {
      data.sundayDesawar = data.sundayDesawar?.replaceAll('\n', '<br>');
    }
    if (data.sundayGali) {
      data.sundayGali = data.sundayGali?.replaceAll('\n', '<br>');
    }

    setFormSubmitLoader(true);
    if (data.mondayDesawar) {
      data.mondayDesawar = data.mondayDesawar?.replaceAll('\n', '<br>');
    }
    if (data.mondayGali) {
      data.mondayGali = data.mondayGali?.replaceAll('\n', '<br>');
    }
    if (data.tuesdayDesawar) {
      data.tuesdayDesawar = data.tuesdayDesawar?.replaceAll('\n', '<br>');
    }
    if (data.tuesdayGali) {
      data.tuesdayGali = data.tuesdayGali?.replaceAll('\n', '<br>');
    }
    if (data.wednesDesawar) {
      data.wednesDesawar = data.wednesDesawar?.replaceAll('\n', '<br>');
    }
    if (data.wednesGali) {
      data.wednesGali = data.wednesGali?.replaceAll('\n', '<br>');
    }
    if (data.thursdayDesawar) {
      data.thursdayDesawar = data.thursdayDesawar?.replaceAll('\n', '<br>');
    }
    if (data.thursdayGali) {
      data.thursdayGali = data.thursdayGali?.replaceAll('\n', '<br>');
    }
    if (data.saturdayDesawar) {
      data.saturdayDesawar = data.saturdayDesawar?.replaceAll('\n', '<br>');
    }
    if (data.saturdayGali) {
      data.saturdayGali = data.saturdayGali?.replaceAll('\n', '<br>');
    }
    if (data.fridayDesawar) {
      data.fridayDesawar = data.fridayDesawar?.replaceAll('\n', '<br>');
    }
    if (data.fridayGali) {
      data.fridayGali = data.fridayGali?.replaceAll('\n', '<br>');
    }
    if (data.sundayDesawar) {
      data.sundayDesawar = data.sundayDesawar?.replaceAll('\n', '<br>');
    }
    if (data.sundayGali) {
      data.sundayGali = data.sundayGali?.replaceAll('\n', '<br>');
    }
    if (!data.lotteryClubOne) {
      data.lotteryClubOne = {};
    }
    if (!data.lotteryClubTwo) {
      data.lotteryClubTwo = {};
    }
    data.lotteryClubOne.lotteryId = data.lotteryClubIdOne;
    data.lotteryClubTwo.lotteryId = data.lotteryClubIdTwo;

    fetch(constants.BASE_URL + 'satta-king-jodi/' + sjkId, {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json', 'Accept': 'application/json', 'Authorization': constants.getAuthHeader() },
      body: JSON.stringify(data)
    }).then(function (response) {
      return response.json();
    }).then(function (response: any) {
      setFormSubmitLoader(false);
      if (response.status == '200') {
        $('#sfg-modal').modal('hide');
        constants.showSuccess('Request processed successfully');
        getSkjList();
      } else {
        constants.showError(response.message);
      }
    });
  }
  // function to change date format
  function formatDate(input: any) {
    if (input != undefined) {
      let datePart = input.match(/\d+/g),
        year = datePart[0],
        // get only two digits 
        month = datePart[1],
        day = datePart[2];
      return day + '-' + month + '-' + year;
    }
  }
  console.log(sfgList, "sattakingjodidata")

  const deleteSattaKingJodi = (sattaKingJodiId: string) => {
    if (window.confirm('Are you sure, you want to delete this record?')) {
      setFormSubmitLoader(true);
      fetch(constants.BASE_URL + 'satta-king-jodi/' + sattaKingJodiId, {
        method: 'DELETE',
        headers: { 'Content-Type': 'application/json', 'Accept': 'application/json', 'Authorization': constants.getAuthHeader() },
      }).then(function (response) {
        return response.json();
      }).then(function (response: any) {
        setFormSubmitLoader(false);
        if (response.status == '200') {
          getSkjList();
          constants.showSuccess(response.message);
        } else {
          constants.showError(response.message);
        }
      });
    }
  }
  return (
    <>
      <div className='page-content'>
        <section className="page-header page-header-dark bg-secondary">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-md-8">
                <h2 className='admin-heading mb-0 text-white'>ADMIN PANEL</h2>
              </div>
              <div className="col-md-4">

              </div>
            </div>
          </div>
        </section>
        <div className="container-fluid p-5 mb-2">
          <div className="row">
            <div className="col-lg-2">
              <ProfileSidebar />
            </div>
            <div className="col-lg-10">
              <div className="bg-white shadow-md rounded p-4">
                <div className='row'>
                  <div className='col-4 col-md-4'>
                    <h4 className="mb-2 text-left">SattaKing Jodi</h4>
                  </div>
                  {/* <div className='col-lg-4'>
                  <input type="text" placeholder='Search by service name' className='form-control form-control-sm w-50 float-end'
                    onChange={searchByName} />
                </div> */}
                  <div className='col-4 col-md-4'>
                  </div>
                  <div className='col-4 col-md-4'>
                    <button type='button' className="btn btn-info btn-sm float-right" onClick={prepareAddSkj}>Add New Result</button>
                  </div>
                </div>
                <div className="tab-content my-3" id="myTabContent">
                  <div className="tab-pane fade show active" id="first" role="tabpanel" aria-labelledby="first-tab">
                    <div className="table-responsive-md">
                      <table className="table table table-striped table-hover text-dark">
                        <thead >
                          <tr>
                            <th>Sr No</th>
                            <th>Start Date</th>
                            <th>End Date</th>
                            <th>Lottery Club (Galii Satta)</th>
                            <th>Lottery Club (Desawar Satta)</th>
                            <th>Action</th>

                            {/* <th>Action</th> */}
                          </tr>
                        </thead>
                        <tbody>
                          {sfgList?.map((data: any, index: number) => (
                            <tr key={index}>
                              <td className="align-middle">{(pageNum - 1) * itemsPerPage + index + 1}</td>
                              <td className="align-middle">{formatDate(data.weekStartDate)}</td>
                              <td className="align-middle">{formatDate(data.weekEndDate)}</td>
                              <td className="align-middle">{data?.lotteryClubOne.name}</td>
                              <td className="align-middle">{data?.lotteryClubTwo.name}</td>
                              {/* <td className="align-middle"><button type='button'  onClick ={()=>prepareEditSjk(data)} className='btn btn-info btn-sm '><i className="fas fa-edit"></i></button></td> */}

                              <td className="align-middle">
                                <button className="featured-box-icon bg-primary text-white rounded-circle border-0 edit-icon"
                                  onClick={() => prepareEditSjk(data)}>
                                  <i className="fas fa-edit"></i>
                                </button>
                               
                            <button className="featured-box-icon bg-danger text-white rounded-circle border-0 edit-icon"
                                onClick={() => deleteSattaKingJodi(data.sattaKingJodiId)} disabled={formSubmitLoader}>
                                {!formSubmitLoader && <i className="fas fa-trash"></i>}
                                {formSubmitLoader && <i className="fas fa-circle-notch fa-spin me-2"></i>}
                              </button>
                   
                              </td>
                            </tr>

                          ))}
                        </tbody>
                      </table>
                    </div>
                    <ReactPaginate breakLabel="..." nextLabel=">" onPageChange={handlePageClick} pageRangeDisplayed={5}
                      pageCount={pageCount} previousLabel="<" pageClassName="page-item" pageLinkClassName="page-link"
                      previousClassName="page-item" previousLinkClassName="page-link" nextClassName="page-item" nextLinkClassName="page-link"
                      breakClassName="page-item" breakLinkClassName="page-link" containerClassName="pagination" activeClassName="active"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div id="sfg-modal" className="modal fade" role="dialog" aria-hidden="true">
          <div className="modal-dialog modal-dialog-centered modal-shadow" role="document">
            <div className="modal-content border-0">
              <div className="modal-header">
                <h5 className="modal-title">Satta King Jodi</h5>
                <button type="button" className="btn-close" data-dismiss="modal" aria-label="Close"></button>
              </div>
              <form id="loginForm" method="post" onSubmit={handleSubmit(updateflag ? editSattakingJodi : addSkj)}>
                <div className="modal-body py-4 px-0">
                  <div className="row">
                    <div className="col-11 col-md-10 mx-auto">
                      {/* <input type="hidden" id="serviceId" {...register("serviceId", { required: true })}/> */}
                      {/* <div className="mb-3">
                      <label className="number" htmlFor="name">Satta Name<span className='star'>*</span></label>
                      <select className="form-control" id="name" {...register("name", { required: true })}>
                        <option disabled value="">--Select--</option>
                        <option value="TIME BAZAR">TIME BAZAR</option>
                        <option value="MILAN DAY">MILAN DAY</option>
                        <option value="RAJDHANI DAY">RAJDHANI DAY</option>
                        <option value="KALYAN">KALYAN</option>
                        <option value="MILAN NIGHT">MILAN NIGHT</option>
                        <option value="KALYAN NIGHT">KALYAN NIGHT</option>
                        <option value="RAJDHANI NIGHT">RAJDHANI NIGHT</option>
                        <option value="MAIN BAZAR">MAIN BAZAR</option>
                      </select>
                      {errors.name && <span className='text-danger'>Name is required </span>}
                    </div> */}
                      {/* <div className="mb-3">
                      <label className="number" htmlFor="date">Date<span className='star'>*</span></label>
                      <input type="date"  className="form-control" id="date" placeholder="Date"
                        {...register("date", { required: true })} />
                      {errors.date && <span className='text-danger'>Date is required </span>}
                    </div> */}
                      <div className="row">
                        <div className=" col-4 col-md-4 mx-auto mb-3">
                          <label className="number" htmlFor="open"><b>Week Start Date</b></label>
                          <input type="date" className="form-control" id="openTime" placeholder="Week Start Date"
                            {...register("weekStartDate", { required: false })} />
                          {errors.open && <span className='text-danger'>Open is required </span>}
                          <div className="number">Monday</div>
                        </div>
                        <div className=" col-4 col-md-4 mx-auto mb-3">
                          <label className="number" htmlFor="open"><b>Week End Date</b></label>
                          <input type="date" className="form-control" id="openTime" placeholder="Week End Date"
                            {...register("weekEndDate", { required: false })} />
                          {errors.open && <span className='text-danger'>Open is required </span>}
                          <div className="number">Monday</div>
                        </div>
                        <div className=" col-4 col-md-4 mx-auto mb-3">

                        </div>
                      </div>
                      <div className="row">
                        <div className=" col-3 col-md-3 mx-auto mb-3">

                        </div>
                        <div className="col-4 col-md-4 mx-auto mb-3">
                          <label className="number" htmlFor="name">Lottery Club<span className='star'>*</span></label>
                          <select className="form-control" id="name" defaultValue={""} {...register("lotteryClubIdOne", { required: true })}>
                            <option disabled value="">--Select Lottery Club--</option>
                            {lotteryClubList?.map((lc, index) => (
                              <option key={index} value={lc.lotteryId}>{lc.name}</option>
                            ))}
                          </select>
                          {errors.lotteryId && <span className='text-danger'>Name is required </span>}
                        </div>
                        <div className=" col-4 col-md-4 mx-auto mb-3">
                          <label className="number" htmlFor="name">Lottery Club<span className='star'>*</span></label>
                          <select className="form-control" id="name" defaultValue={""} {...register("lotteryClubIdTwo", { required: true })}>
                            <option disabled value="">--Select Lottery Club--</option>
                            {lotteryClubList?.map((lc, index) => (
                              <option key={index} value={lc.lotteryId}>{lc.name}</option>
                            ))}
                          </select>
                          {errors.lotteryId && <span className='text-danger'>Name is required </span>}
                        </div>

                      </div>
                      <div className='row'>
                        <div className=" col-3 col-md-3 mx-auto mb-3">
                          <label className="number" htmlFor="open"><b>Day</b></label>
                          <div className="number">Monday</div>
                        </div>
                        <div className=" col-4 col-md-4 mx-auto mb-3">
                          <label className="number" htmlFor="open"><b>Galii Satta</b></label>
                          <textarea cols={40} rows={2} className="form-control" id="openTime" placeholder="Galli Monday"
                            {...register("mondayGali", { required: false })} ></textarea>
                          {errors.open && <span className='text-danger'>Open is required </span>}
                        </div>
                        <div className=" col-4 col-md-4 mx-auto mb-3">
                          <label className="number" htmlFor="open"><b>Desawar Satta</b></label>
                          <textarea rows={2} cols={40} className="form-control" id="openTime" placeholder="Desawar  Monday"
                            {...register("mondayDesawar", { required: false })} />
                          {errors.open && <span className='text-danger'>Open is required </span>}
                        </div>
                      </div>
                      <div className='row'>
                        <div className=" col-3 col-md-3 mx-auto mb-3">
                          <div className='number'>Passed</div>
                        </div>
                        <div className=" col-4 col-md-4 mx-auto mb-3">
                          <textarea className="form-control" id="openTime" placeholder="Monday Pass"
                            {...register("mondayGaliPassed", { required: false })} />
                          {errors.open && <span className='text-danger'>Open is required </span>}
                        </div>
                        <div className=" col-4 col-md-4 mx-auto mb-3">
                          <textarea className="form-control" id="openTime" placeholder=" Monday Pass"
                            {...register("mondayDesawarPassed", { required: false })} />
                          {errors.open && <span className='text-danger'>Open is required </span>}
                        </div>
                      </div>
                      <hr />
                      <div className='row'>
                        <div className=" col-3 col-md-3 mx-auto mb-3">
                          <label className="number" htmlFor="open"></label>
                          <div className="number">Tuesday</div>
                        </div>
                        <div className=" col-4 col-md-4 mx-auto mb-3">
                          <label className="number" htmlFor="open">Galii Satta</label>
                          <textarea className="form-control" id="openTime" placeholder="Galli Tuesday"
                            {...register("tuesdayGali", { required: false })} />
                          {errors.open && <span className='text-danger'>Open is required </span>}
                        </div>
                        <div className=" col-4 col-md-4 mx-auto mb-3">
                          <label className="number" htmlFor="open">Desawar Satta</label>
                          <textarea className="form-control" id="openTime" placeholder="Desawar Tuesday"
                            {...register("tuesdayDesawar", { required: false })} />
                          {errors.open && <span className='text-danger'>Open is required </span>}
                        </div>
                      </div>
                      <div className='row'>
                        <div className=" col-3 col-md-3 mx-auto mb-3">
                          <div className='number'>Passed</div>
                        </div>
                        <div className=" col-4 col-md-4 mx-auto mb-3">
                          <textarea className="form-control" id="openTime" placeholder="Tuesady Pass"
                            {...register("tuesdayGaliPassed", { required: false })} />
                          {errors.open && <span className='text-danger'>Open is required </span>}
                        </div>
                        <div className=" col-4 col-md-4 mx-auto mb-3">
                          <textarea className="form-control" id="openTime" placeholder="Tuesday Pass"
                            {...register("tuesdayDesawarPassed", { required: false })} />
                          {errors.open && <span className='text-danger'>Open is required </span>}
                        </div>
                      </div>
                      <hr />
                      <div className='row'>
                        <div className=" col-3 col-md-3 mx-auto mb-3">
                          <label className="number" htmlFor="open"></label>
                          <div className="number">Wednesday</div>
                        </div>
                        <div className=" col-4 col-md-4 mx-auto mb-3">
                          <label className="number" htmlFor="open">Galii Satta</label>
                          <textarea className="form-control" id="openTime" placeholder="Galli Wednesday"
                            {...register("wednesdayGali", { required: false })} />
                          {errors.open && <span className='text-danger'>Open is required </span>}
                        </div>
                        <div className=" col-4 col-md-4 mx-auto mb-3">
                          <label className="number" htmlFor="open">Desawar Satta</label>
                          <textarea className="form-control" id="openTime" placeholder="Desawar Wednesday"
                            {...register("wednesdayDesawar", { required: false })} />
                          {errors.open && <span className='text-danger'>Open is required </span>}
                        </div>
                      </div>
                      <div className='row'>
                        <div className=" col-3 col-md-3 mx-auto mb-3">
                          <div className='number'>Passed</div>
                        </div>
                        <div className=" col-4 col-md-4 mx-auto mb-3">
                          <textarea className="form-control" id="openTime" placeholder="Wednesday Pass"
                            {...register("wednesdayGaliPassed", { required: false })} />
                          {errors.open && <span className='text-danger'>Open is required </span>}
                        </div>
                        <div className=" col-4 col-md-4 mx-auto mb-3">
                          <textarea className="form-control" id="openTime" placeholder="Wednesday Pass"
                            {...register("wednesdayDesawarPassed", { required: false })} />
                          {errors.open && <span className='text-danger'>Open is required </span>}
                        </div>
                      </div>
                      <hr />
                      <div className='row'>
                        <div className=" col-3 col-md-3 mx-auto mb-3">
                          <label className="number" htmlFor="open"></label>
                          <div className="number">Thursday</div>
                        </div>
                        <div className=" col-4 col-md-4 mx-auto mb-3">
                          <label className="number" htmlFor="open">Galii Satta</label>
                          <textarea className="form-control" id="openTime" placeholder="Galli Thursday"
                            {...register("thursdayGali", { required: false })} />
                          {errors.open && <span className='text-danger'>Open is required </span>}
                        </div>
                        <div className=" col-4 col-md-4 mx-auto mb-3">
                          <label className="number" htmlFor="open">Desawar Satta</label>
                          <textarea className="form-control" id="openTime" placeholder="Desawar Thursday"
                            {...register("thursdayDesawar", { required: false })} />
                          {errors.open && <span className='text-danger'>Open is required </span>}
                        </div>
                      </div>
                      <div className='row'>
                        <div className=" col-3 col-md-3 mx-auto mb-3">
                          <div className='number'>Passed</div>
                        </div>
                        <div className=" col-4 col-md-4 mx-auto mb-3">
                          <textarea className="form-control" id="openTime" placeholder="Thursday Pass"
                            {...register("thursdayGaliPassed", { required: false })} />
                          {errors.open && <span className='text-danger'>Open is required </span>}
                        </div>
                        <div className=" col-4 col-md-4 mx-auto mb-3">
                          <textarea className="form-control" id="openTime" placeholder="Thursday Pass"
                            {...register("thursdayDesawarPassed", { required: false })} />
                          {errors.open && <span className='text-danger'>Open is required </span>}
                        </div>
                      </div>
                      <hr />
                      <div className='row'>
                        <div className=" col-3 col-md-3 mx-auto mb-3">
                          <label className="number" htmlFor="open"></label>
                          <div className="number">Friday</div>
                        </div>
                        <div className=" col-4 col-md-4 mx-auto mb-3">
                          <label className="number" htmlFor="open">Galii Satta</label>
                          <textarea className="form-control" id="openTime" placeholder="Galli Friday"
                            {...register("fridayGali", { required: false })} />
                          {errors.open && <span className='text-danger'>Open is required </span>}
                        </div>
                        <div className=" col-4 col-md-4 mx-auto mb-3">
                          <label className="number" htmlFor="open">Desawar Satta</label>
                          <textarea className="form-control" id="openTime" placeholder="Desawar Friday"
                            {...register("fridayDesawar", { required: false })} />
                          {errors.open && <span className='text-danger'>Open is required </span>}
                        </div>
                      </div>
                      <div className='row'>
                        <div className=" col-3 col-md-3 mx-auto mb-3">
                          <div className='number'>Passed</div>
                        </div>
                        <div className=" col-4 col-md-4 mx-auto mb-3">
                          <textarea className="form-control" id="openTime" placeholder="Friday Pass"
                            {...register("fridayGaliPassed", { required: false })} />
                          {errors.open && <span className='text-danger'>Open is required </span>}
                        </div>
                        <div className=" col-4 col-md-4 mx-auto mb-3">
                          <textarea className="form-control" id="openTime" placeholder="Friday Pass"
                            {...register("fridayDesawarPassed", { required: false })} />
                          {errors.open && <span className='text-danger'>Open is required </span>}
                        </div>
                      </div>
                      <hr />
                      <div className='row'>
                        <div className=" col-3 col-md-3 mx-auto mb-3">
                          <label className="number" htmlFor="open"></label>
                          <div className="number">Saturday</div>
                        </div>
                        <div className=" col-4 col-md-4 mx-auto mb-3">
                          <label className="number" htmlFor="open">Galii Satta</label>
                          <textarea className="form-control" id="openTime" placeholder="Galli Saturday"
                            {...register("saturdayGali", { required: false })} />
                          {errors.open && <span className='text-danger'>Open is required </span>}
                        </div>
                        <div className=" col-4 col-md-4 mx-auto mb-3">
                          <label className="number" htmlFor="open">Desawar Satta</label>
                          <textarea className="form-control" id="openTime" placeholder="Desawar Saturday"
                            {...register("saturdayDesawar", { required: false })} />
                          {errors.open && <span className='text-danger'>Open is required </span>}
                        </div>
                      </div>
                      <div className='row'>
                        <div className=" col-3 col-md-3 mx-auto mb-3">
                          <div className='number'>Passed</div>
                        </div>
                        <div className=" col-4 col-md-4 mx-auto mb-3">
                          <textarea className="form-control" id="openTime" placeholder="Saturday Pass"
                            {...register("saturdayGaliPassed", { required: false })} />
                          {errors.open && <span className='text-danger'>Open is required </span>}
                        </div>
                        <div className=" col-4 col-md-4 mx-auto mb-3">
                          <textarea className="form-control" id="openTime" placeholder="Saturday Pass"
                            {...register("saturdayDesawarPassed", { required: false })} />
                          {errors.open && <span className='text-danger'>Open is required </span>}
                        </div>
                      </div>
                      <hr />
                      <div className='row'>
                        <div className=" col-3 col-md-3 mx-auto mb-3">
                          <label className="number" htmlFor="open"></label>
                          <div className="number">Sunday</div>
                        </div>
                        <div className=" col-4 col-md-4 mx-auto mb-3">
                          <label className="number" htmlFor="open">Galii Satta</label>
                          <textarea className="form-control" id="openTime" placeholder="Galli sunday "
                            {...register("sundayGali", { required: false })} />
                          {errors.open && <span className='text-danger'>Open is required </span>}
                        </div>
                        <div className=" col-4 col-md-4 mx-auto mb-3">
                          <label className="number" htmlFor="open">Desawar Satta</label>
                          <textarea className="form-control" id="openTime" placeholder="Desawar Sunday"
                            {...register("sundayDesawar", { required: false })} />
                          {errors.open && <span className='text-danger'>Open is required </span>}
                        </div>
                      </div>
                      <div className='row'>
                        <div className=" col-3 col-md-3 mx-auto mb-3">
                          <div className='number'>Passed</div>
                        </div>
                        <div className=" col-4 col-md-4 mx-auto mb-3">
                          <textarea className="form-control" id="openTime" placeholder="Sunday Pass"
                            {...register("sundayGaliPassed", { required: false })} />
                          {errors.open && <span className='text-danger'>Open is required </span>}
                        </div>
                        <div className=" col-4 col-md-4 mx-auto mb-3">
                          <textarea className="form-control" id="openTime" placeholder="Sunday Pass"
                            {...register("sundayDesawarPassed", { required: false })} />
                          {errors.open && <span className='text-danger'>Open is required </span>}
                        </div>
                      </div>
                      <hr />



                    </div>
                  </div>
                </div>
                <div className="modal-footer">
                  <button type="button" className="btn btn-danger btn-sm" data-dismiss="modal">Close</button>
                  <button type="submit" className="btn btn-primary btn-sm" disabled={formSubmitLoader}>
                    {formSubmitLoader && <i className="fas fa-circle-notch fa-spin me-2"></i>}
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default AdminSattaKingJodi