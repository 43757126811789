import React, { useEffect, useState } from 'react'
import { NavLink } from 'react-router-dom'
import ProfileSidebar from '../../ProfileSidebar/ProfileSidebar'
import * as constants from '../../../Constants/Constants'
import { useForm } from 'react-hook-form';
import ReactPaginate from 'react-paginate';
import './FinalMatkaAnk.css';

declare var $: any;

export default function FinalMatkaAnk() {

  const { register, handleSubmit, setValue, formState: { errors }, reset } = useForm();
  const [formSubmitLoader, setFormSubmitLoader] = useState<boolean>(false);
  const [fmaList, setFmaList] = useState<any[]>();
  const [finalMatkaAnk, setFinalMatkaAnk] = useState<any>();
  const [currentDate, setCurrentDate] = useState<string>(new Date().toISOString().split("T")[0]);
  const [selectedDate, setSelectedDate] = useState('');
  const [lotteryClubList, setLotteryClubList] = useState<any[]>();
  const [selectedLotteryClub, setSelectedLotteryClub] = useState('');

  useEffect(() => {
    getLotteryClubList();
  }, []);

  useEffect(() => {
    getFmaList();
  }, [selectedDate, selectedLotteryClub]);


  const getLotteryClubList = () => {
    fetch(constants.BASE_URL + 'lottery-club/dropdown', {
      method: 'GET',
      headers: { 'Content-Type': 'application/json', 'Accept': 'application/json', 'Authorization': constants.getAuthHeader() },
    }).then(function (response) {
      return response.json();
    }).then(function (response: any) {
      if (response.status == '200') {
        setLotteryClubList(response.result);
      }
    });
  }

  const getFmaList = () => {
    let queryParam = '';
    if (selectedDate) {
      queryParam += '?date=' + selectedDate;
    }
    if (currentDate && !selectedDate) {
      queryParam += '?date=' + currentDate;
    }
    if (selectedLotteryClub) {
      queryParam += queryParam ? '&' : '?';
      queryParam += 'lotteryId=' + selectedLotteryClub;
    }
    fetch(constants.BASE_URL + 'final-matka-ank' + queryParam, {
      method: 'GET',
      headers: { 'Content-Type': 'application/json', 'Accept': 'application/json', 'Authorization': constants.getAuthHeader() },
    }).then(function (response) {
      return response.json();
    }).then(function (response: any) {
      if (response.status == '200') {
        setFmaList(response.result);
      }
    });
  }

  const prepareAddFma = () => {
    $('#fma-modal').modal('show');
  }

  const addFma = (data: any) => {
    setFormSubmitLoader(true);
    if(finalMatkaAnk && finalMatkaAnk.finalMatkaAnkId && finalMatkaAnk.finalMatkaAnkId.length > 0){
      data['lotteryClub'] = { lotteryId: data['lotteryId'] };
      fetch(constants.BASE_URL + 'final-matka-ank/' + finalMatkaAnk.finalMatkaAnkId, {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json', 'Accept': 'application/json', 'Authorization': constants.getAuthHeader() },
        body: JSON.stringify(data)
      }).then(function (response) {
        return response.json();
      }).then(function (response: any) {
        setFormSubmitLoader(false);
        if (response.status == '200') {
          reset();
          $('#fma-modal').modal('hide');
          constants.showSuccess('Request processed successfully');
          getFmaList();
        } else {
          constants.showError(response.message);
        }
      });
    }else{
      data['lotteryClub'] = { lotteryId: data['lotteryId'] };
      fetch(constants.BASE_URL + 'final-matka-ank', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', 'Accept': 'application/json', 'Authorization': constants.getAuthHeader() },
        body: JSON.stringify(data)
      }).then(function (response) {
        return response.json();
      }).then(function (response: any) {
        setFormSubmitLoader(false);
        if (response.status == '201') {
          reset();
          $('#fma-modal').modal('hide');
          constants.showSuccess('Request processed successfully');
          getFmaList();
        } else {
          constants.showError(response.message);
        }
      });
    }
  }

  const prepareEditFma = (fmaId: string) => {
    let fma = fmaList?.find((data: any) => data.finalMatkaAnkId == fmaId);
    setFinalMatkaAnk(fma);
    $('#fma-modal').modal('show');
    setTimeout(() => {
      setValue('finalMatkaAnkId', fma.finalMatkaAnkId);
      setValue('date', fma.date, { shouldDirty: true });
      setValue('lotteryId', fma.lotteryClub.lotteryId , { shouldDirty: true });
      setValue('finalAnk', fma.finalAnk, { shouldDirty: true });
    }, 500);
  }

  const onDateChange = (e: any) => {
    setCurrentDate(e.target.value);
  }
  function formatDate(input: any) {
    if (input != undefined) {
      let datePart = input.match(/\d+/g),
        year = datePart[0],
        // get only two digits 
        month = datePart[1],
        day = datePart[2];
      return day + '-' + month + '-' + year;
    }
  }

  const deleteFinalMatkaAnk = (finalMatkaAnkId: string) => {
    if (window.confirm('Are you sure, you want to delete this record?')) {
      setFormSubmitLoader(true);
      fetch(constants.BASE_URL + 'final-matka-ank/' + finalMatkaAnkId, {
        method: 'DELETE',
        headers: { 'Content-Type': 'application/json', 'Accept': 'application/json', 'Authorization': constants.getAuthHeader() },
      }).then(function (response) {
        return response.json();
      }).then(function (response: any) {
        setFormSubmitLoader(false);
        if (response.status == '200') {
          getFmaList();
          constants.showSuccess(response.message);
        } else {
          constants.showError(response.message);
        }
      });
    }
  }

  return (
    <div className='page-content'>
      <section className="page-header page-header-dark bg-secondary">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-8">
              <h2 className='admin-heading mb-0 text-white'>ADMIN PANEL</h2>
            </div>
            <div className="col-md-4">

            </div>
          </div>
        </div>
      </section>
      <div className="container-fluid p-5 mb-2">
        <div className="row">
          <div className="col-lg-2">
            <ProfileSidebar />
          </div>
          <div className="col-lg-10">
            <div className="bg-white shadow-md rounded p-4">
              <div className='row'>
                <div className='col'>
                  <h4 className="mb-2 text-left">Final Matka Ank</h4>
                </div>
                {/* <div className='col-lg-4'>
                  <input type="text" placeholder='Search by service name' className='form-control form-control-sm w-50 float-end'
                    onChange={searchByName} />
                </div> */}
                <div className='col-2'>
                  {<input type="date" className='form-control' value={selectedDate ? selectedDate : currentDate} onChange={(e) => setSelectedDate(e.target.value)} />}
                </div>
                <div className='col-3'>{
                  <select className="form-control" onChange={(e) => setSelectedLotteryClub(e.target.value)} defaultValue={""}>
                    <option disabled value="">--Select Lottery Club--</option>
                    {lotteryClubList?.map((lc, index) => (
                      <option key={index} value={lc.lotteryId}>{lc.name}</option>
                    ))}
                  </select>
                }</div>
                <div className='col-2'>
                  <button type='button' className="btn btn-info btn-sm float-right" onClick={prepareAddFma}>Add New Ank</button>
                </div>
              </div>
              <div className="tab-content my-3" id="myTabContent">
                <div className="tab-pane fade show active" id="first" role="tabpanel" aria-labelledby="first-tab">
                  <div className="table-responsive-md">
                    <table className="table table table-striped table-hover text-dark">
                      <thead >
                        <tr>
                          <th>Sr No</th>
                          <th>Lottery Club</th>
                          <th>Date</th>
                          <th>Result</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {fmaList?.map((data: any, index: number) => (
                          <tr key={index}>
                            <td className="align-middle">{index + 1}</td>
                            <td className="align-middle">{data.lotteryClub?.name}</td>
                            <td className="align-middle">{formatDate(data.date)}</td>
                            <td className="align-middle">{data.finalAnk}</td>
                            <td className="align-middle">
                              <button className="featured-box-icon bg-primary text-white rounded-circle border-0 edit-icon"
                                onClick={() => prepareEditFma(data.finalMatkaAnkId)}>
                                <i className="fas fa-edit"></i>
                              </button>
                              
                            <button className="featured-box-icon bg-danger text-white rounded-circle border-0 edit-icon"
                                onClick={() => deleteFinalMatkaAnk(data.finalMatkaAnkId)} disabled={formSubmitLoader}>
                                {!formSubmitLoader && <i className="fas fa-trash"></i>}
                                {formSubmitLoader && <i className="fas fa-circle-notch fa-spin me-2"></i>}
                              </button>
                            
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="fma-modal" className="modal fade" role="dialog" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered modal-shadow" role="document">
          <div className="modal-content border-0">
            <div className="modal-header">
              <h5 className="modal-title">Final Matka Ank</h5>
              <button type="button" className="btn-close" data-dismiss="modal" aria-label="Close"></button>
            </div>
            <form id="loginForm" method="post" onSubmit={handleSubmit(addFma)}>
              <div className="modal-body py-4 px-0">
                <div className="row">
                  <div className="col-11 col-md-10 mx-auto">
                    {/* <input type="hidden" id="serviceId" {...register("serviceId", { required: true })}/> */}
                    <div className="mb-3">
                      <label className="number" htmlFor="name">Lottery Club<span className='star'>*</span></label>
                      <select className="form-control" id="name" defaultValue={""} {...register("lotteryId", { required: true })}>
                        <option disabled value="">--Select Lottery Club--</option>
                        {lotteryClubList?.map((lc, index) => (
                          <option key={index} value={lc.lotteryId}>{lc.name}</option>
                        ))}
                      </select>
                      {errors.lotteryId && <span className='text-danger'>Name is required </span>}
                    </div>
                    <div className="mb-3">
                      <label className="number" htmlFor="date">Date<span className='star'>*</span></label>
                      <input type="date" className="form-control" id="date" placeholder="Date"
                        {...register("date", { required: true })} />
                      {errors.date && <span className='text-danger'>Date is required </span>}
                    </div>
                    <div className="mb-3">
                      <label className="number" htmlFor="finalAnk">Final Ank<span className='star'>*</span></label>
                      <input type="text" className="form-control" id="finalAnk" placeholder="Final matka ank"
                        {...register("finalAnk", { required: true })} />
                      {errors.finalAnk && <span className='text-danger'>Final Ank is required </span>}
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <button type="button" className="btn btn-danger btn-sm" data-dismiss="modal">Close</button>
                <button type="submit" className="btn btn-primary btn-sm" disabled={formSubmitLoader}>
                  {formSubmitLoader && <i className="fas fa-circle-notch fa-spin me-2"></i>}
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  )
}
