import React, { ChangeEvent, useEffect, useState } from "react";
import ProfileSidebar from "../../ProfileSidebar/ProfileSidebar";
import * as constants from "../../../Constants/Constants";
import { useForm } from "react-hook-form";
import "./Advertisement.css";

declare var $: any;

interface FileState {
  bottomTwoPhoto: File | undefined;
  bottomOnePhoto: File | undefined;
  topThreePhoto: File | undefined;
  topTwoPhoto: File | undefined;
  topOnePhoto: File | undefined;
}

export default function Advertisement() {
  const imageUrl = constants.BASE_URL + "file/image/";

  const [topOnePhotoUrl, setTopOnePhotoUrl] = useState("");
  const [topTwoPhotoUrl, setTopTwoPhotoUrl] = useState("");
  const [topThreePhotoUrl, setTopThreePhotoUrl] = useState("");
  const [bottomOnePhotoUrl, setBottomOnePhotoUrl] = useState("");
  const [bottomTwoPhotoUrl, setBottomTwoPhotoUrl] = useState("");
  const [fontColor, setFontColor] = useState('#fff');
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
    reset,
  } = useForm();
  const [formSubmitLoader, setFormSubmitLoader] = useState<boolean>(false);

  const [file, setFile] = useState<FileState>({
    bottomTwoPhoto: undefined,
    bottomOnePhoto: undefined,
    topThreePhoto: undefined,
    topTwoPhoto: undefined,
    topOnePhoto: undefined

  });
  useEffect(() => {
    
    $('#ruleForGuesser').summernote({
      placeholder: 'Write rules for guesser...',
      tabsize: 2,
      height: 100,
      toolbar: [
        ['style', ['bold', 'italic', 'underline', 'strikethrough']],
        ['font', ['color']],
        ['para', ['ul', 'ol', 'paragraph']],
        ['table', ['table']],
        ['view', ['fullscreen', 'codeview', 'help']]
      ]
    });
    $('#contact').summernote({
      placeholder: 'Write contact Details...',
      tabsize: 2,
      height: 100,
      toolbar: [
        ['style', ['bold', 'italic', 'underline', 'strikethrough']],
        ['font', ['color']],
        ['para', ['ul', 'ol', 'paragraph']],
        ['table', ['table']],
        ['view', ['fullscreen', 'codeview', 'help']]
      ]
    });
    $('#topOne').summernote({
      placeholder: 'Top One Advertise',
      tabsize: 2,
      height: 100,
      toolbar: [
        ['style', ['bold', 'italic', 'underline', 'strikethrough']],
        ['font', ['color']],
        ['para', ['ul', 'ol', 'paragraph']],
        ['table', ['table']],
        ['view', ['fullscreen', 'codeview', 'help']]
      ]
    });
    $('#topTwo').summernote({
      placeholder: 'Top One Advertise',
      tabsize: 2,
      height: 100,
      toolbar: [
        ['style', ['bold', 'italic', 'underline', 'strikethrough']],
        ['font', ['color']],
        ['para', ['ul', 'ol', 'paragraph']],
        ['table', ['table']],
        ['view', ['fullscreen', 'codeview', 'help']]
      ]
    });
    $('#topThree').summernote({
      placeholder: 'Top One Advertise',
      tabsize: 2,
      height: 100,
      toolbar: [
        ['style', ['bold', 'italic', 'underline', 'strikethrough']],
        ['font', ['color']],
        ['para', ['ul', 'ol', 'paragraph']],
        ['table', ['table']],
        ['view', ['fullscreen', 'codeview', 'help']]
      ]
    });
    $('#bottomOne').summernote({
      placeholder: 'Top One Advertise',
      tabsize: 2,
      height: 100,
      toolbar: [
        ['style', ['bold', 'italic', 'underline', 'strikethrough']],
        ['font', ['color']],
        ['para', ['ul', 'ol', 'paragraph']],
        ['table', ['table']],
        ['view', ['fullscreen', 'codeview', 'help']]
      ]
    });
    $('#bottomTwo').summernote({
      placeholder: 'Top One Advertise',
      tabsize: 2,
      height: 100,
      toolbar: [
        ['style', ['bold', 'italic', 'underline', 'strikethrough']],
        ['font', ['color']],
        ['para', ['ul', 'ol', 'paragraph']],
        ['table', ['table']],
        ['view', ['fullscreen', 'codeview', 'help']]
      ]
    });
    getAdvertisement();
  }, []);

  const handelOnChange = (e: ChangeEvent<HTMLInputElement>, name: keyof FileState) => {
    const selectedFile = e.target.files?.[0];
    if (selectedFile) {
      setFile(prevState => ({
        ...prevState,
        [name]: selectedFile
      }));
    }
  }

  const handelDelete = async (name: keyof FileState) => {
    if (window.confirm('Are you sure, you want to delete this record?')) {
      try {
        const response = await fetch(`${constants.BASE_URL}advertisement/deleteImage/${name}`, {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: constants.getAuthHeader(),
          },
        });

        const responseData = await response.json();

        if (responseData.status === "200") {
          switch (name) {
            case "topOnePhoto":
              setTopOnePhotoUrl("");
              setValue("topOne", "");
              setValue("topOneLink", "");
              $('#topOne').summernote('code', '');
              break;
              case "topTwoPhoto":
                setTopTwoPhotoUrl("");
                setValue("topTwo", ""); // Clear the Summernote content
                setValue("topTwoLink", "");
                $('#topTwo').summernote('code', ''); // Clear the content of topTwo editor
                break;
              case "topThreePhoto":
                setTopThreePhotoUrl("");
                setValue("topThree", ""); // Clear the Summernote content
                setValue("topThreeLink", "");
                $('#topThree').summernote('code', ''); // Clear the content of topThree editor
                break;
              case "bottomOnePhoto":
                setBottomOnePhotoUrl("");
                setValue("bottomOne", "");
                setValue("bottomOneLink", "");
                $('#bottomOne').summernote('code', '');
                break;
              case "bottomTwoPhoto":
                setBottomTwoPhotoUrl("");
                setValue("bottomTwo", "");
                setValue("bottomTwoLink", "");
                $('#bottomTwo').summernote('code', '');
                break;
            default:
              break;
          }
        } else {
          constants.showError(responseData.message);
        }
      } catch (error) {
        console.error("Error deleting image:", error);
      }
    }
  };
  const handleFontColorChange = (color: { hex: React.SetStateAction<string>; }) => {
    setFontColor(color.hex);
    setValue('fontColor', color.hex);
  };



  const submitAdvertisement = async (data: any) => {
    setFormSubmitLoader(true);
    data.topOne = $('#topOne').summernote('code');
    if (data.topOne) {
      data.topOne = data.topOne;
    }
    data.topTwo = $('#topTwo').summernote('code');
    if (data.topTwo) {
      data.topTwo = data.topTwo;
    }
    data.topThree = $('#topThree').summernote('code');
    if (data.topThree) {
      data.topThree = data.topThree;
    }
    data.bottomOne = $('#bottomOne').summernote('code');
    if (data.bottomOne) {
      data.bottomOne = data.bottomOne;
    }
    data.bottomTwo = $('#bottomTwo').summernote('code');
    if (data.bottomTwo) {
      data.bottomTwo = data.bottomTwo;
    }
    if (file) {
      try {
        const base64Strings = await Promise.all([
          file.topOnePhoto ? constants.convertFileToBase64(file.topOnePhoto) : '',
          file.topTwoPhoto ? constants.convertFileToBase64(file.topTwoPhoto) : '',
          file.topThreePhoto ? constants.convertFileToBase64(file.topThreePhoto) : '',
          file.bottomOnePhoto ? constants.convertFileToBase64(file.bottomOnePhoto) : '',
          file.bottomTwoPhoto ? constants.convertFileToBase64(file.bottomTwoPhoto) : ''
        ]);

        data.topOnePhoto = file.topOnePhoto ? file.topOnePhoto.name : '';
        data.topOneEncodedPhoto = base64Strings[0];

        data.topTwoPhoto = file.topTwoPhoto ? file.topTwoPhoto.name : '';
        data.topTwoEncodedPhoto = base64Strings[1];

        data.topThreePhoto = file.topThreePhoto ? file.topThreePhoto.name : '';
        data.topThreeEncodedPhoto = base64Strings[2];

        data.bottomOnePhoto = file.bottomOnePhoto ? file.bottomOnePhoto.name : '';
        data.bottomOneEncodedPhoto = base64Strings[3];

        data.bottomTwoPhoto = file.bottomTwoPhoto ? file.bottomTwoPhoto.name : '';
        data.bottomTwoEncodedPhoto = base64Strings[4];

      } catch (error) {
        console.log("Not getting the result....", error);
      }
    }
    // data.morningStartTime = morningStartTime;
    // data.morningEndTime = morningEndTime;
    // data.eveningStartTime = eveningStartTime;
    // data.eveningEndTime = eveningEndTime;



    data.contact = $('#contact').summernote('code');
    if (data.contact) {
      data.contact = data.contact;
    }
    if (data.fontColor) {
      data.fontColor = data.fontColor;
    }
    data.ruleForGuesser = $('#ruleForGuesser').summernote('code');
    if (data.ruleForGuesser) {
      data.ruleForGuesser = data.ruleForGuesser;
    }
    fetch(constants.BASE_URL + "advertisement", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: constants.getAuthHeader(),
      },
      body: JSON.stringify(data),
    })
      .then(function (response) {
        return response.json();
      })
      .then(function (response: any) {
        setFormSubmitLoader(false);
        if (response.status == "201") {
          constants.showSuccess("Request processed successfully");
        } else {
          constants.showError(response.message);
        }
      });
  };


  const getAdvertisement = () => {
    fetch(constants.BASE_URL + "advertisement/public", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: constants.getAuthHeader(),
      },
    })
      .then(function (response) {
        return response.json();
      })
      .then(function (response: any) {
        if (response.status == "200") {
          if (response.result) {
            setValue("morningStartTime", response.result?.morningStartTime)
            setValue("morningEndTime", response.result?.morningEndTime)
            setValue("eveningStartTime", response.result?.eveningStartTime)
            setValue("eveningEndTime", response.result?.eveningEndTime)
            setValue("fontColor", response.result?.fontColor)
            $('#contact').summernote('code', response.result.contact);
            $('#ruleForGuesser').summernote('code', response.result.ruleForGuesser);
            setTopOnePhotoUrl(imageUrl + response.result.topOnePhoto + '/');
            setTopTwoPhotoUrl(imageUrl + response.result.topTwoPhoto + '/');
            setTopThreePhotoUrl(imageUrl + response.result.topThreePhoto + '/');
            setBottomOnePhotoUrl(imageUrl + response.result.bottomOnePhoto + '/');
            setBottomTwoPhotoUrl(imageUrl + response.result.bottomTwoPhoto + '/');
            setTimeout(() => {
              $('#topOne').summernote('code', response.result?.topOne);
              setValue("topOneLink", response.result?.topOneLink);
              setValue("topOnePhoto", response.result?.topOnePhoto);

              $('#topTwo').summernote('code', response.result?.topTwo);
              setValue("topTwoLink", response.result?.topTwoLink);
              setValue("topTwoPhoto", response.result?.topTwoPhoto);

              $('#topThree').summernote('code', response.result?.topThree);
              setValue("topThreeLink", response.result?.topThreeLink);
              setValue("topThreePhoto", response.result?.topThreePhoto);

              $('#bottomOne').summernote('code', response.result?.bottomOne);
              setValue("bottomOneLink", response.result?.bottomOneLink);
              setValue("bottomOnePhoto", response.result?.bottomOnePhoto);

              $('#bottomTwo').summernote('code', response.result?.bottomTwo);
              setValue("bottomTwoLink", response.result?.bottomTwoLink);
              setValue("bottomTwoPhoto", response.result?.bottomTwoPhoto);

            }, 500);
          }
        }
      });
  };


  return (
    <>
      <div className="page-content">
        <section className="page-header page-header-dark bg-secondary">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-md-8">
                <h2 className="admin-heading mb-0 text-white">ADMIN PANEL</h2>
              </div>
              <div className="col-md-4"></div>
            </div>
          </div>
        </section>
        <div className="container-fluid p-5 mb-2">
          <div className="row">
            <div className="col-lg-2">
              <ProfileSidebar />
            </div>
            <div className="col-lg-10">
              <div className="bg-white shadow-md rounded p-4">
                <div className="row">
                  <div className="col">
                    <h4 className="mb-2 text-left">Advertisement</h4>
                  </div>
                </div>
                <form
                  id="advForm"
                  method="post"
                  onSubmit={handleSubmit(submitAdvertisement)}
                >
                  <div className="row">
                    <div className="col-11 col-md-10 mx-auto">
                      <div className="row">
                        <div className=" col-md-4 col-sm-12">
                          <label
                            className="form-control-label"
                            htmlFor="advertisement mt-5"
                          >
                            Top One
                          </label>
                          <textarea
                            className="form-control"
                            id="topOne"
                            {...register("topOne", { required: false })}
                          ></textarea>
                        </div>
                        <div className=" col-md-4 col-sm-12">
                          <label className="number" htmlFor="link">
                            Top One Link
                          </label>
                          <textarea
                            className="form-control"
                            id="topOneLink"
                            placeholder="Top One Link"
                            {...register("topOneLink", { required: false })}
                          ></textarea>
                        </div>
                        <div className="col-md-4 col-sm-12">
                          <label className="form-control-label">
                            Top One Image
                          </label>
                          <input
                            className="form-control"
                            id="image"
                            type="file"
                            name="image"
                            accept="image/*"
                            onChange={(event) => {
                              handelOnChange(event, "topOnePhoto");
                            }}
                          />
                          <div className="row">
                            <div className="col-8 text-center pt-1">
                              {topOnePhotoUrl && (
                                <img
                                  src={topOnePhotoUrl}
                                  alt="Top One Image"
                                  className="textimg"
                                />
                              )}
                            </div>
                            <div className="col-4 pt-4 text-center">
                              <button className="btn btn-sm bg-danger rounded text-white font-weight-bold" onClick={() => handelDelete("topOnePhoto")}>Delete</button>                           </div>
                          </div>

                        </div>
                      </div>
                      <div className="row mt-3">
                        <div className=" col-md-4 col-sm-12">
                          <label
                            className="form-control-label"
                            htmlFor="advertisement mt-5"
                          >
                            Top Two
                          </label>
                          <textarea
                            className="form-control"
                            id="topTwo"
                            {...register("topTwo", { required: false })}
                          ></textarea>
                        </div>
                        <div className="col-md-4 col-sm-12">
                          <label className="number" htmlFor="link">
                            Top Two Link
                          </label>
                          <textarea
                            className="form-control"
                            id="topTwoLink"
                            placeholder="Top Two Link"
                            {...register("topTwoLink", { required: false })}
                          ></textarea>
                        </div>
                        <div className="col-md-4 col-sm-12">
                          <label className="form-control-label">
                            Top Two Image
                          </label>
                          <input
                            className="form-control"
                            id="topTwoPhoto"
                            type="file"
                            accept="image/*"
                            onChange={(event) => {
                              handelOnChange(event, "topTwoPhoto");
                            }}
                          />
                          <div className="row">
                            <div className="col-8 text-center pt-1">
                              {topTwoPhotoUrl && (
                                <img
                                  src={topTwoPhotoUrl}
                                  alt="Top Two Image"
                                  className="textimg"
                                />
                              )}
                            </div>
                            <div className="col-4 pt-4 text-center">
                              <button className="btn btn-sm bg-danger rounded text-white font-weight-bold" onClick={() => handelDelete("topTwoPhoto")}>Delete</button>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row mt-3">
                        <div className="col-md-4 col-sm-12">
                          <label
                            className="form-control-label"
                            htmlFor="advertisement mt-5"
                          >
                            Top Three
                          </label>
                          <textarea
                            className="form-control"
                            id="topThree"
                            {...register("topThree", { required: false })}
                          ></textarea>
                        </div>
                        <div className=" col-md-4 col-sm-12">
                          <label className="number" htmlFor="link">
                            Top Three Link
                          </label>
                          <textarea
                            className="form-control"
                            id="topThreeLink"
                            placeholder="Top Three Link"
                            {...register("topThreeLink", { required: false })}
                          ></textarea>
                        </div>
                        <div className="col-md-4 col-sm-12">
                          <label className="form-control-label">
                            Top Three Image
                          </label>
                          <input
                            className="form-control"
                            id="topThreePhoto"
                            type="file"
                            accept="image/*"
                            onChange={(event) => {
                              handelOnChange(event, "topThreePhoto");
                            }}
                          />
                          <div className="row">
                            <div className="col-8 text-center pt-1">
                              {topThreePhotoUrl && (
                                <img
                                  src={topThreePhotoUrl}
                                  alt="Top Three Image"
                                  className="textimg"
                                />
                              )}
                            </div>
                            <div className="col-4 pt-4 text-center">
                              <button className="btn btn-sm bg-danger rounded text-white font-weight-bold" onClick={() => handelDelete("topThreePhoto")}>Delete</button>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row mt-3">
                        <div className=" col-md-4 col-sm-12">
                          <label
                            className="form-control-label"
                            htmlFor="advertisement mt-5"
                          >
                            Bottom One
                          </label>
                          <textarea
                            className="form-control"
                            id="bottomOne"
                            {...register("bottomOne", { required: false })}
                          ></textarea>
                        </div>
                        <div className="col-md-4 col-sm-12">
                          <label className="number" htmlFor="link">
                            Bottom One Link
                          </label>
                          <textarea
                            className="form-control"
                            id="bottomOneLink"
                            placeholder="Bottom One Link"
                            {...register("bottomOneLink", { required: false })}
                          ></textarea>
                        </div>
                        <div className="col-md-4 col-sm-12">
                          <label className="form-control-label">
                            Bottom One Image
                          </label>
                          <input
                            className="form-control"
                            id="bottomOnePhoto"
                            type="file"
                            accept="image/*"
                            onChange={(event) => {
                              handelOnChange(event, "bottomOnePhoto");
                            }}
                          />
                          <div className="row">
                            <div className="col-8 text-center pt-1">
                              {bottomOnePhotoUrl && (
                                <img
                                  src={bottomOnePhotoUrl}
                                  alt="Bottom One Image"
                                  className="textimg"
                                />
                              )}
                            </div>
                            <div className="col-4 pt-4 text-center">
                              <button className="btn btn-sm bg-danger rounded text-white font-weight-bold" onClick={() => handelDelete("bottomOnePhoto")}>Delete</button>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row mt-3">
                        <div className="col-md-4 col-sm-12">
                          <label
                            className="form-control-label"
                            htmlFor="advertisement mt-5"
                          >
                            Bottom Two
                          </label>
                          <textarea
                            className="form-control"
                            id="bottomTwo"
                            {...register("bottomTwo", { required: false })}
                          ></textarea>
                        </div>
                        <div className="col-md-4 col-sm-12">
                          <label className="number" htmlFor="link">
                            Bottom Two Link
                          </label>
                          <textarea
                            className="form-control"
                            id="bottomTwoLink"
                            placeholder="Bottom Two Link"
                            {...register("bottomTwoLink", { required: false })}
                          ></textarea>
                        </div>
                        <div className="col-md-4 col-sm-12">
                          <label className="form-control-label">
                            Bottom Two Image
                          </label>
                          <input
                            className="form-control"
                            id="bottomTwoPhoto"
                            type="file"
                            accept="image/*"
                            onChange={(event) => {
                              handelOnChange(event, "bottomTwoPhoto");
                            }}
                          />
                          <div className="row">
                            <div className="col-8 text-center pt-1">
                              {bottomTwoPhotoUrl && (
                                <img
                                  src={bottomTwoPhotoUrl}
                                  alt="Bottom Two Image"
                                  className="textimg"
                                />
                              )}
                            </div>
                            <div className="col-4 pt-4 text-center">
                              <button className="btn btn-sm bg-danger rounded text-white font-weight-bold" onClick={() => handelDelete("bottomTwoPhoto")}>Delete</button>
                            </div>
                          </div>

                        </div>
                      </div>
                      <div className="row mt-3">
                        <div className="col-md-4 col-sm-12">
                          <div className="form-group ">
                            <label className="form-control-label">Morning Start Time :</label>
                            <div className="d-flex align-items-center">
                              <div className="row">
                                <div className="col-md-6">
                                  <span className="text-bold">From:</span>
                                  <input
                                    type="text"
                                    className="form-control mx-2"
                                    {...register("morningStartTime", { required: false })}
                                    placeholder="Start Time"
                                  />
                                </div>
                                <div className="col-md-6">
                                  <span className="text-bold">To:</span>
                                  <input
                                    type="text"
                                    className="form-control mx-2"
                                    {...register("morningEndTime", { required: false })}
                                    placeholder="End Time"
                                  />
                                </div>
                              </div>


                            </div>
                          </div>
                        </div>

                        <div className="col-md-4 col-sm-12">
                          <div className="form-group">
                            <label className="form-control-label">Evening Start Time :</label>
                            <div className="d-flex align-items-center">
                              <div className="row">
                                <div className="col-md-6">
                                  <span className="text-bold">From:</span>
                                  <input
                                    type="text"
                                    className="form-control mx-2"
                                    {...register("eveningStartTime", { required: false })}
                                    placeholder="Start Time"
                                  />
                                </div>
                                <div className="col-md-6">
                                  <span className="text-bold">To:</span>
                                  <input
                                    type="text"
                                    className="form-control mx-2"
                                    {...register("eveningEndTime", { required: false })}
                                    placeholder="End Time"
                                  />
                                </div>

                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row mt-3">
                      <div className="col-md-6 col-sm-12">
                          <label className="form-control-label" htmlFor="contact">
                            Contact Details
                          </label>
                          <textarea
                            id="contact"
                            {...register("contact", { required: false })}
                          />

                        </div>
                        <div className="col-md-6 col-sm-12">
                          <label className="form-control-label" htmlFor="ruleForGuesser">
                            Rule for Guesser
                          </label>
                          <textarea
                            id="ruleForGuesser"
                            {...register("ruleForGuesser", { required: false })}
                          />

                        </div>

                      </div>


                      <div className="row">
                        <div className="col mt-3 text-center">
                          <button
                            type="submit"
                            className="btn btn-primary btn-sm"
                            disabled={formSubmitLoader}
                          >
                            {formSubmitLoader && (
                              <i className="fas fa-circle-notch fa-spin me-2"></i>
                            )}
                            Submit
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div >
        </div >
      </div >
    </>
  );
}
