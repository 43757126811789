import React, { useEffect, useState } from 'react'
import { NavLink } from 'react-router-dom'
import ProfileSidebar from '../../ProfileSidebar/ProfileSidebar'
import * as constants from '../../../Constants/Constants'
import { useForm } from 'react-hook-form';
import ReactPaginate from 'react-paginate';
import './Users.css';


declare var $: any;

export default function Users() {

  const { register, handleSubmit, setValue, formState: { errors }, reset, watch } = useForm();
  const [formSubmitLoader, setFormSubmitLoader] = useState<boolean>(false);
  const [selectedUserId, setSelectedUserId] = useState(null);
  const [userList, setUserList] = useState<any[]>();
  const [pageCount, setPageCount] = useState(0);
  const [pageNum, setPageNum] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(20);
  const [showPassword, setShowPassword] = useState(false);
  const [showPassword2, setShowPassword2] = useState(false);
  const [user, setUser] = useState<any>();
  const [currentUser, setCurrentUser] = useState<any>();

  const [searchBy, setSearchBy] = useState('');

  useEffect(() => {
    if (sessionStorage.getItem('currentUser')) {
      setCurrentUser(JSON.parse(sessionStorage.getItem('currentUser')!));
    }
    getUserList();
  }, [pageNum, searchBy]);

  const getUserList = () => {
    let queryParam = '?page=' + (pageNum - 1) + '&size=' + itemsPerPage;
    if (searchBy) {
      queryParam += '&searchBy=' + searchBy;
    }
    fetch(constants.BASE_URL + 'user/list' + queryParam, {
      method: 'GET',
      headers: { 'Content-Type': 'application/json', 'Accept': 'application/json', 'Authorization': constants.getAuthHeader() },
    }).then(function (response) {
      return response.json();
    }).then(function (response: any) {
      if (response.status == '200') {
        setUserList(response.result);
        setPageCount(Math.ceil(response.listCount / itemsPerPage));
      }
    });
  }

  const prepareChangePassword = (userId: any) => {
    setSelectedUserId(userId);
    reset();
    $('#change-password-modal').modal('show');
  }

  const prepareEditUser = (userId: string) => {
    let u = userList?.find((data: any) => data.userId == userId);
    setUser(u);
    $('#user-modal').modal('show');
    setTimeout(() => {
      setValue('userId', u.userId);
      setValue('name', u.name, { shouldDirty: true });
      setValue('mobile', u.mobile, { shouldDirty: true });
      setValue('userText', u.userText, { shouldDirty: true });
    }, 500);
  }


  const handleCloseModal = () => {
    $('#change-password-modal').modal('hide');
    reset(); // Reset form state
  };

  const handlePageClick = (event: any) => {
    setPageNum(event.selected + 1);
  };

  const handleSearchInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchBy(e.target.value);
  };

  const changeUserPassword = (data: any) => {
    setFormSubmitLoader(true);
    fetch(constants.BASE_URL + 'user/profile/change-password/' + selectedUserId, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': constants.getAuthHeader()
      },
      body: JSON.stringify(data),
    }).then(response => response.json())
      .then(response => {
        setFormSubmitLoader(false);
        if (response.status == '200') {
          constants.showSuccess('Password changed successfully');
          getUserList();
          $('#change-password-modal').modal('hide');
        } else {
          constants.showError(response.message);
        }
      });
  };

  const changeUserStatus = (userId: string) => {
    fetch(constants.BASE_URL + 'user/status/' + userId, {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json', 'Accept': 'application/json', 'Authorization': constants.getAuthHeader() },
    }).then(function (response) {
      return response.json();
    }).then(function (response: any) {
      if (response.status == '200') {
        constants.showSuccess('Status changed successfully');
        getUserList();
      } else {
        constants.showError('Could not perform the request');
      }
    });
  }

  const handleFormSubmit = (event: any) => {
    event.preventDefault();
    const formData = {
      name: event.target.name.value,
      mobile: event.target.mobile.value,
      userText: event.target.userText.value
    };
    updateUser(formData);
  };

  const updateUser = (data: any) => {
    setFormSubmitLoader(true);
    fetch(constants.BASE_URL + 'user/' + user.userId, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': constants.getAuthHeader()
      },
      body: JSON.stringify(data),
    }).then(response => response.json())
      .then(response => {
        setFormSubmitLoader(false);
        if (response.status == '200') {
          constants.showSuccess('User Updated successfully');
          getUserList();
          $('#user-modal').modal('hide');
        } else {
          constants.showError(response.message);
        }
      });
  };

  const deleteUser = (userId: string) => {
    if (window.confirm('Are you sure, you want to delete this record?')) {
      setFormSubmitLoader(true);
      fetch(constants.BASE_URL + 'user/' + userId, {
        method: 'DELETE',
        headers: { 'Content-Type': 'application/json', 'Accept': 'application/json', 'Authorization': constants.getAuthHeader() },
      }).then(function (response) {
        return response.json();
      }).then(function (response: any) {
        setFormSubmitLoader(false);
        if (response.status == '200') {
          getUserList();
          constants.showSuccess(response.message);
        } else {
          constants.showError(response.message);
        }
      });
    }
  }


  const newPassword = watch('newPassword');
  return (
    <div className='page-content'>
      <section className="page-header page-header-dark bg-secondary">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-8">
              <h2 className='admin-heading mb-0 text-white'>ADMIN PANEL</h2>
            </div>
            <div className="col-md-4">

            </div>
          </div>
        </div>
      </section>
      <div className="container-fluid p-5 mb-2">
        <div className="row">
          <div className="col-lg-2">
            <ProfileSidebar />
          </div>
          <div className="col-lg-10">
            <div className="bg-white shadow-md rounded p-4">
              <div className='row'>
                <div className='col'>
                  <h4 className="mb-2 text-left">Users</h4>
                </div>
                <div className='col-lg-4'>
                  <input
                    type="text"
                    placeholder='Search by user name'
                    className='form-control form-control-sm w-50 float-end'
                    value={searchBy}
                    onChange={handleSearchInputChange}
                  />
                </div>
                {/* <div className='col'>
                  <button type='button' className="btn btn-info btn-sm float-right" onClick={prepareAddLotteryClub}>Add New Club</button>
                </div> */}
              </div>
              <div className="tab-content my-3" id="myTabContent">
                <div className="tab-pane fade show active" id="first" role="tabpanel" aria-labelledby="first-tab">
                  <div className="table-responsive-md">
                    <table className="table table table-striped table-hover text-dark">
                      <thead >
                        <tr>
                          <th>Sr No</th>
                          <th>Username</th>
                          <th>Mobile</th>
                          <th>User Text</th>
                          <th>Status</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {userList?.map((data: any, index: number) => (
                          <tr key={index}>
                            <td className="align-middle">{(pageNum - 1) * itemsPerPage + index + 1}</td>
                            <td className="align-middle">{data.name}</td>
                            <td className="align-middle">{data.mobile}</td>
                            <td className="align-middle">{data.userText}</td>
                            <td className="align-middle">
                              {data.status &&
                                <span className="badge badge-success p-2">Active</span>
                              }
                              {!data.status &&
                                <span className="badge badge-danger p-2">In-Active</span>
                              }</td>
                            <td className="align-middle">
                            {currentUser.role.name === 'ADMIN' && (
                              <span
                                className="badge badge-primary cursor-pointer p-2 me-1"
                                onClick={() => changeUserStatus(data.userId)}
                              >
                                Change Status
                              </span>
                            )} 
                              <button className="featured-box-icon bg-primary text-white rounded-circle border-0 edit-icon"
                                onClick={() => prepareEditUser(data.userId)}>
                                <i className="fas fa-edit"></i>
                              </button>
                              <button
                                className="featured-box-icon bg-primary rounded text-white border-0 ml-1"
                                onClick={() => prepareChangePassword(data.userId)}
                              >
                                <i className="fa fa-key"></i>
                              </button>
                              <button className="featured-box-icon bg-danger text-white rounded-circle border-0 edit-icon ml-1"
                                onClick={() => deleteUser(data.userId)} disabled={formSubmitLoader}>
                                {!formSubmitLoader && <i className="fas fa-trash"></i>}
                                {formSubmitLoader && <i className="fas fa-circle-notch fa-spin me-2"></i>}
                              </button>
                            </td>

                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                  <ReactPaginate breakLabel="..." nextLabel=">" onPageChange={handlePageClick} pageRangeDisplayed={5}
                    pageCount={pageCount} previousLabel="<" pageClassName="page-item" pageLinkClassName="page-link"
                    previousClassName="page-item" previousLinkClassName="page-link" nextClassName="page-item" nextLinkClassName="page-link"
                    breakClassName="page-item" breakLinkClassName="page-link" containerClassName="pagination" activeClassName="active"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="change-password-modal" className="modal" role="dialog" aria-hidden="true">
        <div className="modal-dialog modal-shadow" role="document">
          <div className="modal-content border-0">
            <div className="modal-header">
              <h5 className="modal-title">Change Password</h5>
              <button type="button" className="btn-close" aria-label="Close" onClick={handleCloseModal}></button>
            </div>
            <form onSubmit={handleSubmit(changeUserPassword)}>
              <div className="modal-body py-4 px-0">
                <div className="row">
                  <div className="col-11 col-md-10 mx-auto">
                    {/* <div className="mb-3">
                      <label htmlFor="oldpsw" className="form-label">Old Password<span className="text-danger">*</span></label>
                      <input
                        type="password"
                        className={`form-control ${errors.password ? 'is-invalid' : ''}`}
                        id="password"
                        placeholder="Enter old Password"
                        autoComplete="off"
                        {...register('password', { required: 'Old Password is required.' })}
                      />
                      {errors.password && <span className='text-danger'>Old Password is required </span>}
                    </div> */}
                    <div className="mb-3">
                      <label htmlFor="pass1" className="form-label">New Password<span className="text-danger">*</span></label>

                      <input
                        type={showPassword ? 'text' : 'password'}
                        className={`form-control ${errors.newPassword ? 'is-invalid' : ''}`}
                        id="newPassword"
                        autoComplete="off"
                        placeholder="Enter New Password"

                        {...register('newPassword', { required: 'New Password is required.' })}
                      />
                      {/* <div className="input-group-text">
                          <span className="fas fa-eye eye-show eyeIcon" onClick={() => setShowPassword(!showPassword)}></span>
                        </div> */}
                      {errors.newPassword && <span className='text-danger'>New Password is required </span>}

                    </div>
                    <div className="mb-3">
                      <label htmlFor="cnfPassword" className="form-label">Confirm Password<span className="text-danger">*</span></label>

                      <input
                        type={showPassword2 ? 'text' : 'password'}
                        className={`form-control ${errors.cnfPassword ? 'is-invalid' : ''}`}
                        id="cnfPassword"
                        placeholder="Enter Confirm Password"
                        autoComplete="off"
                        {...register('cnfPassword', {
                          required: 'Confirm Password is required.',
                          validate: value => value === newPassword || 'Passwords do not match'
                        })}
                      />
                      {/* <div className="input-group-text">
                          <span className="fas fa-eye eye-show eyeIcon" onClick={() => setShowPassword2(!showPassword2)}></span>
                        </div> */}
                      {errors.cnfPassword && (
                        <span className='text-danger'>
                          {typeof errors.cnfPassword.message === 'string' ? errors.cnfPassword.message : 'Passwords do not match'}
                        </span>
                      )}

                    </div>
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <button type="button" className="btn btn-danger btn-sm" onClick={handleCloseModal}>Close</button>
                <button type="submit" className="btn btn-primary btn-sm" disabled={formSubmitLoader}>
                  {formSubmitLoader && <i className="fas fa-circle-notch fa-spin me-2"></i>}
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div id="user-modal" className="modal fade" role="dialog" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered modal-shadow" role="document">
          <div className="modal-content border-0">
            <div className="modal-header">
              <h5 className="modal-title">User</h5>
              <button type="button" className="btn-close" data-dismiss="modal" aria-label="Close"></button>
            </div>
            <form id="loginForm" method="post" onSubmit={handleFormSubmit}>
              <div className="modal-body py-4 px-0">
                <div className="row">
                  <div className="col-11 col-md-10 mx-auto">
                    {/* <input type="hidden" id="serviceId" {...register("serviceId", { required: true })}/> */}
                    <div className="mb-3">
                      <label className="number" htmlFor="name">User Name<span className='star'>*</span></label>
                      <input type="text" className="form-control" id="name" placeholder="User Name"
                        {...register("name", { required: true })} />
                      {errors.name && <span className='text-danger'>Name is required </span>}
                    </div>
                    <div className="mb-3">
                      <label className="number" htmlFor="opentime">Mobile</label>
                      <input type="text" className="form-control" id="mobile" placeholder="Mobile"
                        
                        {...register("mobile", { required: true })} />
                      {/* {errors.mobile && <span className='text-danger'>Mobile is required </span>} */}

                    </div>
                    <div className="mb-3">
                      <label className="number" htmlFor="opentime">User Text</label>
                      <input type="text" className="form-control" id="userText" placeholder="userText"
                        {...register("userText", { required: true })} />
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <button type="button" className="btn btn-danger btn-sm" data-dismiss="modal">Close</button>
                <button type="submit" className="btn btn-primary btn-sm" disabled={formSubmitLoader}>
                  {formSubmitLoader && <i className="fas fa-circle-notch fa-spin me-2"></i>}
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  )
}
