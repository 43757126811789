import React from 'react'
import { Routes, Route } from "react-router-dom";
import Footer from '../Component/Footer/Footer';
import FreeMatkaSattaGame from '../Component/FreeMatkaSattaGame/FreeMatkaSattaGame';
import Home from '../Component/Home/Home';
import Jodi from '../Component/Jodi/Jodi';
import MatkaGuessing from '../Component/MatkaGuessing/MatkaGuessing';
import Panel from '../Component/Panel/Panel';
import WeeklySattaMaatka from '../Component/WeeklySattaMatka/WeeklySattaMaatka';
import SattakingJodi from '../Component/SattakingJodi/SattakingJodi';
import MatkaTrickTips from '../Component/MatkaTrickTips/MatkaTrickTips';
import LotteryClub from '../Component/Admin/LotteryClub/LotteryClub';
import DailyResult from '../Component/Admin/DailyResult/DailyResult';
import FinalMatkaAnk from '../Component/Admin/FinalMatkaAnk/FinalMatkaAnk';
import SattaFreeGame from '../Component/Admin/SattaFreeGame/SattaFreeGame';
import SattaFixGame from '../Component/Admin/SattaFixGame/SattaFixGame';
import Dashboard from '../Component/Admin/Dashboard/Dashboard';
import Users from '../Component/Admin/Users/Users';
import MatksGuessingList from '../Component/Admin/MatkaGuessingList/MatkaGuessingList';
import TodayTips from '../Component/Admin/TodayTips/TodayTips';
import AllSattaMatkaCharts from '../Component/AllSattaMatkaCharts/AllSattaMatkaCharts';
import Advertisement from '../Component/Admin/Advertisement/Advertisement';
import AdminSattaKingJodi from '../Component/Admin/AdminSattaKingJodi/AdminSattaKingJodi';
import TodayFreeGame from '../Component/Admin/TodayFreeGame/TodayFreeGame';
import WeeklyJodiPattiAdmin from '../Component/Admin/WeeklyJodiPattiAdmin/WeeklyJodiPattiAdmin';


export default function Routing() {
  return (
    <div>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/footer" element={<Footer />} />
        <Route path="/jodi/:lotId" element={<Jodi />} />
        <Route path="/panel/:lotId" element={<Panel />} />
        <Route path="/matkaguessing" element={<MatkaGuessing />} />
        <Route path="/freematkasattagame" element={<FreeMatkaSattaGame />} />
        <Route path="/weeklysattamatka" element={<WeeklySattaMaatka />} />
        <Route path="/sattakingjodi" element={<SattakingJodi />} />
        <Route path="/matkatricktips" element={<MatkaTrickTips />} />
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/lotteryclub" element={<LotteryClub />} />
        <Route path="/dailyresults" element={<DailyResult />} />
        <Route path="/final-matka-ank" element={<FinalMatkaAnk />} />
        <Route path="/satta-free-game" element={<SattaFreeGame />} />
        <Route path="/satta-fix-game" element={<SattaFixGame />} />
        <Route path="/users" element={<Users />} />
        <Route path="/matka-guessing-list" element={<MatksGuessingList />} />
        <Route path="/today-tips" element={<TodayTips />} />
        <Route path="/all-satta-matka-charts" element={<AllSattaMatkaCharts/>} />
        <Route path="/advertisement" element={<Advertisement/>} />
        <Route path="/adminsattakingjodi" element={<AdminSattaKingJodi/>} />
        <Route path="/todayfreegame" element={<TodayFreeGame/>} />
        <Route path="/weeklymatkaadmin" element={<WeeklyJodiPattiAdmin/>} />



      </Routes>
    </div>
  )
}
