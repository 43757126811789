import React, { useEffect, useState } from 'react';
import "./WeeklySattaMatka.css";
import * as constants from '../../Constants/Constants';





export default function () {
  const [currentweeklyJodiPatti, setCurrentweeklyJodiPattiKKM] = useState<any>();

  useEffect (()=>{
    getweeklyJodiPattiKMKNMb() ;
  } ,[])
  // get api for wwekly jodi patti KALYAN MATKA KALYAN NIGHT MAIN BAZAR current week
  const getweeklyJodiPattiKMKNMb = () => {
    // let queryParam = currentDate ? '?date=' + currentDate : '';
    fetch(constants.BASE_URL + 'weekly-jodi-patti/public/current-week', {
      method: 'GET',
      headers: { 'Content-Type': 'application/json', 'Accept': 'application/json', 'Authorization': constants.getAuthHeader() },
    }).then(function (response) {
      return response.json();
    }).then(function (response: any) {
      if (response.status == '200') {
        setCurrentweeklyJodiPattiKKM(response.result);
      } else {
        constants.showError(response.message);
      }
      
    });
  }
  // function to change date format
function formatDate(input: any) {
  if (input != undefined){
    let datePart = input?.match(/\d+/g),
    year = datePart[0],
    // get only two digits 
    month = datePart[1],
    day = datePart[2];
    return day + '-' + month + '-' + year;
  }
  // console.log(input ,"input")
 
}
  return (
    <div>
      <div className='lotheadingwhite'>
        <h3>
          Weekly Satta Matka Jodi Chart
        </h3>
        <p>Weekly Satta Matka Chart, Weekly Matka Best Jodi, Kalyan Weekly Matka Jodi, Weekly Satta Jodi, Weekly Matka Jodi, Weekly Matka Paper, Weekly Satta Matka Panel Chart, Weekly Matka Kalyan Chart, Kalyan Weekly Jodi, Milan Matka Weekly Jodi Panel, Weekly Matka Fix Jodi, Free Weekly Matka Jodi At @Sattamatkagods.</p>
        <h4>Weekly Kalyan Matka Jodi</h4>
        <p>Satta Matka User Get In This Page, Weekly Satta Matka, Weekly Satta, Matka Weekly Fix Jodi, Weekly Satta Matka Chart, Weekly Satta Jodi, Satta Matka Weekly Jodi Number, Satta Matka Weekly Guessing, Weekly Matka Open To Close, Weekly Satta Matka Paper Chart, Weekly Satta Matka Open To Close, Satta Matka Weekly Figure, Weekly Best Jodi Chart, Kalyan Weekly Best Jodi, Weekly Jodi Pana Matka, Satta Weekly Jodi Today, Main Mumbai Weekly Jodi Chart, Weekly Jodi Panna Patti, Matka India Net Weekly, Kalyan Weekly Line.</p>
      </div>
      <p>Weekly Matka Best Jodi Chart Every Week Update by worldsattamatka4u.com</p>
      <div className='subsectionblue'>
        <h4>
          SATTA MATKA WEEKLY GAME
        </h4>
        {/* <h5>Date: 12-Dec-2022 to 17-Dec-2022</h5> */}

      </div>
      
      <div className='subsectionwhite'>
        <h4>
          How To Play Satta Matka WeekLy Game ?
        </h4>
        <h5 className='text-left'>Satta Matka WeekLy Game :-</h5>
        <ol className='text-left text-left'>
          <li>figure & Panna :- Open To Close Play With Direction Of <b>worldsattamatka4u.com</b> Ex- MonDay To Tuesday Kalyan Open Play Then pass Or Fail when Pass Stop If Fail Figure Then Rs x 2 Play Matlab Double Karke khelo Respectivily.</li>
          <li>Matka Jodi :- When You Jodi Play If Pass Then stop If Fail Then running With Direction.</li>
          <li>Weekly Open Jodi Panna Sangam :- App Ko Ye Sab Chij Full Week Running Khelna Hoga. Fail Ho To Daouble Karke bado Or Khelo iss khel me Himat Chahiye.</li>
         
        </ol>

      </div>
      {currentweeklyJodiPatti?.map((currentweeklyJodiPattiKKM: any, index: React.Key | null | undefined) => (
      <div  key={index} className='subsectionwhite'>
        <h4 className='text-danger'>Kalyan Matka | Kalyan Night | Main Bazar</h4>
        <h4 className='text-success'>
          SATTA MATKA WEEKLY GAME
        </h4>
        
        <h4 className='text-dark'>Date: {formatDate(currentweeklyJodiPattiKKM?.weekStartDate)} to {formatDate(currentweeklyJodiPattiKKM?.weekEndDate)}  Lottery Club: {(currentweeklyJodiPattiKKM.lotteryClub as any)?.name}</h4> 
        <div className='container'>
          <table className="table table-bordered table-success">
            <thead className='thead-light'>
              <tr>
                <th colSpan={2}>MON-TUE</th>
                {/* <th scope="col">Final Ank</th> */}
              </tr>
            </thead>
            <tbody>
              <tr>
                <th scope="row">Figure</th>
                <td dangerouslySetInnerHTML={{ __html:currentweeklyJodiPattiKKM?.monTueFigure}}/>
                  {/* {currentweeklyJodiPattiKKM?.monTueFigure} */}
                {/* </td> */}
              </tr>
              <tr>
                <th scope="row">Panel</th>
                <td dangerouslySetInnerHTML={{ __html:currentweeklyJodiPattiKKM?.monTuePanel}}/>
                  {/* {currentweeklyJodiPattiKKM?.monTuePanel} */}
                {/* </td> */}
              </tr>
              <tr>
                <th scope="row">MatkaJodi</th>
                <td dangerouslySetInnerHTML={{ __html:currentweeklyJodiPattiKKM?.monTueMatkaJodi}}/>
                  {/* {currentweeklyJodiPattiKKM?.monTueMatkaJodi} */}
                {/* </td> */}
              </tr>
            </tbody>
          </table>
          <table className="table table-bordered table-success">
            <thead className='thead-light'>
              <tr>
                <th colSpan={2}>WED-THU</th>
                {/* <th scope="col">Final Ank</th> */}
              </tr>
            </thead>
            <tbody>
              <tr>
                <th scope="row">Figure</th>
                <td dangerouslySetInnerHTML={{ __html:currentweeklyJodiPattiKKM?.wedThuFigure}}/>
                  {/* {currentweeklyJodiPattiKKM?.wedThuFigure} */}
                {/* </td> */}
              </tr>
              <tr>
                <th scope="row">Panel</th>
                <td dangerouslySetInnerHTML={{ __html:currentweeklyJodiPattiKKM?.wedThuPanel}}/>
                  {/* {currentweeklyJodiPattiKKM?.wedThuPanel} */}
                {/* </td> */}
              </tr>
              <tr>
                <th scope="row">MatkaJodi</th>
                <td dangerouslySetInnerHTML={{ __html:currentweeklyJodiPattiKKM?.wedThuMatkaJodi}}/>
                  {/* {currentweeklyJodiPattiKKM?.wedThuJodi} */}
                {/* </td> */}
              </tr>
            </tbody>
          </table>
          <table className="table table-bordered table-success">
            <thead className='thead-light'>
              <tr>
                <th colSpan={2}>Fri-Sat</th>
                {/* <th scope="col">Final Ank</th> */}
              </tr>
            </thead>
            <tbody>
              <tr>
                <th scope="row">Figure</th>
                <td dangerouslySetInnerHTML={{ __html:currentweeklyJodiPattiKKM?.friSatFigure}}/>
                  {/* {currentweeklyJodiPattiKKM?.friSatFigure} */}
                {/* </td> */}
              </tr>
              <tr>
                <th scope="row">Panel</th>
                <td dangerouslySetInnerHTML={{ __html:currentweeklyJodiPattiKKM?.friSatPanel}}/>
                  {/* {currentweeklyJodiPattiKKM?.friSatPanel} */}
                {/* </td> */}
              </tr>
              <tr>
                <th scope="row">MatkaJodi</th>
                <td dangerouslySetInnerHTML={{ __html:currentweeklyJodiPattiKKM?.friSatMatkaJodi}}/>
                  {/* {currentweeklyJodiPattiKKM?.friSatMatkaJodi} */}
                {/* </td> */}
              </tr>
            </tbody>
          </table>
          <table className="table table-bordered table-success">
            {/* <thead >
                      <tr>
                        <th>Fri-Sat</th>
                        <th scope="col">Final Ank</th>
                      </tr>
                    </thead> */}
            <tbody>
              <tr>
                <th rowSpan={2} className="text-middle subsectionblue ">Weekly Fix Ank</th>
                <td dangerouslySetInnerHTML={{ __html:currentweeklyJodiPattiKKM?.weeklyFixAnkOpen}}/>
                  {/* {currentweeklyJodiPattiKKM?.weeklyFixAnkOpen} */}
                {/* </td> */}
              </tr>
              <tr>
                {/* <th scope="row">Figure</th> */}
                <td dangerouslySetInnerHTML={{ __html:currentweeklyJodiPattiKKM?.weeklyFixAnkClose}}/>
                  {/* {currentweeklyJodiPattiKKM?.weeklyFixAnkClose} */}
                {/* </td> */}
              </tr>


            </tbody>
          </table>
          <table className="table table-bordered table-success">
            {/* <thead >
                      <tr>
                        <th>Fri-Sat</th>
                        <th scope="col">Final Ank</th>
                      </tr>
                    </thead> */}
            <tbody>
              <tr>
                <th rowSpan={2} className="text-middle subsectionblue ">Weekly Jodi</th>
                <td dangerouslySetInnerHTML={{ __html:currentweeklyJodiPattiKKM?.weeklyJodiOne}}/>
                  {/* {currentweeklyJodiPattiKKM?.weeklyJodiOne} */}
                {/* </td> */}
              </tr>
              <tr>
                {/* <th scope="row">Figure</th> */}
                <td dangerouslySetInnerHTML={{ __html:currentweeklyJodiPattiKKM?.weeklyJodiTwo}}/>
                  {/* {currentweeklyJodiPattiKKM?.weeklyJodiTwo} */}
                {/* </td> */}
              </tr>
            </tbody>
          </table>
          <table className="table table-bordered table-success">
            {/* <thead >
                      <tr>
                        <th>Fri-Sat</th>
                        <th scope="col">Final Ank</th>
                      </tr>
                    </thead> */}
            <tbody>
              <tr>
                <th rowSpan={2} className="text-middle subsectionblue ">Weekly Panel</th>
                <td dangerouslySetInnerHTML={{ __html:currentweeklyJodiPattiKKM?.weeklyPanelOne}}/>
                  {/* {currentweeklyJodiPattiKKM?.weeklyPanelOne} */}
                {/* </td> */}
              </tr>
              <tr>
                {/* <th scope="row">Figure</th> */}
                <td dangerouslySetInnerHTML={{ __html:currentweeklyJodiPattiKKM?.weeklyPanelTwo}}/>
                {/* {currentweeklyJodiPattiKKM?.weeklyPanelTwo} */}
                {/* </td> */}
              </tr>


            </tbody>
          </table>
          <table className="table table-bordered table-success">
            {/* <thead >
                      <tr>
                        <th>Fri-Sat</th>
                        <th scope="col">Final Ank</th>
                      </tr>
                    </thead> */}
            <tbody>
              <tr>
                <th rowSpan={2} className="text-middle subsectionblue ">Weekly Sangam</th>
                <td dangerouslySetInnerHTML={{ __html:currentweeklyJodiPattiKKM?.weeklySangamOne}}/>
                  {/* {currentweeklyJodiPattiKKM?.weeklySangamOne} */}
                {/* </td> */}
              </tr>
              <tr>
                {/* <th scope="row">Figure</th> */}
                <td dangerouslySetInnerHTML={{ __html:currentweeklyJodiPattiKKM?.weeklySangamTwo}}/>
                  {/* {currentweeklyJodiPattiKKM?.weeklySangamTwo} */}
                {/* </td> */}
              </tr>


            </tbody>
          </table>
        </div>
        <h4 className='bg-danger'><a href="/">www.worldsattamatka4u.com</a></h4>


      </div>
      ))}
      {/* MILAN DAY MILAN NIGHT RESULT starts */}
   
      {/* RAJDHANI DAY RAJDHANI NIGHT RESULT ENDS */}
      {/* Milan Day Result */}
      {/* <div className='subsectionwhite'>
        <h4>Milan Day</h4>
        <h6>700-7</h6>
        <h4>Milan Night</h4>
        <h6>148-30-334</h6>
      </div> */}
      <div className='subsectionwhite'>
        <h5 className='text-danger'>Weekly Open Close Satta Jodi Chart</h5>
        <p className='text-success'>If you want to know the result of Weekly Satta Matka, then you are in the right place. Get here 100% fixed Weekly Kalyan Matka Chart, on satta matka live. We also offer 100% fixed leak numbers for all kalyan games like Weekly Kalyan Matka, Weekly Milan Matka Jodi.</p>
        <h5 className='text-danger'>Do You Want To Get 100% Fixed Kalyan Jodi Numbers on Satta Matka?</h5>
        <p className='text-success'>Get 100% fixed kalyan Jodi numbers on satta matka live. Our experts make kalyan matka lovers guess correct weekly kalyan Jodi numbers. We provide a 100% fix leak number for all kalyan games. If you are losing continuously in the kalyan satta matka game, instantly consult our satta matka Live's dedicated kalyan matka experts. You can live chat on our forum platform. Our team will help you win kalyan winning jackpot number and assure you to get back to the new winning strip again to recover your previous loss in kalyan matka.</p>
        <h5 className='text-danger'>Which Website is best for Satta Matka online Playing Platform?</h5>
        <p className='text-success'>Sattamatkagods.net is India's best satta matka online platform to play Indian matka game free. Moreover we also all related information such as weekly matka live, weekly matka paper, weekly matka chart, weekly satta chart. Our satta expert delivers weekly matka tips and weekly satta tips with their experiences. Here players can get instant accurate live satta matka results. Our website is well optimized and designed so that players can get pieces of information and access easily. We cover all satta matka, matka king, satta king, matka india, satta result, satta matka, weekly Rajdhani matka Jodi, weekly Milan matka Jodi, weekly matka open close, matka Jodi charts, weekly panel charts. You will also get live satta record charts; all chart record results on time.</p>
        <h5 className='text-danger'>Why You Choose Our Satta Matka Website among Many?</h5>
        <p className='text-success'>Due to our best content and accurate genuine satta matka result, we became the most popular satta matka online website in India. Join our satta matka guessing forum, to get a reliable source for satta matka results & satta kalyan matka tips. Here the player will get latest updates, satta matka results, kalyan matka tips and tricks today online. After joining our satta matka guessing forum, you can discuss your queries for quick earnings.</p>
        <p className='text-success'>Join ever growing satta matka game website sattamatkagods.net, here is the best opportunity for people who are unemployed or uneducated to earn money. From this game, they can earn a huge amount of money to live a luxurious life. By following some tips and tricks they can become a satta king. We are the trusted online betting website where we help you earn the best by playing the satta matka game online.</p>

        <h5 className='text-danger'>How to Check Week Satta Matka Result?</h5>
        <h6 className='text-danger'>For checking weekly satta matka result follows these easy steps -:</h6>
        <ol className="text-left">
          <li>Open browser and go to the official site www.worldsattamatka4u.com</li>
          <li>Then you can see instant satta results of satta matka, weekly satta matka, satta matka, Milan market, weekly satta, weekly matka, kalyan matka results and online matka results.</li>
        </ol>
        <p className='text-danger'>Get fastest matka weekly pana chart, satta matka weekly result, satta weekly number chart, satta weekly chart, satta weekly Jodi result, satta weekly Jodi pana, matka weekly number, matka weekly Jodi, satta weekly Jodi fix, matka weekly Jodi today result, matka weekly guessing at the trustworthy www.sattamatkaABC.com</p>

        
      </div>
    </div>
  )
}
