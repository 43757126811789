import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import * as constants from '../../Constants/Constants'
import { useNavigate, NavLink } from 'react-router-dom';

import "./Panel.css"
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";

export default function Panel(props: any) {

  let navigate = useNavigate();
  const [columns, setColumns] = useState<number>(6);
  const [rows, setRows] = useState<any[]>();
  const [jodiData, setJodiData] = useState<any>({});
  const [events, setEvents] = useState<any>([]);
  const params = useParams();

  const lotteryId = params.lotId

  useEffect(() => {
    window.scrollTo(0, 0);
    fetch(`${constants.BASE_URL}daily-result/public/${lotteryId}/${constants.today}`, {
      method: 'GET',
      headers: { 'Content-Type': 'application/json', 'Accept': 'application/json', 'Authorization': constants.getAuthHeader() },
    }).then(function (response) {
      return response.json();
    }).then(function (response: any) {

      if (response.status == '200') {
        let totalColumns = response.result.noOfDays;
        setColumns(totalColumns);
        // totalColumns = 7;
        setJodiData(response.result);
        var totalRows: number = response.result?.dailyResultList.length / totalColumns;
        let rowArray: any[] = [];
        for (var i = 0; i < totalRows; i++) {
          let subArray = response.result?.dailyResultList.splice(0, totalColumns);
          // console.log(subArray ,"xyz")
          if (i < 3) {

          }
          if (subArray.length > 0) {
            rowArray.push({ firstDate: subArray[0].date, lastDate: subArray[subArray.length - 1].date, data: subArray });
          }

        }
        setRows(rowArray);
      } else {
        constants.showError(response.message);
      }
    });
  }, [lotteryId])

  const getLotteryClubJodi = () => {
    navigate((`/jodi/${lotteryId}`))
  }
  const getLotteryClubPanel = () => {
    navigate((`/panel/${lotteryId}`))
  }
  // function to change date format
  function formatDate(input: any) {
    let datePart = input.match(/\d+/g),
      year = datePart[0],
      // get only two digits 
      month = datePart[1],
      day = datePart[2];
    return day + '/' + month + '/' + year;
  }

  const [visible, setVisible] = useState(true);
  const [visible1, setVisible1] = useState(false)

  const toggleVisible = () => {
    const scrolled = document.documentElement.scrollTop;
    if (scrolled > 0) {
      setVisible(false)
    } else if (scrolled <= 0) {
      setVisible(true)
    }
  };

  const scrollToBottom = () => {
    document.getElementById("scroll1")!.scrollIntoView();
  }

  const toggleVisible1 = () => {
    const scrolled = document.documentElement.scrollTop;
    if (scrolled > 300) {
      setVisible1(true)
    }
    else if (scrolled <= 300) {
      setVisible1(false)
    }
  };

  const scrollToTop1 = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
      /* you can also use 'auto' behaviour
         in place of 'smooth' */
    });
  };


  // console.log(rows,"xyz")
  return (
    <div >

      <div className='container-fluid'>
        <div className="col-lg-12 col-md-12 lotheadingpink my-1">
        <a href="https://www.worldsattamatka4u.com/"><h5 className="colorblue text-link">worldsattamatka4u.com</h5></a>
        </div>
      </div>

      <div className='container-fluid'>
        <h3 className="colorblue">SATTA MATKA</h3>
        <div>In this satta matka chart page you get - milan morning matka, milan morning satta, milan morning satta matka chart, milan morning matka chart, milan morning satta chart, Milan morning matka chart, milan morning matka jodi chart, milan morning matka panel chart, milan morning matka record, milan morning paper chart.
        </div>
        <div className="col-lg-12 col-md-12 lotheadingwhite my-1">
          <h4 className="titlered121">{jodiData.lotteryClubName}</h4>
          <h5>{jodiData.result}</h5>
          <div className="time">{jodiData.openTime} -{jodiData.closeTime}
          </div>
          <div className=" d-flex justify-content-between">
            <div>
              <div onClick={() => getLotteryClubJodi()} className="jodi">JODI</div>
            </div>
            {/* <div className='jodi'>
                <div onClick={() => getLotteryClubPanel()} className="jodi">Panel</div>
              </div> */}
          </div>
        </div>
      </div>

      {/* table starts */}
      <div id='id1'>
        <button type='button' className="btn btn-success btn-sm m-2" onClick={scrollToBottom}>
          <i className="fa fa-arrow-down"
            style={{ display: visible ? 'inline' : 'none' }} > Go to bottom</i>
        </button></div>
      <div className="container">
        <h6 className='p-2 text-danger'>Panel chart for {jodiData.lotteryClubName}</h6>
        <table className="table table-light panel-table tabledata">
          <thead>
            <tr className='boldjodi1'>
              <th scope="col">Date</th>
              <th scope="col">Mon</th>
              <th scope="col">Tue</th>
              <th scope="col">Wed</th>
              <th scope="col">Thu</th>
              {columns > 4 &&
                <th scope="col">Fri</th>
              }
              {columns > 5 &&
                <th scope="col">Sat</th>
              }
              {columns > 6 &&
                <th scope="col">Sun</th>
              }
            </tr>
          </thead>
          <tbody>
            {rows?.map((outerData: any, index: number) => (

              <tr key={index}>
                <td scope="row" className="boldjodi1 p-0">{formatDate(outerData?.firstDate)} <br />to<br /> {formatDate(outerData?.lastDate)}</td>
                {outerData?.data?.map((innerData: any, innerIndex: number) => (
                  <td scope="row" className=" p-0" key={innerIndex}>
                    <div className='d-flex justify-content-between '>
                      <div className="d-flex flex-column">
                        <div className={(innerData.result.substring(4, 6) == 11) || (innerData.result.substring(4, 6) == 22) || (innerData.result.substring(4, 6) == 33) || (innerData.result.substring(4, 6) == 44) || (innerData.result.substring(4, 6) == 55) || (innerData.result.substring(4, 6) == 66) || (innerData.result.substring(4, 6) == 77) || (innerData.result.substring(4, 6) == 88) || (innerData.result.substring(4, 6) == 99) || (innerData.result.substring(4, 6) == 61) || (innerData.result.substring(4, 6) == 16) || (innerData.result.substring(4, 6) == 72) || (innerData.result.substring(4, 6) == 27) || (innerData.result.substring(4, 6) == 83) || (innerData.result.substring(4, 6) == 38 || (innerData.result.substring(4, 6) == 94) || (innerData.result.substring(4, 6) == 49 || (innerData.result.substring(4, 6) == "05") || (innerData.result.substring(4, 6) == 50 || (innerData.result.substring(4, 6) == "00")))) ? "rp boldjodi1r" : "rp boldjodi1"}>{innerData.result.substring(0, 1)}</div>
                        <div className={(innerData.result.substring(4, 6) == 11) || (innerData.result.substring(4, 6) == 22) || (innerData.result.substring(4, 6) == 33) || (innerData.result.substring(4, 6) == 44) || (innerData.result.substring(4, 6) == 55) || (innerData.result.substring(4, 6) == 66) || (innerData.result.substring(4, 6) == 77) || (innerData.result.substring(4, 6) == 88) || (innerData.result.substring(4, 6) == 99) || (innerData.result.substring(4, 6) == 61) || (innerData.result.substring(4, 6) == 16) || (innerData.result.substring(4, 6) == 72) || (innerData.result.substring(4, 6) == 27) || (innerData.result.substring(4, 6) == 83) || (innerData.result.substring(4, 6) == 38 || (innerData.result.substring(4, 6) == 94) || (innerData.result.substring(4, 6) == 49 || (innerData.result.substring(4, 6) == "05") || (innerData.result.substring(4, 6) == 50 || (innerData.result.substring(4, 6) == "00")))) ? "rp boldjodi1r" : "rp boldjodi1"}>{innerData.result.substring(1, 2)}</div>
                        <div className={(innerData.result.substring(4, 6) == 11) || (innerData.result.substring(4, 6) == 22) || (innerData.result.substring(4, 6) == 33) || (innerData.result.substring(4, 6) == 44) || (innerData.result.substring(4, 6) == 55) || (innerData.result.substring(4, 6) == 66) || (innerData.result.substring(4, 6) == 77) || (innerData.result.substring(4, 6) == 88) || (innerData.result.substring(4, 6) == 99) || (innerData.result.substring(4, 6) == 61) || (innerData.result.substring(4, 6) == 16) || (innerData.result.substring(4, 6) == 72) || (innerData.result.substring(4, 6) == 27) || (innerData.result.substring(4, 6) == 83) || (innerData.result.substring(4, 6) == 38 || (innerData.result.substring(4, 6) == 94) || (innerData.result.substring(4, 6) == 49 || (innerData.result.substring(4, 6) == "05") || (innerData.result.substring(4, 6) == 50 || (innerData.result.substring(4, 6) == "00")))) ? "rp boldjodi1r" : "rp boldjodi1"}>{innerData.result.substring(2, 3)}</div>
                      </div>
                      <div className="d-flex flex-column">
                        <div className=""> </div>
                        <div className={(innerData.result.substring(4, 6) == 11) || (innerData.result.substring(4, 6) == 22) || (innerData.result.substring(4, 6) == 33) || (innerData.result.substring(4, 6) == 44) || (innerData.result.substring(4, 6) == 55) || (innerData.result.substring(4, 6) == 66) || (innerData.result.substring(4, 6) == 77) || (innerData.result.substring(4, 6) == 88) || (innerData.result.substring(4, 6) == 99) || (innerData.result.substring(4, 6) == 61) || (innerData.result.substring(4, 6) == 16) || (innerData.result.substring(4, 6) == 72) || (innerData.result.substring(4, 6) == 27) || (innerData.result.substring(4, 6) == 83) || (innerData.result.substring(4, 6) == 38 || (innerData.result.substring(4, 6) == 94) || (innerData.result.substring(4, 6) == 49 || (innerData.result.substring(4, 6) == "05") || (innerData.result.substring(4, 6) == 50 || (innerData.result.substring(4, 6) == "00")))) ? "align-self-center  m-auto boldjodir" : "align-self-center  m-auto boldjodi "}> {innerData.result.substring(4, 6)}</div>
                        <div className=""> </div>
                      </div>
                      <div className="d-flex flex-column">
                        <div className={(innerData.result.substring(4, 6) == 11) || (innerData.result.substring(4, 6) == 22) || (innerData.result.substring(4, 6) == 33) || (innerData.result.substring(4, 6) == 44) || (innerData.result.substring(4, 6) == 55) || (innerData.result.substring(4, 6) == 66) || (innerData.result.substring(4, 6) == 77) || (innerData.result.substring(4, 6) == 88) || (innerData.result.substring(4, 6) == 99) || (innerData.result.substring(4, 6) == 61) || (innerData.result.substring(4, 6) == 16) || (innerData.result.substring(4, 6) == 72) || (innerData.result.substring(4, 6) == 27) || (innerData.result.substring(4, 6) == 83) || (innerData.result.substring(4, 6) == 38 || (innerData.result.substring(4, 6) == 94) || (innerData.result.substring(4, 6) == 49 || (innerData.result.substring(4, 6) == "05") || (innerData.result.substring(4, 6) == 50 || (innerData.result.substring(4, 6) == "00")))) ? "rp boldjodi1r" : "rp boldjodi1"}>{innerData.result.substring(7, 8)}</div>
                        <div className={(innerData.result.substring(4, 6) == 11) || (innerData.result.substring(4, 6) == 22) || (innerData.result.substring(4, 6) == 33) || (innerData.result.substring(4, 6) == 44) || (innerData.result.substring(4, 6) == 55) || (innerData.result.substring(4, 6) == 66) || (innerData.result.substring(4, 6) == 77) || (innerData.result.substring(4, 6) == 88) || (innerData.result.substring(4, 6) == 99) || (innerData.result.substring(4, 6) == 61) || (innerData.result.substring(4, 6) == 16) || (innerData.result.substring(4, 6) == 72) || (innerData.result.substring(4, 6) == 27) || (innerData.result.substring(4, 6) == 83) || (innerData.result.substring(4, 6) == 38 || (innerData.result.substring(4, 6) == 94) || (innerData.result.substring(4, 6) == 49 || (innerData.result.substring(4, 6) == "05") || (innerData.result.substring(4, 6) == 50 || (innerData.result.substring(4, 6) == "00")))) ? "rp boldjodi1r" : "rp boldjodi1"}>{innerData.result.substring(8, 9)}</div>
                        <div className={(innerData.result.substring(4, 6) == 11) || (innerData.result.substring(4, 6) == 22) || (innerData.result.substring(4, 6) == 33) || (innerData.result.substring(4, 6) == 44) || (innerData.result.substring(4, 6) == 55) || (innerData.result.substring(4, 6) == 66) || (innerData.result.substring(4, 6) == 77) || (innerData.result.substring(4, 6) == 88) || (innerData.result.substring(4, 6) == 99) || (innerData.result.substring(4, 6) == 61) || (innerData.result.substring(4, 6) == 16) || (innerData.result.substring(4, 6) == 72) || (innerData.result.substring(4, 6) == 27) || (innerData.result.substring(4, 6) == 83) || (innerData.result.substring(4, 6) == 38 || (innerData.result.substring(4, 6) == 94) || (innerData.result.substring(4, 6) == 49 || (innerData.result.substring(4, 6) == "05") || (innerData.result.substring(4, 6) == 50 || (innerData.result.substring(4, 6) == "00")))) ? "rp boldjodi1r" : "rp boldjodi1"}>{innerData.result.substring(9, 10)}</div>
                      </div>
                    </div>
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
        {/* <FullCalendar plugins={[dayGridPlugin]} initialView="dayGridMonth" weekends={true}
              events={events} eventContent={renderEventContent}
            /> */}
      </div>
      {/* table ends */}

      <div id="scroll1">
        <button type='button' className="btn btn-success m-2" onClick={scrollToTop1} >
          <i className="fa fa-arrow-up"
            style={{ display: visible ? 'inline' : 'none' }} > Go to top</i>
        </button></div>
      <div className='container-fluid'>
      <div className="col-lg-12 col-md-12 lotheadingwhite my-1">
              <div className="col-lg-12 col-md-12 my-1 ">
                <div className="Imp_content">Important Links :-</div>
              </div>
              <div className="col-lg-12 col-md-12 my-1 ">
                <NavLink to='/matkaguessing'>
                  <h4><div className="Guessing_Link">Matka Guessing Forum</div> </h4>
                </NavLink>
              </div>

              <div className="col-lg-12 col-md-12 my-1 ">
                <a href="/all-satta-matka-charts"><h4><div className="Guessing_Link">All Satta Matka Charts</div> </h4></a>
              </div>

            </div>
      </div>

    </div>
  )
}

function renderEventContent(eventInfo: any) {
  return (
    <>

      <div className='d-flex justify-content-between '>
        <div className="d-flex flex-column">
          <div className="p-1 boldjodi1"><b>{eventInfo.event.title.substring(0, 1)}</b></div>
          <div className="p-1 boldjodi1"><b>{eventInfo.event.title.substring(1, 2)}</b></div>
          <div className="p-1 boldjodi1"><b>{eventInfo.event.title.substring(2, 3)}</b></div>
        </div>
        <div className="d-flex flex-column">
          <div className="p-1"> </div>
          {/* <div className="p-2"> 1</div>  */}
          <div className="align-self-center pt--1 px-3 m-auto boldjodi"> <b>{eventInfo.event.title.substring(4, 5)}{eventInfo.event.title.substring(5, 6)}</b></div>

          {/* <div className="align-self-center pt-4"><b>66</b></div> */}
          <div className="p-1"> </div>
        </div>
        <div className="d-flex flex-column">
          <div className="p-1 boldjodi1"><b>{eventInfo.event.title.substring(7, 8)}</b></div>
          <div className="p-1 boldjodi1"><b>{eventInfo.event.title.substring(8, 9)}</b></div>
          <div className="p-1 boldjodi1"><b>{eventInfo.event.title.substring(9, 10)}</b></div>
        </div>

      </div>
      {/* <b>{eventInfo.event.title}</b> */}
    </>
  );
}

