import React, { useEffect, useState } from 'react'
import { NavLink } from 'react-router-dom'
import ProfileSidebar from '../../ProfileSidebar/ProfileSidebar'
import * as constants from '../../../Constants/Constants'
import { useForm } from 'react-hook-form';
import ReactPaginate from 'react-paginate';
import './SattaFixGame.css';

declare var $: any;

export default function SattaFixGame() {

  const { register, handleSubmit, setValue, formState: { errors }, reset } = useForm();
  const [formSubmitLoader, setFormSubmitLoader] = useState<boolean>(false);
  const [sfgList, setSfgList] = useState<any[]>();
  const [currentDate, setCurrentDate] = useState<string>(new Date().toISOString().split("T")[0]);

  useEffect(() => {
    getSfgList();
  }, [currentDate]);

  const getSfgList = () => {
    let queryParam = currentDate ? '?date=' + currentDate : '';
    fetch(constants.BASE_URL + 'lottery-fix-game' + queryParam, {
      method: 'GET',
      headers: { 'Content-Type': 'application/json', 'Accept': 'application/json', 'Authorization': constants.getAuthHeader() },
    }).then(function (response) {
      return response.json();
    }).then(function (response: any) {
      if (response.status == '200') {
        setSfgList(response.result);
      }
    });
  }

  const prepareAddSfg = () => {
    $('#sfg-modal').modal('show');
  }

  const addSfg = (data: any) => {
    setFormSubmitLoader(true);
    fetch(constants.BASE_URL + 'lottery-fix-game', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json', 'Accept': 'application/json', 'Authorization': constants.getAuthHeader() },
      body: JSON.stringify(data)
    }).then(function (response) {
      return response.json();
    }).then(function (response: any) {
      setFormSubmitLoader(false);
      if (response.status == '201') {
        reset();
        $('#sfg-modal').modal('hide');
        constants.showSuccess('Request processed successfully');
        getSfgList();
      } else {
        constants.showError(response.message);
      }
    });
  }

  const prepareEditSfg = (sfgId: string) => {

  }

  const onDateChange = (e: any) => {
    setCurrentDate(e.target.value);
  }

  return (
    <div className='page-content'>
      <section className="page-header page-header-dark bg-secondary">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-8">
              <h2 className='admin-heading mb-0 text-white'>ADMIN PANEL</h2>
            </div>
            <div className="col-md-4">

            </div>
          </div>
        </div>
      </section>
      <div className="container-fluid p-5 mb-2">
        <div className="row">
          <div className="col-lg-2">
            <ProfileSidebar />
          </div>
          <div className="col-lg-10">
            <div className="bg-white shadow-md rounded p-4">
              <div className='row'>
                <div className='col'>
                  <h4 className="mb-2 text-left">Satta Fix Game</h4>
                </div>
                {/* <div className='col-lg-4'>
                  <input type="text" placeholder='Search by service name' className='form-control form-control-sm w-50 float-end'
                    onChange={searchByName} />
                </div> */}
                <div className='col'>
                  <input type="date" className='form-control float-right w-50' value={currentDate} onChange={onDateChange}/>
                </div>
                <div className='col-2'>
                  <button type='button' className="btn btn-info btn-sm float-right" onClick={prepareAddSfg}>Add New Result</button>
                </div>
              </div>
              <div className="tab-content my-3" id="myTabContent">
                <div className="tab-pane fade show active" id="first" role="tabpanel" aria-labelledby="first-tab">
                  <div className="table-responsive-md">
                    <table className="table table table-striped table-hover text-dark">
                      <thead >
                        <tr>
                          <th>Sr No</th>
                          <th>Name</th>
                          <th>Date</th>
                          <th>Haruf</th>
                          <th>Jodi</th>
                          <th>Fix Jodi</th>
                          {/* <th>Action</th> */}
                        </tr>
                      </thead>
                      <tbody>
                        {sfgList?.map((data: any, index: number) => (
                          <tr key={index}>
                            <td className="align-middle">{index + 1}</td>
                            <td className="align-middle">{data.name}</td>
                            <td className="align-middle">{data.date}</td>
                            <td className="align-middle">{data.haruf}</td>
                            <td className="align-middle">{data.jodi}</td>
                            <td className="align-middle">{data.fixJodi}</td>
                            {/* <td className="align-middle">
                              <button className="featured-box-icon bg-primary text-white rounded-circle border-0 edit-icon"
                                onClick={() => prepareEditLotteryClub(data.lotteryId)}>
                                <i className="fas fa-edit"></i>
                              </button>
                            </td> */}
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="sfg-modal" className="modal fade" role="dialog" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered modal-shadow" role="document">
          <div className="modal-content border-0">
            <div className="modal-header">
              <h5 className="modal-title">Satta Fix Game</h5>
              <button type="button" className="btn-close" data-dismiss="modal" aria-label="Close"></button>
            </div>
            <form id="loginForm" method="post" onSubmit={handleSubmit(addSfg)}>
              <div className="modal-body py-4 px-0">
                <div className="row">
                  <div className="col-11 col-md-10 mx-auto">
                    {/* <input type="hidden" id="serviceId" {...register("serviceId", { required: true })}/> */}
                    <div className="mb-3">
                      <label className="number" htmlFor="name">Satta Name<span className='star'>*</span></label>
                      <select className="form-control" id="name" {...register("name", { required: true })}>
                        <option disabled value="">--Select--</option>
                        <option value="Faridabad Satta">Faridabad Satta</option>
                        <option value="Gaziyabad Satta">Gaziyabad Satta</option>
                        <option value="Gali Satta King">Gali Satta King</option>
                        <option value="Disawar Satta">Disawar Satta</option>
                      </select>
                      {errors.name && <span className='text-danger'>Name is required </span>}
                    </div>
                    <div className="mb-3">
                      <label className="number" htmlFor="date">Date<span className='star'>*</span></label>
                      <input type="date" className="form-control" id="date" placeholder="Date"
                        {...register("date", { required: true })} />
                      {errors.date && <span className='text-danger'>Date is required </span>}
                    </div>
                    <div className="mb-3">
                      <label className="number" htmlFor="haruf">Haruf</label>
                      <input type="text" className="form-control" id="haruf" placeholder="haruf"
                        {...register("haruf", { required: false })} />
                    </div>
                    <div className="mb-3">
                      <label className="number" htmlFor="jodi">Jodi</label>
                      <input type="text" className="form-control" id="jodi" placeholder="Jodi"
                        {...register("jodi", { required: false })} />
                    </div>
                    <div className="mb-3">
                      <label className="number" htmlFor="fixJodi">Fix Jodi</label>
                      <input type="text" className="form-control" id="fixJodi" placeholder="Fix Jodi"
                        {...register("fixJodi", { required: false })} />
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <button type="button" className="btn btn-danger btn-sm" data-dismiss="modal">Close</button>
                <button type="submit" className="btn btn-primary btn-sm" disabled={formSubmitLoader}>
                  {formSubmitLoader && <i className="fas fa-circle-notch fa-spin me-2"></i>}
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  )
}
