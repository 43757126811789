import React, { useEffect, useState } from 'react'
import ProfileSidebar from '../../ProfileSidebar/ProfileSidebar'
import * as constants from '../../../Constants/Constants'
import { useForm } from 'react-hook-form';
import './Dashboard.css';

declare var $: any;

export default function Dashboard() {

  const [counts, setCounts] = useState<any>();

  useEffect(() => {
    getDashboardCounts();
  }, []);

  const getDashboardCounts = () => {
    fetch(constants.BASE_URL + 'dashboard/counts', {
      method: 'GET',
      headers: { 'Content-Type': 'application/json', 'Accept': 'application/json', 'Authorization': constants.getAuthHeader() },
    }).then(function (response) {
      return response.json();
    }).then(function (response: any) {
      if (response.status == '200') {
        setCounts(response.result);
      }
    });
  }

  return (
    <div className='page-content'>
      <section className="page-header page-header-dark bg-secondary">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-8">
              <h2 className='admin-heading mb-0 text-white'>ADMIN PANEL</h2>
            </div>
            <div className="col-md-4">

            </div>
          </div>
        </div>
      </section>
      <div className="container-fluid p-5 mb-2">
        <div className="row">
          <div className="col-lg-2">
            <ProfileSidebar />
          </div>
          <div className="col-lg-10">
            <div className="bg-white shadow-md rounded p-4">
              <div className='row'>
                <div className='col'>
                  <h4 className="mb-2 text-left">Dashboard</h4>
                </div>
                <div className='col'>
                </div>
              </div>
              <div className="tab-content my-3" id="myTabContent">
                <div className="row">
                  <div className="col-md-4">
                    <div className="dbox dbox--color-1">
                      <div className="dbox__body">
                        <p className="dbox__count mb-0">{counts?.userCount}</p>
                        <p className="dbox__title mb-0">Users</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="dbox dbox--color-2">
                      <div className="dbox__body">
                        <p className="dbox__count mb-0">{counts?.lotteryClubCount}</p>
                        <p className="dbox__title mb-0">Lottery Clubs</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="dbox dbox--color-3">
                      <div className="dbox__body">
                        <p className="dbox__count mb-0">18</p>
                        <p className="dbox__title mb-0">Matka Anks</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
