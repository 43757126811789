import React from 'react'
import "./MatkaTrickTips.css"

export default function MatkaTrickTips() {
    return (
        <>
            <div className='lotheadingwhite'>
                <h3>
                    Satta Matka Lifetime Trick Tips
                </h3>
                <p>Satta Matka Lifetime Trick Formula Chart Help You to Win Sattamatka. Today Perfect Kalyan Matka Lifetime Trick Tips, Kalyan Matka Formula, Matka Video, Matka Paper, and Matka Image Provide By @Sattamatkagods. Matka Video, Image, Paper, And Lifetime Open, Close, Jodi, And Panel Trick Latest Update Here.</p>
                <p>All Indian Matka User Get In This Page, Satta Matka Trick, Matka Lifetime Trick, Matka Formula, Datewise Matka Chart, Matka Jodi Total Trick, Matka Jodi Difference, Open To Close Matka Trick, Matka Panel Trick, Matka Running Ank Trick, 2 Ank Matka Trick, Matka Single Jodi Trick And Scheme, Kalyan Matka Lifetime Trick, Cut Ank Matka Trick, Weekly Matka Figure Trick, Final Ank Trick And Tips, Daily Kalyan Matka Trick, Perfect Matka Trick, Kalyan Matka Formula, Matka Trick Formula</p>
                <h4>Matka Lifetime Trick Formula</h4>
            </div>
            <div className='smallstripblue text-white my-1'>Matka Lifetime Trick Tips Updated by WorldSattamatka4u at worldsattamatka4u.com</div>
            <div className='lotheadingwhite'>
                <div className='bg-warning p-1 biggerfont'>KALYAN MATKA JODI TRICK<br />YEAR END SPECIAL TRICK</div><br />
                <div className='bg-white p-1 biggerfont'>KALYAN MATKA JODI TRICK-2022</div><br />
                <div className='bg-info p-1 bigfont'>14 Dec |20 Dec |21 Dec |22 Dec |27 Dec |29 Dec </div><br />
                <div className='bg-danger p-1 bigfont'><a href='/home'>worldsattamatka4u.com</a></div><br />
                <div className=' row col-lg-12' >
                    <div className='col-lg-6'><h5 className='bg-warning'>Set-1</h5>
                        <div className="container">
                            <table className="table table-bordered table-light tabledata">
                                <thead>
                                    <tr>
                                        <th scope="col">S</th>
                                        <th scope="col">M</th>
                                        <th scope="col">T</th>
                                        <th scope="col">W</th>
                                        <th scope="col">T</th>
                                        <th scope="col">F</th>
                                        <th scope="col">S</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td scope="row">11</td>
                                        <td>11</td>
                                        <td>11</td>
                                        <td>17</td>
                                        <td>11</td>
                                        <td>11</td>
                                        <td>17</td>


                                    </tr>
                                    <tr>
                                        <td scope="row">11</td>
                                        <td>22</td>
                                        <td>33</td>
                                        <td>44</td>
                                        <td>22</td>
                                        <td>33</td>
                                        <td>44</td>
                                    </tr>
                                    <tr>
                                        <td scope="row">11</td>
                                        <td>22</td>
                                        <td>33</td>
                                        <td>44</td>
                                        <td>22</td>
                                        <td>33</td>
                                        <td>44</td>
                                    </tr>
                                </tbody>
                            </table>

                        </div>
                    </div>
                    <div className='col-lg-6'><h5 className='bg-info'>Set-2</h5>
                        <div className="container">
                            <table className="table table-bordered table-light tabledata">
                                <thead>
                                    <tr>
                                        <th scope="col">S</th>
                                        <th scope="col">M</th>
                                        <th scope="col">T</th>
                                        <th scope="col">W</th>
                                        <th scope="col">T</th>
                                        <th scope="col">F</th>
                                        <th scope="col">S</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td scope="row">11</td>
                                        <td>11</td>
                                        <td>11</td>
                                        <td>17</td>
                                        <td>11</td>
                                        <td>11</td>
                                        <td>17</td>


                                    </tr>
                                    <tr>
                                        <td scope="row">11</td>
                                        <td>22</td>
                                        <td>33</td>
                                        <td>44</td>
                                        <td>22</td>
                                        <td>33</td>
                                        <td>44</td>
                                    </tr>
                                    <tr>
                                        <td scope="row">11</td>
                                        <td>22</td>
                                        <td>33</td>
                                        <td>44</td>
                                        <td>22</td>
                                        <td>33</td>
                                        <td>44</td>
                                    </tr>
                                </tbody>
                            </table>

                        </div></div>
                </div>
            </div>
            <div className='lotheadingwhite'>
                <div className='bg-white biggerfont p-1'>KALYAN MATKA JODI TRICK-2022</div><br />
                <div className='bg-info p-1 bigfont'>15 Dec|21 Dec  |23 Dec |30 Dec </div><br />
                {/* <div className='bg-danger p-1 bigfont'><a href='/home'>Sattamatkatoppers.com</a></div><br/> */}
                <div className=' row col-lg-12' >
                    <div className='col-lg-6'><h5 className='bg-warning'>Set-1</h5>
                        <div className="container">
                            <table className="table table-bordered table-light tabledata">
                                <thead>
                                    <tr>
                                        <th scope="col">S</th>
                                        <th scope="col">M</th>
                                        <th scope="col">T</th>
                                        <th scope="col">W</th>
                                        <th scope="col">T</th>
                                        <th scope="col">F</th>
                                        <th scope="col">S</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td scope="row">11</td>
                                        <td>11</td>
                                        <td>11</td>
                                        <td>17</td>
                                        <td>11</td>
                                        <td>11</td>
                                        <td>17</td>


                                    </tr>
                                    <tr>
                                        <td scope="row">11</td>
                                        <td>22</td>
                                        <td>33</td>
                                        <td>44</td>
                                        <td>22</td>
                                        <td>33</td>
                                        <td>44</td>
                                    </tr>
                                    <tr>
                                        <td scope="row">11</td>
                                        <td>22</td>
                                        <td>33</td>
                                        <td>44</td>
                                        <td>22</td>
                                        <td>33</td>
                                        <td>44</td>
                                    </tr>
                                </tbody>
                            </table>

                        </div>
                    </div>
                    <div className='col-lg-6'><h5 className='bg-info'>Set-2</h5>
                        <div className="container">
                            <table className="table table-bordered table-light tabledata">
                                <thead>
                                    <tr>
                                        <th scope="col">S</th>
                                        <th scope="col">M</th>
                                        <th scope="col">T</th>
                                        <th scope="col">W</th>
                                        <th scope="col">T</th>
                                        <th scope="col">F</th>
                                        <th scope="col">S</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td scope="row">11</td>
                                        <td>11</td>
                                        <td>11</td>
                                        <td>17</td>
                                        <td>11</td>
                                        <td>11</td>
                                        <td>17</td>


                                    </tr>
                                    <tr>
                                        <td scope="row">11</td>
                                        <td>22</td>
                                        <td>33</td>
                                        <td>44</td>
                                        <td>22</td>
                                        <td>33</td>
                                        <td>44</td>
                                    </tr>
                                    <tr>
                                        <td scope="row">11</td>
                                        <td>22</td>
                                        <td>33</td>
                                        <td>44</td>
                                        <td>22</td>
                                        <td>33</td>
                                        <td>44</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>

            </div>
            <div className='lotheadinglight'>
                <div className='titleentirelengthred biggerfont'>FINAL JODI AND PANEL DAILY</div>
                <div className='titleentirelengthblue biggerfont'>3 P.M. Update Hoga Website JODI AND PANEL DAILY</div>
                <div className='titleentirelengthwhite biggerfont'>www.worldsattamatka4u.com</div>
                <div className='titleentirelengthblue biggerfont'>FIX AND FINAL KALYAN GAME UPDATE TIME: 3:00 PM</div>
                <div className='titleentirelengthred biggerfont'>दुसरो को भी जरुर दिखाओ वेबसाइट</div>
            </div>
            <div className='lotheadingwhite'>
            <div className='titleentirelengthwhite biggerfont'>HOW TO PLAY THIS CHART?</div>
            <span className='smallstripblue biggerfont'>How to follow Kalyan Matka Lifetime Trick Chart?</span>
            <p>In this Lifetime Matka Chart You get Daily 4 Ank For Open to Close. How to Find Matka Four Figue for Today? WorldSattamatka4u Explain Details about this Lifetime Trick Chart.</p>
            <p>Rule Of This Chart:</p>
            <ul className='text-left'>
                <li>Vertical Line - Open</li>
                <li>Horizontal Line - Close</li>
           
            <li>Example-1: Today Jodi Come 76. Then You Get Four Digit For Next Day. Vertically You to 7 Open Number and Horizontally Go to 6 Close Number then at a point both are cross then you stop and Get 4 Golden Digit = 3 1 0 9 for Next Day.</li>
            <li>Example-2: Today Jodi Come 39. Then You Get Four Digit For Next Day. Vertically You to 3 Open Number and Horizontally Go to 9 Close Number then at a point both are cross then you stop and Get 4 Golden Digit = 4 3 6 7 for Next Day.</li>
            <li>Example-3: Today Jodi Come 48. Then You Get Four Digit For Next Day. Vertically You to 4 Open Number and Horizontally Go to 8 Close Number then at a point both are cross then you stop and Get 4 Golden Digit = 2 1 4 5 for Next Day</li>
            <li>I think Now you Understand this Lifetime Matka Chart.</li>
            </ul>
            <div className='titleentirelengthred biggerfont'>SATTA MATKA LIFETIME TRICK</div>
                <div className='titleentirelengthwhite biggerfont'>www.worldsattamatka4u.com</div>
                <div className='titleentirelengthblue biggerfont'>VISIT OUR WEBSITE HOW TO PLAY THIS CHART</div>
                <div className="container">
                            <table className="table table-bordered table-light tabledata">
                                <thead>
                                    <tr>
                                    <th scope="col">O/C</th>
                                        <th scope="col">0</th>
                                        <th scope="col">1</th>
                                        <th scope="col">2</th>
                                        <th scope="col">3</th>
                                        <th scope="col">4</th>
                                        <th scope="col">5</th>
                                        <th scope="col">6</th>
                                        <th scope="col">7</th>
                                        <th scope="col">8</th>
                                        <th scope="col">9</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td scope="row">0</td>
                                        <td>6459</td>
                                        <td>6281</td>
                                        <td>6897</td>
                                        <td>9821</td>
                                        <td>1758</td>
                                        <td>9567</td>
                                        <td>3857</td>
                                        <td>3291</td>
                                        <td>0378</td>
                                        <td>0613</td>
                                    </tr>
                                    <tr>
                                        <td scope="row">1</td>
                                        <td>9358</td>
                                        <td>3840</td>
                                        <td>2359</td>
                                        <td>8170</td>
                                        <td>5420</td>
                                        <td>1458</td>
                                        <td>7401</td>
                                        <td>5638</td>
                                        <td>9651</td>
                                        <td>7619</td>
                                      
                                    </tr>
                                    <tr>
                                    <td scope="row">2</td>
                                        <td>2741</td>
                                        <td>4936</td>
                                        <td>7968</td>
                                        <td>9847</td>
                                        <td>3140</td>
                                        <td>6930</td>
                                        <td>9153</td>
                                        <td>2910</td>
                                        <td>6795</td>
                                        <td>9850</td>
                                    </tr>
                                    <tr>
                                    <td scope="row">3</td>
                                        <td>2741</td>
                                        <td>4936</td>
                                        <td>7968</td>
                                        <td>9847</td>
                                        <td>3140</td>
                                        <td>6930</td>
                                        <td>9153</td>
                                        <td>2910</td>
                                        <td>6795</td>
                                        <td>9850</td>
                                    </tr>
                                    <tr>
                                    <td scope="row">4</td>
                                        <td>2741</td>
                                        <td>4936</td>
                                        <td>7968</td>
                                        <td>9847</td>
                                        <td>3140</td>
                                        <td>6930</td>
                                        <td>9153</td>
                                        <td>2910</td>
                                        <td>6795</td>
                                        <td>9850</td>
                                    </tr>
                                    <tr>
                                    <td scope="row">5</td>
                                        <td>2741</td>
                                        <td>4936</td>
                                        <td>7968</td>
                                        <td>9847</td>
                                        <td>3140</td>
                                        <td>6930</td>
                                        <td>9153</td>
                                        <td>2910</td>
                                        <td>6795</td>
                                        <td>9850</td>
                                    </tr>
                                    <tr>
                                    <td scope="row">6</td>
                                        <td>2741</td>
                                        <td>4936</td>
                                        <td>7968</td>
                                        <td>9847</td>
                                        <td>3140</td>
                                        <td>6930</td>
                                        <td>9153</td>
                                        <td>2910</td>
                                        <td>6795</td>
                                        <td>9850</td>
                                    </tr>
                                    <tr>
                                    <td scope="row">7</td>
                                        <td>2741</td>
                                        <td>4936</td>
                                        <td>7968</td>
                                        <td>9847</td>
                                        <td>3140</td>
                                        <td>6930</td>
                                        <td>9153</td>
                                        <td>2910</td>
                                        <td>6795</td>
                                        <td>9850</td>
                                    </tr>
                                    <tr>
                                    <td scope="row">8</td>
                                        <td>2741</td>
                                        <td>4936</td>
                                        <td>7968</td>
                                        <td>9847</td>
                                        <td>3140</td>
                                        <td>6930</td>
                                        <td>9153</td>
                                        <td>2910</td>
                                        <td>6795</td>
                                        <td>9850</td>
                                    </tr>
                                    <tr>
                                    <td scope="row">9</td>
                                        <td>2741</td>
                                        <td>4936</td>
                                        <td>7968</td>
                                        <td>9847</td>
                                        <td>3140</td>
                                        <td>6930</td>
                                        <td>9153</td>
                                        <td>2910</td>
                                        <td>6795</td>
                                        <td>9850</td>
                                    </tr>
                                    
                                    
                                   
                                </tbody>
                            </table>
                            <div className='lotheadingpink1 biggerfont'>
                                 www.worldsattamatka4u.com .This matka chart is used for Kalyan ,Milan ,Rajdhani,Kalyan Night ,Main Bazar ,Shridevi,Supreme and Main Mumbai.Share this chart to all.
                            </div>

                        </div>
                        
            </div>
            <div className='lotheadingwhite'>
                <div className='bg-white biggerfont p-1'>DATEWISE MATKA CHART - SATTA MATKA</div>
                <h6>Datewise Matka Chart Help you to win all Satta Matka Market. This Chart Using for Kalyan, Milan, Rajdhani, Time Bazar, Main Bazar, Kalyan Night, and Old Main Mumbai.</h6>
                <div className='bg-info p-1 biggerfont'><h2>DATEWISE MATKA CHART</h2></div>
                <div className='bg-danger p-1 biggerfont'><a href='/'>worldsattamatka4u.com</a></div>
                <div className=' col-lg-12' >
                        <div className="container">
                            <table className="table table-bordered table-light tabledata">
                                <thead>
                                    <tr>
                                        <th scope="col">Date</th>
                                        <th scope="col">Ank</th>
                                        <th scope="col">Date</th>
                                        <th scope="col">Ank</th>
                                        <th scope="col">Date</th>
                                        <th scope="col">Ank</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td scope="row">1</td>
                                        <td>9048</td>
                                        <td>11</td>
                                        <td>2784</td>
                                        <td>21</td>
                                        <td>4013</td>
                                      

                                    </tr>
                                    <tr>
                                        <td scope="row">2</td>
                                        <td>4508</td>
                                        <td>12</td>
                                        <td>1709</td>
                                        <td>22</td>
                                        <td>9210</td>
                                       
                                    </tr>
                                    <tr>
                                        <td scope="row">3</td>
                                        <td>5274</td>
                                        <td>13</td>
                                        <td>5690</td>
                                        <td>23</td>
                                        <td>4325</td>
                                        
                                    </tr>
                                    <tr>
                                        <td scope="row">4</td>
                                        <td>8529</td>
                                        <td>14</td>
                                        <td>8361</td>
                                        <td>24</td>
                                        <td>5714</td>
                                        
                                    </tr>
                                    <tr>
                                        <td scope="row">5</td>
                                        <td>1249</td>
                                        <td>15</td>
                                        <td>2081</td>
                                        <td>25</td>
                                        <td>8120</td>
                                        
                                    </tr>
                                    <tr>
                                        <td scope="row">6</td>
                                        <td>9265</td>
                                        <td>16</td>
                                        <td>3706</td>
                                        <td>26</td>
                                        <td>9062</td>
                                        
                                    </tr>
                                    <tr>
                                        <td scope="row">7</td>
                                        <td>8031</td>
                                        <td>17</td>
                                        <td>8417</td>
                                        <td>27</td>
                                        <td>3259</td>
                                        
                                    </tr>
                                    <tr>
                                        <td scope="row">8</td>
                                        <td>9516</td>
                                        <td>18</td>
                                        <td>5820</td>
                                        <td>28</td>
                                        <td>5480</td>
                                        
                                    </tr>
                                    <tr>
                                        <td scope="row">9</td>
                                        <td>2350</td>
                                        <td>19</td>
                                        <td>6713</td>
                                        <td>29</td>
                                        <td>0178</td>
                                        
                                    </tr>
                                    <tr>
                                        <td scope="row">10</td>
                                        <td>4580</td>
                                        <td>20</td>
                                        <td>5476</td>
                                        <td>30</td>
                                        <td>4205</td>
                                        
                                    </tr>
                                    <tr>
                                        <td scope="row"></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td>31</td>
                                        <td>6982</td>
                                        
                                    </tr>


                                </tbody>
                            </table>

                        </div>
                    
                   
                </div>
            </div>
            <div className='lotheadingwhite'>
            <div className='bg-white biggerfont p-1'>MATKA JODI TOTAL</div>
            <div className='lotheadingpink1'>
                <span>Finding a Jodi Total is too simple. Fast You Know this Rule. In this Ways You see 2 Case i.e</span><br/>
                <span>Rules-1: Open + Close = Jodi Total</span><br/>
                <span>Case-1 = Assume a Jodi i.e. 25</span><br/>
                <span>According to the Rule-1</span><br/>
                <span>Now You Get Jodi Total = 7</span><br/>
                <span>Case-2 = Assume a Jodi i.e. 96</span><br/>
                <span>Accourding to the Rule-1</span><br/>
                <span>9 + 6 = 15</span><br/>
                <span>Note: If Jodi total More then 9, the last figure is Considered as a Jodi Total.</span><br/>
                <span>So Now You Get Jodi Total - 5</span><br/>

            </div>
            </div>
            <div className='lotheadingwhite'>
                <div className='bg-white biggerfont p-1'>DATEWISE MATKA CHART - SATTA MATKA</div>
                <h6>Datewise Matka Chart Help you to win all Satta Matka Market. This Chart Using for Kalyan, Milan, Rajdhani, Time Bazar, Main Bazar, Kalyan Night, and Old Main Mumbai.</h6>
                <div className='bg-info p-1 biggerfont'><h2>DATEWISE MATKA CHART</h2></div>
                <div className='bg-danger p-1 biggerfont'><a href='/'>worldsattamatka4u.com</a></div>
                <div className=' col-lg-12' >
                        <div className="container">
                            <table className="table table-bordered table-light tabledata biggerfont font-weight-bold">
                                <thead>
                                    <tr>
                                        <th scope="col">ANK</th>
                                        <th scope="col">Open to Close</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td scope="row">0-5</td>
                                        <td>2864</td>
                                    </tr>
                                    <tr>
                                        <td scope="row">1-6</td>
                                        <td>3725</td>
                                    </tr>
                                    <tr>
                                        <td scope="row">2-7</td>
                                        <td>4681</td>
                                    </tr>
                                    <tr>
                                        <td scope="row">3-9</td>
                                        <td>5097</td>
                                    </tr>
                                    <tr>
                                        <td scope="row">4-11</td>
                                        <td>6908</td>
                                    </tr>
                                </tbody>
                            </table>

                        </div>
                        <div className='lotheadingpink1'>
                            <h3>Chart Used for-Kalyan ,Milan ,Rajdhani,Kalyan Night ,Main Bazar ,Shridevi,Supreme and Main Mumbai.Share this chart to all.Check with old Matka chart then start to play on your own risk.</h3>
                        </div>
                </div>
                <div className='bg-danger text-white'>Created By WorldSattamatka4u</div>
            </div>
            <div className='lotheadingwhite'>
            <div className='titleentirelengthblue biggerfont'>Matka Jodi Difference</div>

            <div className='lotheadingpink1'>
                <h3>How to Calculate Difference Of a Jodi?</h3>
                <span>Finding a Jodi Diffrence is too Easy. Fast You Know this Rule. In this Ways You see 2 Case i.e</span><br/>
                <span>Rules-2: Close-Open = Jodi Difference</span><br/>
                <span>Case-1 = Assume a Jodi i.e. 46</span><br/>
                <span>Accourding to the Rule-2</span><br/>
                <span>6 - 4 = 2</span><br/>
                <span>Now You Get Jodi Difference = 7</span><br/>
                <span>Case-2 = Assume a Jodi i.e. 97</span><br/>
                <span>Accourding to the Rule-2</span><br/>
                <span>7 - 9 = 8</span><br/>
                <span>Note: If Close Digit Smaller then Open Number then Think 7 = 17 then Use Rule</span><br/>
                <span>So Now You Get Jodi Difference - 8</span><br/>
               


            </div> 
                <div className='bg-white biggerfont p-1'>SATTA MATKA LIFETIME TRICK CHART</div>
                <div className='bg-white biggerfont p-1'>TRICK RULES-JODI DIFFERENCE SE 4 OPEN TO CLOSE</div>
                <div className='bg-danger p-1 biggerfont'><a href='/'>worldsattamatka4u.com</a></div>
                <div className=' col-lg-12' >
                        <div className="container">
                            <table className="table table-bordered table-light tabledata biggerfont font-weight-bold">
                                <thead>
                                    <tr>
                                        <th scope="col">ANK</th>
                                        <th scope="col">Open to Close</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td scope="row">0-5</td>
                                        <td>1490</td>
                                    </tr>
                                    <tr>
                                        <td scope="row">1-6</td>
                                        <td>3472</td>
                                    </tr>
                                    <tr>
                                        <td scope="row">2-7</td>
                                        <td>6248</td>
                                    </tr>
                                    <tr>
                                        <td scope="row">3-8</td>
                                        <td>9376</td>
                                    </tr>
                                    <tr>
                                        <td scope="row">4-9</td>
                                        <td>7410</td>
                                    </tr>
                                </tbody>
                            </table>

                        </div>
                        <div className='lotheadingpink1'>
                            <h3>Chart Used for-Kalyan ,Milan ,Rajdhani,Kalyan Night ,Main Bazar ,Shridevi,Supreme and Main Mumbai.Share this chart to all.Check with old Matka chart then start to play on your own risk.</h3>
                        </div>
                </div>
                <div className='bg-danger text-white'>Created By WorldSattamatka4u</div>
            </div>
            <div className='lotheadingwhite'>
            <div className='titleentirelengthblue biggerfont'>Satta Matka Final Ank</div>
            <div className='lotheadingpink1'>
                <h3>Matka Final Ank - Matka Trick Open Close</h3>
                <span>Get Daily Four Digit From MATKA FINAL ANK. How You Get Daily Final Ank? Go To WWW.SATTAMATKAGODS.NET and Check Daily update Final Ank By Official.</span><br/>
                <span>Example:</span><br/>
                <span>1. Today Kalyan Final Ank = 2, Then Today Four Ank = 3 1 6 7</span><br/>
                <span>1. Today Milan Final Ank = 8, Then Today Four Ank = 5 4 1 9</span><br/>
               


            </div> 
                <div className='bg-white biggerfont p-1'>FINAL ANK SE 4 ANK</div>
                <div className='bg-danger p-1 biggerfont'><a href='/'>worldsattamatka4u.com</a></div>
                <div className=' col-lg-12' >
                        <div className="container">
                            <table className="table table-bordered table-light tabledata biggerfont font-weight-bold">
                                <thead>
                                    <tr>
                                        <th scope="col"> FINAL ANK</th>
                                        <th scope="col">4 ANK 0 T C </th>
                                        <th scope="col"> FINAL ANK</th>
                                        <th scope="col">4 ANK 0 T C</th>

                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td scope="row">0</td>
                                        <td>7825</td>
                                        <td>5</td>
                                        <td>2968</td>

                                    </tr>
                                    <tr>
                                    <td scope="row">1</td>
                                        <td>4219</td>
                                        <td>6</td>
                                        <td>5193</td>
                                    </tr>
                                    <tr>
                                    <td scope="row">2</td>
                                        <td>3167</td>
                                        <td>7</td>
                                        <td>3650</td>
                                    </tr>
                                    <tr>
                                    <td scope="row">3</td>
                                        <td>2764</td>
                                        <td>8</td>
                                        <td>5419</td>
                                    </tr>
                                    <tr>
                                    <td scope="row">4</td>
                                        <td>0581</td>
                                        <td>9</td>
                                        <td>5073</td>
                                    </tr>
                                </tbody>
                            </table>

                        </div>
                        <div className='lotheadingpink1'>
                            <h3>IF YOU WANT DAILY SATTA MATKA FINAL ANK THEN VISIT worldsattamatka4u.com .Chart Used for-Kalyan ,Milan ,Rajdhani,Kalyan Night ,Main Bazar ,Shridevi,Supreme and Main Mumbai.Share this chart to all.Check with old Matka chart then start to play on your own risk.</h3>
                        </div>
                </div>
                <div className='bg-danger text-white'>Created By WorldSattamatka4u</div>
            </div>
            <div className="col-lg-12 col-md-12 lotheadingblue
       textalign borderviolet1  my-1">
                <div>
                  <h3 className="whitecolor"><b>⇘ कल्याण मटका बाज़ार ⇙</b></h3>
                  <h4 className="whitecolor">सनिबार कल्याण मटका सिंगल लाल जोड़ी 72 पास और ओपन पैनल 557 पास हुई पूरी जनता के सामने</h4>
                  <h5 className="whitecolor">दिनांक:- 5-Dec-2022 - सोमबार</h5>
                  <h3 className="whitecolor">सिंगल अचुक जोड़ी</h3>
                  <h3 className="whitecolor">सिंगल अचुक ओपन पैनल</h3>
                  <h5 className="whitecolor">एडवांस फीस रस 5000 कॉल - WorldSattamatka4u</h5>
                  <h4 className="whitecolor">
                    <span>+91xxxxxxxxxx</span>
                  </h4>
                  <h5 className="whitecolor">गेम फ़ैल हुआ तब पैसा बापिस मिलेगा</h5>
                </div>
              </div>
              <div className='lotheadingwhite'>
              <div className=' col-lg-12' >
                        <div className="container">
                            <table className="table table-bordered table-light tabledata biggerfont font-weight-bold">
                                <thead>
                                    <tr>
                                        <th colSpan={2}>TODAY GOLDEN NUMBER</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td scope="row">LUCKEY NUMBER</td>
                                        <td>MOTOR PATTI</td>
                                    </tr>
                                    <tr>
                                        <td scope="row">156</td>
                                        <td>234890</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div className='lotheadingpink1'>
                            <h3>Chart Used for-Kalyan ,Milan ,Rajdhani,Kalyan Night ,Main Bazar ,Shridevi,Supreme and Main Mumbai.Share this chart to all.Check with old Matka chart then start to play on your own risk.</h3>
                        </div>
                </div>
              </div>
              <div className="col-lg-12 col-md-12 lotheadingwhite my-1">
                <h4 className="titlered">Dec 6 2022</h4>
                <h5 className="greencolor">Satta king - fix satta haruf and fix satta king Jodi publish by WorldSattamatka4u. In this section you get fix three haruf, fix satta Jodi and today satta king single fix Jodi. Faridabad satta king number, today Ghaziabad satta Jodi, Gali satta king Jodi, and Disawar satta tips for a public update every day 3:00 pm. Get quickly free satta number, today satta tips, fix satta game, satta fix Jodi, and satta kalyan at WorldSattamatka4u</h5>
              </div>    
              <div className="col-lg-12 col-md-12 lotheadingwhite my-1">
                <h4 className="titlered"><span className="spanviolet">Faridabad Satta</span></h4>
                <h5 className="redcolor">Haruf-270</h5>
                <div className="greencolor">Jodi -27 72 01 10 64 46 91 19
                </div>
                <div className="colorwhite">Single Fix Jodi -45
                </div>
                <div className="yellowgreen">Faridabad satta king number
                </div>
              </div>
              <div className="col-lg-12 col-md-12 lotheadingwhite my-1">
                <h4 className="titlered"><span className="spanpink">Disawar Satta</span></h4>
                <h5 className="redcolor">Haruf-</h5>
                <div className="greencolor">Jodi -
                </div>
                <div className="colorwhite">Single Fix Jodi -
                </div>
                <div className="yellowgreen">Faridabad satta king number
                </div>
              </div> 
              <div className="col-lg-12 col-md-12 lotheadingwhite my-1">
                <h4 className="titlered"><span className="spanviolet">Important links for Satta Matka User</span> &nbsp; &nbsp; &nbsp; &nbsp;<span><i className="fa fa-arrow-down" /></span></h4>
                <div className="col-lg-12 col-md-12 my-1 ">
                  <a href="/matkaguessing"><h4><div className="spanviolet">Matka Guessing</div> </h4></a>
                </div>
                <div className="col-lg-12 col-md-12 my-1 ">
                  <a href="/sattakingjodi"><h4><div className="spanviolet">Satta King Jodi</div> </h4></a>
                </div>
                <div className="col-lg-12 col-md-12 my-1 ">
                  <a href="/freematkasattagame"><h4><div className="spanviolet">Today Free Game</div> </h4></a>
                </div>
                <div className="col-lg-12 col-md-12 my-1">
                  <a href="/weeklysattamatka"><h4><div className="spanviolet">Weekly Jodi Patti</div> </h4></a>
                </div>
                <div className="col-lg-12 col-md-12 my-1 ">
                  <a href="/matkatricktips"><h4><div className="spanviolet">Matka Life Trick Tips</div> </h4></a>
                </div>
                <div className="col-lg-12 col-md-12 my-1 ">
                  <a href="/matkatricktips"><h4><div className="spanviolet">Lifetime  Video Image</div> </h4></a>
                </div>
              </div> 
              <div className='lotheadinglight text-danger'>
                <h3>Matka Video</h3>
                <p>Officially Satta Matka Video Image Provides Best Matka Trick and tips Video and Image (photo) On This page. We Offer You Lifetime Matka trick and tips Paper Chart, Matka Video, Satta Video, Matka Trick Video, Kalyan Matka Trick Video, and Matka Live Stream On YouTube. Daily Matka Guessing and Today Matka Trick Video Publish By @Sattamatkagods. Matka Trick Tips Video Image Page Instant Update Satta Matka Video and Lifetime Trick Matka Images. Matka Guessing, Matka Result, and Matka Games Update By Matka Experts. SattaMatka Website Provides You Paid Membership But Our Matka Website Provides You Today satta matka game, Matka Guessing, and Fastest Matka Results.</p>
                <h3>SATTA MATKA LIFETIME TRICK</h3>
                <ol className='text-left'>Satta Matka Lifetime trick Gives You many types of tricks and tips. In this Help of trick We are Guessing Matka game. Use Of Trick and Trick many Prople win Lot of Money.
                    <li>Datewise Matka Chart</li>
                    <li>Matka Jodi Total</li>
                    <li>Matka Jodi Difference</li>
                    <li>Open to Close Matka Trick</li>
                    <li>Matka Panel Trick</li>
                    <li>Matka Running Ank Trick</li>
                    <li>2 Ank Matka trick</li>
                    <li>Matka Single Jodi Trick and Scheme</li>
                    <li>Kalyan Matka Lifetime Trick</li>
                    <li>Cut Ank Matka Trick ( Open Cut Ank Trick and Close Cut Ank Trick)</li>
                    <li>Weekly Matka Figure Trick</li>
                    <li>Final Ank Trick and Tips</li>
                </ol>
                <h3 className='text-success'>SATTA MATKA KING </h3>
                <h3 className='text-success'>WorldSattamatka4u </h3>

              </div>
        </>
    )
}
